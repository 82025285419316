import React, { useMemo } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { Elements } from '@stripe/react-stripe-js';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import i18next from './locales/translationService';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store/store';
import { darkModeTheme, lightModeTheme } from './theme';
import { useAppSelector } from './store/hooks';
import { selectTheme } from './store/settingsReducer';
import { GlobalStyles, useTheme } from '@mui/material';
import { assertIsDefined } from './helpers/commonHelpers';
import { CapacitorStripeProvider } from '@capacitor-community/stripe/dist/esm/react/provider';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { getQueryClientInstance } from './queryClient';
import { loadStripe } from '@stripe/stripe-js';
import { LicenseInfo } from '@mui/x-license-pro';
import { getCurrentLocale } from './helpers/dateAndTimeHelpers';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { MantineProvider } from '@mantine/core';
import ReactGA from 'react-ga4';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { initializeSentry } from './sentry';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { ReactFCWithChildren } from './types/types';
import { CountryInitializer } from './components/CountryInitializer/CountryInitializer';
import { initializeAppVersion } from './helpers/version';

const gtmId = process.env.REACT_APP_GTM_ID;
const gaID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const stripePublishableKey = process.env.REACT_APP_STRIPE_KEY as string;
const stripePromise = loadStripe(stripePublishableKey);

// Can be public https://mui.com/x/introduction/licensing/#security
LicenseInfo.setLicenseKey('6f45635790961a6641b7ecb02ea96c19Tz04OTA4NSxFPTE3NDU1ODI4OTAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

if (gtmId) {
    const tagManagerArgs: TagManagerArgs = {
        gtmId,
    };

    TagManager.initialize(tagManagerArgs);
}

const enableAnalytics = async (gaID: string) => {
    if (Capacitor.isNativePlatform()) {
        await FirebaseAnalytics.setEnabled({ enabled: true });
    } else {
        ReactGA.initialize(gaID);
    }
};

if (gaID) {
    enableAnalytics(gaID);
}

export const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
    const { href, ...other } = props;
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

export const UnstyledLink: ReactFCWithChildren<{ href: string }> = ({ href, children }) => {
    const theme = useTheme();
    return (
        <LinkBehavior href={href} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
            {children}
        </LinkBehavior>
    );
};

const themeOverrides: ThemeOptions = {
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordWrap: 'break-word' as const,
                    whiteSpace: 'pre-line' as const,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
    },
};

const ThemeWrapper = () => {
    const currentTheme = useAppSelector(selectTheme);
    const locale = getCurrentLocale();
    const theme = useMemo(
        () =>
            currentTheme === 'light' ? createTheme({ ...lightModeTheme, ...themeOverrides }) : createTheme({ ...darkModeTheme, ...themeOverrides }),
        [currentTheme],
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <MantineProvider theme={{ colorScheme: currentTheme }}>
                <ThemeProvider theme={theme}>
                    <GlobalStyles
                        styles={{
                            body: { backgroundColor: `${theme.palette.background.paper} !important` },
                        }}
                    />

                    <CountryInitializer>
                        <App />
                    </CountryInitializer>
                </ThemeProvider>
            </MantineProvider>
        </LocalizationProvider>
    );
};

const container = document.getElementById('root');
assertIsDefined(container, 'root is not defined?');
const root = createRoot(container);

const queryClient = getQueryClientInstance();

const initAndRender = async () => {
    await initializeAppVersion();

    if (sentryDsn) {
        await initializeSentry();
    }

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <PersistGate loading={null} persistor={persistor}>
                        <I18nextProvider i18n={i18next}>
                            <Elements stripe={stripePromise}>
                                <CapacitorStripeProvider publishableKey={stripePublishableKey}>
                                    <ThemeWrapper />
                                </CapacitorStripeProvider>
                            </Elements>
                        </I18nextProvider>
                    </PersistGate>
                </QueryClientProvider>
            </Provider>
        </React.StrictMode>,
    );
};

initAndRender();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
defineCustomElements(window);
