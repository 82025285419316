import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { sleep } from '../../helpers/commonHelpers';
import { getApiClient } from '../../services/sharetribe/apiClients';

interface UseVerifyArgs {
    onSuccess?: () => void;
    onError?: (err: AxiosError) => void;
}

export const useVerifyEmail = ({ onSuccess, onError }: UseVerifyArgs) => {
    const verifyFn = async (token: string) => {
        const url = '/verify-email';

        // The verification page looks slightly wonky if the verification succeeds / fails very quickly.
        await sleep();
        const { data } = await getApiClient('auth').post(url, { token });

        return data;
    };

    return useMutation(verifyFn, {
        onSuccess,
        onError,
    });
};
