import { Variants } from 'framer-motion';
import { getPathConfig } from '../routes/helpers';
import { fadeInOut } from './constants';
import { matchPath } from 'react-router-dom';

export const getWebVariants = (): Variants => fadeInOut;

type AnimationVariant = 'left' | 'right' | 'static';

export const getTransitionVariant = (newLocation: string, previousLocation: string, navigationType: string): AnimationVariant => {
    const newRouteConfig = getPathConfig(newLocation);
    const previousRouteConfig = getPathConfig(previousLocation);

    if (newLocation === previousLocation || !newRouteConfig || !previousRouteConfig) {
        return 'static';
    }

    const { animationOrder: newPathValue } = newRouteConfig;
    const { animationOrder: oldPathValue } = previousRouteConfig;

    if (!newPathValue || !oldPathValue || newPathValue === oldPathValue) {
        return 'static';
    }

    if (newPathValue > oldPathValue && navigationType !== 'POP') {
        return 'right';
    }
    if (navigationType === 'POP' || newPathValue < oldPathValue) {
        return 'left';
    }

    return 'static';
};
