import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIssueCredits } from '../../user/hooks/useIssueCredits';
import { Box, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormRenderer } from '../FormRenderer/FormRenderer';
import { FormField } from '../FormRenderer/FormRenderer.types';
import { DrawerProvider } from '../../context/drawer';
import { User } from '../../types/apiTypes';
import { LoadingButton } from '@mui/lab';
import { SwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeIn } from '../../animations/constants';
import { getAppConfig } from '../../countryConfigs';

interface AdminIssueCreditsModalProps {
    user: User;
    open: boolean;
    onClose: () => void;
}

type BaseFormData = {
    amount: number;
    currency: 'EUR' | 'USD';
};

type ReasonFormData = BaseFormData & {
    reason: 'reclamation' | 'marketing-collaboration' | '';
};

type OtherReasonFormData = BaseFormData & {
    reason: 'other';
    customMessage: string;
};

type FormData = ReasonFormData | OtherReasonFormData;

export const AdminIssueCreditsModal: React.FC<AdminIssueCreditsModalProps> = ({ user, open, onClose }) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const { currency } = getAppConfig().stripeConfig;
    const methods = useForm<FormData>({ defaultValues: { amount: 0, currency, reason: '' } });
    const reason = methods.watch('reason');

    const handleOnClose = () => {
        methods.reset();
        setIsConfirming(false);
        onClose();
    };

    const { mutate: issueCredits, isLoading } = useIssueCredits(handleOnClose);

    const handleSetConfirm = async () => {
        const res = await methods.trigger();
        if (res) {
            setIsConfirming(true);
        }
    };

    const submitHandler = async (data: FormData) => {
        const resolvedReason = data.reason === 'other' ? data.customMessage : data.reason;
        issueCredits({ user, ...data, reason: resolvedReason });
    };

    const dialogTitle = isConfirming ? 'Confirm action' : `Issue Credits to ${user.profile.displayName}`;

    const formFields: FormField[] = [
        {
            controlName: 'amount',
            type: 'number',
            label: `Amount ${currency}`,
            registerOptions: {
                required: true,
                shouldUnregister: true,
                min: { value: 1, message: 'Minimum amount is 1' },
                max: { value: 500, message: 'Maximum amount is 500' },
            },
        },
        {
            controlName: 'currency',
            type: 'select-menu',
            label: 'Currency',
            registerOptions: { required: true, shouldUnregister: true },
            items: [{ type: 'item', value: currency, title: currency, content: currency }],
        },
        {
            controlName: 'reason',
            type: 'select-menu',
            label: 'Reason for issuing credits',
            registerOptions: { required: true, shouldUnregister: true },
            items: [
                { type: 'item', value: 'Reclamation', title: 'Reclamation', content: 'Reclamation' },
                { type: 'item', value: 'Marketing collaboration', title: 'Marketing collaboration', content: 'Marketing collaboration' },
                { type: 'item', value: 'other', title: 'Other (specify)', content: 'Other (specify)' },
            ],
        },
    ];

    if (reason === 'other') {
        formFields.push({
            controlName: 'customMessage',
            type: 'text',
            label: 'Message',
            registerOptions: {
                required: true,
                shouldUnregister: true,
                minLength: { value: 5, message: 'Minimum length is 5' },
                maxLength: { value: 100, message: 'Maximum length is 100' },
            },
        });
    }

    return (
        <>
            <SwipeableDrawer open={open} onClose={handleOnClose} fillHeight={false}>
                <DrawerProvider>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(submitHandler)}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, py: 4, gap: '10px' }}>
                                <DialogTitle sx={{ pb: 4 }}>
                                    <b>{dialogTitle}</b>
                                </DialogTitle>
                                {isConfirming ? (
                                    <DialogContent>
                                        Are you sure you want to issue{' '}
                                        <b>
                                            {methods.getValues().amount} {methods.getValues().currency}
                                        </b>{' '}
                                        credits to <b>{user.profile.displayName}</b>?
                                    </DialogContent>
                                ) : (
                                    <FormRenderer formFields={formFields}></FormRenderer>
                                )}

                                <DialogActions>
                                    {!isConfirming ? (
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            <Button onClick={handleOnClose}>Cancel</Button>
                                            <Button variant="contained" onClick={handleSetConfirm}>
                                                Proceed
                                            </Button>
                                        </Box>
                                    ) : (
                                        <AnimatedContainer variants={fadeIn} style={{ display: 'flex', gap: '10px' }} transition={{ delay: 1 }}>
                                            <Button onClick={() => setIsConfirming(false)}>Cancel</Button>
                                            <LoadingButton loading={isLoading} color="primary" variant="contained" type="submit">
                                                Confirm
                                            </LoadingButton>
                                        </AnimatedContainer>
                                    )}
                                </DialogActions>
                            </Box>
                        </form>
                    </FormProvider>
                </DrawerProvider>
            </SwipeableDrawer>
        </>
    );
};
