import React from 'react';
import { Typography } from '@mui/material';
import { formatShortMonthAndDate } from '../../helpers/dateAndTimeHelpers';
import { useTranslation } from 'react-i18next';

interface ListingDetailsSectionProps {
    listingTitle: string;
    listingCoverImage: string;
    bookingStart: string;
    bookingEnd: string;
}

export const ListingDetailsSection: React.FC<ListingDetailsSectionProps> = ({ listingTitle, listingCoverImage, bookingStart, bookingEnd }) => {
    const { t } = useTranslation();
    const bookingPeriod = `${formatShortMonthAndDate(bookingStart, true)} - ${formatShortMonthAndDate(bookingEnd, true)}`;

    return (
        <div style={{ display: 'flex', height: '120px', width: '100%', gap: '10px' }}>
            <div style={{ width: '35%', height: '100%' }}>
                <img src={listingCoverImage} alt={listingTitle} style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '10px' }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%', justifyContent: 'space-between' }}>
                <Typography variant="h6">{listingTitle}</Typography>

                <div>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {t('rentingPeriod')}
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body1">{bookingPeriod}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};
