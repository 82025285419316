import React, { useEffect } from 'react';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { getAppVersion } from './helpers/version';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN || '';

export const initializeSentry = async () => {
    let appInfo: AppInfo | null;
    let distribution: string | undefined;

    const platform = Capacitor.getPlatform();
    const isNativePlatform = Capacitor.isNativePlatform();

    if (isNativePlatform) {
        appInfo = await App.getInfo();
        distribution = appInfo.build;
    }

    const getReleaseVersion = () => {
        const version = getAppVersion();
        // IMPORTANT: This notation must match the one used in build-scripts for native apps (build-android-prod.sh, build-ios-prod.sh) when uploading
        return `robes-${platform}-${version}`;
    };

    Sentry.init({
        dsn: sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.extraErrorDataIntegration(),
        ],

        tracesSampleRate: 1.0,
        release: getReleaseVersion(),
        dist: distribution,
    });
};
