// Possible inputs Stripe might require for a country

import { countryConfig } from '../../../countryConfigs';
import { StripeConnectFormData } from './StripeConnectForm.types';

// Bank account number (used in countries where IBAN is not in use)
export const ACCOUNT_NUMBER = 'accountNumber';
// Required for Japan
export const ACCOUNT_OWNER_NAME = 'accountOwnerName';
// Australian equivalent for routing number
export const BSB = 'bsb';
// Needed for creating full routing number in Canada
export const INSTITUTION_NUMBER = 'institutionNumber';
// Needed for creating full routing number in Canada
export const TRANSIT_NUMBER = 'transitNumber';
// Needed for creating full routing number in Hong Kong
export const CLEARING_CODE = 'clearingCode';
// Needed for creating full routing number in Hong Kong and Singapore
export const BRANCH_CODE = 'branchCode';
// Required for Japan
export const BRANCH_NAME = 'branchName';
// Required for Japan
export const BANK_NAME = 'bankName';
// Needed for creating full routing number in e.g. Singapore
export const BANK_CODE = 'bankCode';
// International bank account number (e.g. EU countries use this)
export const IBAN = 'iban';
// Routing number to separate bank account in different areas
export const ROUTING_NUMBER = 'routingNumber';
// British equivalent for routing number
export const SORT_CODE = 'sortCode';

// Currently supported bank account inputs
// the order here matters: account number input is asked after routing number and its equivalents
export const BANK_ACCOUNT_INPUTS = [
    BSB,
    TRANSIT_NUMBER,
    INSTITUTION_NUMBER,
    CLEARING_CODE,
    BANK_NAME,
    BANK_CODE,
    BRANCH_NAME,
    BRANCH_CODE,
    SORT_CODE,
    ROUTING_NUMBER,
    ACCOUNT_OWNER_NAME,
    ACCOUNT_NUMBER,
    IBAN,
] as const;

type BankAccountInput = typeof BANK_ACCOUNT_INPUTS[number];
type SupportedCountry = keyof typeof countryConfig;

export const isSupportedCountry = (countryCode: string): countryCode is SupportedCountry => {
    return supportedCountryCodes.includes(countryCode as SupportedCountry);
};

export const supportedCountryCodes = Object.keys(countryConfig) as SupportedCountry[];
export const supportedCountries = supportedCountryCodes.map((code) => countryConfig[code]);

/**
 * Country specific Stripe configurations
 */
export const stripeCountryConfigs = (countryCode: string) => {
    const country = supportedCountries.find((c) => c.stripeConfig.countryCode === countryCode);

    if (!country) {
        throw new Error(`Country code not found in Stripe config ${countryCode}`);
    }
    return country;
};

export const isValidCurrencyForCountryCode = (countryCode: string, currency: string) => {
    const country = supportedCountries.find((c) => c.stripeConfig.countryCode === countryCode);
    if (!country) {
        throw new Error(`Country code not found in Stripe config ${countryCode}`);
    }

    return country.stripeConfig.currency === currency;
};

export const getCountryCurrency = (countryCode: string) => {
    if (!isSupportedCountry(countryCode)) {
        throw new Error(`Country code not supported ${countryCode}`);
    }

    const config = stripeCountryConfigs(countryCode);

    return config.stripeConfig.currency;
};

/**
 * Return all the inputs that are required in given country
 */
export const requiredInputs = (countryCode: string) => {
    if (!isSupportedCountry(countryCode)) {
        throw new Error(`Country code not supported ${countryCode}`);
    }

    const bankAccountInputs = countryConfig[countryCode].stripeConfig.accountConfig;
    const required = BANK_ACCOUNT_INPUTS.filter((inputType) => bankAccountInputs[inputType as keyof typeof bankAccountInputs]) as BankAccountInput[];

    return required;
};

// Remove all whitespace from string values.
export const cleanedString = (str: string | undefined) => (str ? str.replace(/\s/g, '') : '');

export const mapInputsToStripeAccountKeys = (country: string, values: StripeConnectFormData) => {
    // Stripe documentation speaks about actual bank account terms of different countries
    // (like BSB, sort code, routing number), but all of those get mapped to one of
    // the two different request keys: routing_number or account_number
    // See: https://stripe.com/docs/payouts vs https://stripe.com/docs/connect/testing

    // We use those country specific terms since we want to show correct labels and errors for users,
    // so this mapping is needed before sending data to Stripe.

    // Stripe fails if there are spaces within the number, this is
    // why we have to clean value up first.

    switch (country) {
        case 'AT':
        case 'BE':
        case 'BG':
        case 'CY':
        case 'CZ':
        case 'DK':
        case 'EE':
        case 'FI':
        case 'FR':
        case 'DE':
        case 'GR':
        case 'IE':
        case 'IT':
        case 'LV':
        case 'LT':
        case 'LU':
        case 'MT':
        case 'NL':
        case 'PL':
        case 'PT':
        case 'RO':
        case 'SK':
        case 'SI':
        case 'ES':
        case 'SE':
        case 'CH':
        case 'NO':
            return { account_number: cleanedString(values[IBAN]) };
        case 'NZ':
            // NZ account number is typically presented in the format xx-xxxx-xxxxxxx-xxx
            // '-' separators must be removed before sending value to Stripe API
            return { account_number: cleanedString(values[ACCOUNT_NUMBER]).replace(/-/g, '') };
        case 'AU':
            return {
                routing_number: cleanedString(values[BSB]),
                account_number: cleanedString(values[ACCOUNT_NUMBER]),
            };
        case 'CA':
            return {
                routing_number: cleanedString(values[TRANSIT_NUMBER]).concat(cleanedString(values[INSTITUTION_NUMBER])),
                account_number: cleanedString(values[ACCOUNT_NUMBER]),
            };
        case 'GB':
            return {
                routing_number: cleanedString(values[SORT_CODE]),
                account_number: cleanedString(values[ACCOUNT_NUMBER]),
            };
        case 'US':
            return {
                routing_number: cleanedString(values[ROUTING_NUMBER]),
                account_number: cleanedString(values[ACCOUNT_NUMBER]),
            };
        case 'SG':
            return {
                routing_number: cleanedString(values[BANK_CODE]).concat('-', cleanedString(values[BRANCH_CODE])),
                account_number: cleanedString(values[ACCOUNT_NUMBER]),
            };
        case 'HK':
            return {
                routing_number: cleanedString(values[CLEARING_CODE]).concat('-', cleanedString(values[BRANCH_CODE])),
                account_number: cleanedString(values[ACCOUNT_NUMBER]),
            };

        case 'JP':
            return {
                bank_name: values[BANK_NAME],
                branch_name: values[BRANCH_NAME],
                routing_number: cleanedString(values[BANK_CODE]).concat(values[BRANCH_CODE] as string),
                account_number: cleanedString(values[ACCOUNT_NUMBER]),
                account_holder_name: values[ACCOUNT_OWNER_NAME],
            };

        default:
            throw new Error(`Not supported country (${country}) given to validator`);
    }
};
