import { TFunction } from 'react-i18next';
import { DeliveryMethod, Methods } from '../../queries/useDeliveryTiming';
import { BookingDetails } from './LogisticsSection.helpers';
import { StyledSvgIcon } from '../../components/Styled/Styled.components';
import { DisplayFormValueComponent } from './DisplayFormValue';
import { DifferentDayAlertMaybe } from './DifferentDayAlertMaybe';
import { getOpeningHourStringForDate, getTransactionDate } from '../../helpers/delivery';

export const DisplayPickupTime: React.FC<{ t: TFunction; bookingDetails: BookingDetails; deliveryMethods: DeliveryMethod[] }> = ({
    t,
    bookingDetails,
    deliveryMethods,
}) => {
    const { deliveryMethod } = bookingDetails;

    const displayDateFormatted = getOpeningHourStringForDate(deliveryMethods, deliveryMethod, 'renterDeliveryDate');

    const renderAlert = () => {
        return <DifferentDayAlertMaybe deliveryMethods={deliveryMethods} bookingDetails={bookingDetails} type="pickup" />;
    };

    return (
        <DisplayFormValueComponent
            value={displayDateFormatted}
            label={t('pickupTime')}
            alertComponent={renderAlert}
            prefix={
                <StyledSvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="m618.924-298.924 42.152-42.152-151.077-151.087V-680h-59.998v212.154l168.923 168.922ZM480.067-100.001q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-480Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
                    </svg>
                </StyledSvgIcon>
            }
        />
    );
};
