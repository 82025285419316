import { addDays, differenceInDays, isAfter, isBefore, isPast, isSameDay } from 'date-fns';
import { DateRange } from '../../components/DateRangePicker/DateRangePicker.types';
import { minimumRentalLength } from '../../constants';
import { Nil } from '../../types/types';
import { Listing } from '../../types/apiTypes';
import { DAYS_IN_WEEK, DAYS_IN_MONTH } from '../AddListing/PricingModelForm.helpers';
import { getLaterDate } from '../../helpers/dateAndTimeHelpers';

export interface Timeslot {
    attributes: {
        type: 'time-slot/day';
        seats: number;
        start: string;
        end: string;
    };
}

export const timeSlotEqualsDay = (timeslot: Timeslot, day: Date) => {
    if (timeslot.attributes.type === 'time-slot/day') {
        // Time slots describe available dates by providing a start and
        // an end date which is the following day. In the single date picker
        // the start date is used to represent available dates.
        const localStartDate = new Date(timeslot.attributes.start);

        return isSameDay(day, localStartDate);
    }

    return false;
};

export const timeSlotsContain = (timeSlots: Timeslot[], date: Date) => timeSlots.findIndex((slot) => timeSlotEqualsDay(slot, date)) > -1;

/**
 * Find first blocked date between two dates.
 * If none is found, null is returned.
 */
const firstBlockedBetween = (timeSlots: Timeslot[] | undefined, startDate: Date | Nil, endDate: Date | Nil): Date | null => {
    if (!startDate || !endDate || !timeSlots) {
        return null;
    }

    const firstDate = addDays(startDate, 1);
    if (isSameDay(firstDate, new Date(endDate))) {
        return null;
    }

    return timeSlotsContain(timeSlots, firstDate) ? firstBlockedBetween(timeSlots, firstDate, endDate) : firstDate;
};

/**
 * Find last blocked date between two dates.
 * If none is found, null is returned.
 */
const lastBlockedBetween = (timeSlots: Timeslot[] | undefined, startDate: Date | Nil, endDate: Date | Nil): Date | null => {
    if (!startDate || !endDate || !timeSlots) {
        return null;
    }

    const firstDate = addDays(startDate, -1);
    if (isSameDay(firstDate, new Date(endDate))) {
        return null;
    }

    return timeSlotsContain(timeSlots, firstDate) ? lastBlockedBetween(timeSlots, firstDate, endDate) : firstDate;
};

export const rangeLengthInDays = (d1: Date | Nil, d2: Date | Nil) => {
    if (!d1 || !d2) {
        return undefined;
    }

    return Math.abs(differenceInDays(d1, d2)) + 1;
};

export const isRangeValid = (dateRange: DateRange): dateRange is [Date, Date] => {
    const [startDate, endDate] = dateRange;

    if ((!startDate && !endDate) || !(startDate instanceof Date) || !(endDate instanceof Date)) {
        return false;
    }

    const length = rangeLengthInDays(startDate, endDate) || 0;

    return length >= minimumRentalLength;
};

const countTimeslots = (timeslots: Timeslot[], idx: number, direction: 'down' | 'up', count = 0): number => {
    if (!timeslots[idx]) {
        return count;
    }

    const step = direction === 'down' ? -1 : 1;
    const nextSlotIdx = idx + step;
    const nextTimeslot = timeslots[nextSlotIdx];

    if (nextTimeslot) {
        const currentDate = timeslots[idx];
        const dateDiff = Math.abs(differenceInDays(new Date(nextTimeslot.attributes.start), new Date(currentDate.attributes.start)));

        // Only count the next date if it's adjacent to the current date
        if (dateDiff === 1) {
            return countTimeslots(timeslots, nextSlotIdx, direction, count + 1);
        }
    }

    return count;
};

export const countAdjacentTimeslots = (date: Date, timeslots: Timeslot[], minimum: number) => {
    const idx = timeslots.findIndex((slot) => timeSlotEqualsDay(slot, date));
    if (idx < 0) {
        return 0;
    }

    const forwardDates = countTimeslots(timeslots, idx, 'up');
    const backwardsDates = countTimeslots(timeslots, idx, 'down');

    return forwardDates + backwardsDates + 1;
};

export const isStartDateSelected = (timeSlots: Timeslot[] | undefined, startDate: Date | Nil, endDate: Date | Nil) =>
    timeSlots && startDate && !endDate;

export const getDateRangeConstraints = (timeSlots: Timeslot[] | undefined, selectedRange: DateRange): DateRange => {
    const [start, end] = selectedRange;

    const endOfRange = 90;
    const lastBookableDate = addDays(new Date(), endOfRange);

    const startDateSelected = isStartDateSelected(timeSlots, start, end);

    // find the next booking after a start date
    const nextBookingStarts = startDateSelected ? firstBlockedBetween(timeSlots, start, addDays(lastBookableDate, 1)) : null;

    // find the previous booking start before start date
    const previousBookingEnds = startDateSelected ? lastBlockedBetween(timeSlots, start, addDays(new Date(), 1)) : null;

    const maxDate = nextBookingStarts ? addDays(nextBookingStarts, -1) : null;
    const minDate = previousBookingEnds ? getLaterDate(addDays(previousBookingEnds, 1), new Date()) : null;

    return [minDate, maxDate];
};

export const isDayBlockedFn = (timeSlots: Timeslot[] | undefined) => {
    if (!timeSlots) {
        return () => true;
    }

    return (day: Date) => !timeSlots.find((timeSlot) => timeSlotEqualsDay(timeSlot, day));
};

export const calculateDiscountedTotal = (duration: number | undefined, listing: Listing) => {
    const { pricing } = listing.publicData;

    if (!pricing || !duration) {
        return null;
    }

    const undiscountedPrice = listing.price.amount * duration;

    const useWeeklyDiscount =
        !!pricing.weekly && ((duration >= DAYS_IN_WEEK && duration < DAYS_IN_MONTH) || (duration >= DAYS_IN_MONTH && !pricing.monthly));
    const useMonthlyDiscount = pricing.monthly && !useWeeklyDiscount && duration >= DAYS_IN_MONTH;

    if (useWeeklyDiscount) {
        const undiscountedWeekly = listing.price.amount * DAYS_IN_WEEK;
        const discount = (1 - (pricing.weekly as number) / undiscountedWeekly) * 100;
        const weeklyDiscountPrice = (undiscountedPrice * (100 - discount)) / 100;

        return weeklyDiscountPrice < undiscountedPrice ? weeklyDiscountPrice : null;
    }

    if (useMonthlyDiscount) {
        const undiscountedMonthly = listing.price.amount * DAYS_IN_MONTH;
        const discount = (1 - (pricing.monthly as number) / undiscountedMonthly) * 100;
        const monthlyDiscountPrice = (undiscountedPrice * (100 - discount)) / 100;

        return monthlyDiscountPrice < undiscountedPrice ? monthlyDiscountPrice : null;
    }
};
