import React from 'react';
import { TransitionName } from '../../../transactions/constants';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { addDays, differenceInHours, isAfter, isBefore, isSameDay } from 'date-fns';
import { TFunction, TFunctionResult } from 'i18next';
import { formatMonthAndDate, formatHHMMTimeRange, formatDayMonthAndDate } from '../../../helpers/dateAndTimeHelpers';
import { DeliveryMethod } from '../../../queries/useDeliveryTiming';
import { TransactionAttributes } from '../../../transactions/apiTypes';
import { capitalizeEachWord } from '../../../helpers/commonHelpers';
import { deliveryTimeInterval } from '../../../constants';
import { Nil } from '../../../types/types';

type TranslationParams = {
    bookingStart: Date;
    bookingEnd: Date;
    deliveryMethods: DeliveryMethod[];
    lastTransitionedAt: string;
    type: 'order' | 'sale';
    isPresentInShowroom: boolean | undefined;
    lastTransition?: TransitionName;
    protectedData: TransactionAttributes['protectedData'];
};

type ParamDependantTranslation = (t: TFunction, params: TranslationParams) => TFunctionResult | React.ReactElement;

type NextStepConfig = {
    order: {
        text?: string | ParamDependantTranslation;
        alert?: {
            title?: string | ParamDependantTranslation;
            body?: string | ParamDependantTranslation;
        };
    };
    sale: {
        text?: string | ParamDependantTranslation;
        alert?: {
            title?: string | ParamDependantTranslation;
            body?: string | ParamDependantTranslation;
        };
    };
};

const getAlertTitle: ParamDependantTranslation = (t, params) => {
    if (params.protectedData.deliveryMethod === 'wolt' || params.protectedData.deliveryMethod === 'showroom') {
        return params.lastTransition === TransitionName.CONFIRM_PAYMENT ? t('deliveryInstructions') : t('nextStep');
    }
    return '';
};

type RentalStatus =
    | 'Upcoming'
    | 'StartsToday'
    | 'InProgress'
    | 'EndsToday'
    | 'Past'
    | 'NotAccepted'
    | 'NotAcceptedPresentInShowroom'
    | 'UpcomingPresentInShowroom';

const getRentalStatus = (
    start: Date,
    end: Date,
    type: 'sale' | 'order',
    presentInShowroom: boolean | undefined,
    lastTransition?: TransitionName,
): RentalStatus => {
    const now = new Date();
    if (lastTransition === TransitionName.CONFIRM_PAYMENT) {
        if (type === 'sale' && presentInShowroom) {
            return 'NotAcceptedPresentInShowroom';
        }
        return 'NotAccepted';
    }
    if (isBefore(now, start)) {
        if (type === 'sale' && presentInShowroom) {
            return 'UpcomingPresentInShowroom';
        }
        return 'Upcoming';
    }
    if (isSameDay(now, start)) {
        if (type === 'sale' && presentInShowroom) {
            return 'UpcomingPresentInShowroom';
        }
        return 'StartsToday';
    }
    if (isAfter(now, start) && isBefore(now, end)) {
        return 'InProgress';
    }
    if (isSameDay(now, end)) {
        return 'EndsToday';
    }

    return 'Past';
};

const getTranslationKey: ParamDependantTranslation = (t, params) => {
    const { bookingStart, bookingEnd, protectedData, type, lastTransition, isPresentInShowroom } = params;
    const {
        renterDeliveryDate,
        renterReturnDate,
        renterReturnDateOpeningHoursString,
        lenderPickupDateOpeningHoursString,
        renterDeliveryDateOpeningHoursString,
        lenderDropoffDateOpeningHoursString,
        lenderPickupDate,
        deliveryMethod,
        deliveryTime,
    } = protectedData;

    let i18nKey = '';
    let translationValues: Record<string, string | number | Nil> = {};

    if (deliveryMethod === 'wolt' || deliveryMethod === 'showroom' || deliveryMethod === 'uber') {
        if (!renterDeliveryDate || !renterReturnDate) {
            return '';
        }

        const formattedDeliveryTime = deliveryTime
            ? `${formatDayMonthAndDate(new Date(renterDeliveryDate))} - ${formatHHMMTimeRange(deliveryTime, deliveryTimeInterval)}`
            : null;

        if (type === 'order') {
            const rentalStatus = getRentalStatus(new Date(renterDeliveryDate), new Date(renterReturnDate), type, isPresentInShowroom, lastTransition);

            translationValues = {
                ...translationValues,
                d1: renterDeliveryDateOpeningHoursString,
                d2: renterReturnDateOpeningHoursString,
                deliveryTime: formattedDeliveryTime,
            };
            i18nKey = `rentalStatusRenter${rentalStatus}${capitalizeEachWord(deliveryMethod)}`;
        }

        if (type === 'sale') {
            const rentalStatus = getRentalStatus(
                new Date(renterDeliveryDate),
                addDays(new Date(renterReturnDate), 1),
                type,
                isPresentInShowroom,
                lastTransition,
            );

            translationValues = {
                ...translationValues,
                d1: lenderDropoffDateOpeningHoursString,
                d2: lenderPickupDateOpeningHoursString,
                deliveryTime: formattedDeliveryTime,
            };

            i18nKey = `rentalStatusLender${rentalStatus}${capitalizeEachWord(deliveryMethod)}`;
        }
    } else {
        translationValues = {
            ...translationValues,
            d1: formatMonthAndDate(bookingStart),
            d2: formatMonthAndDate(bookingEnd),
        };

        const rentalStatus = getRentalStatus(bookingStart, bookingEnd, type, isPresentInShowroom, lastTransition);

        if (type === 'order') {
            i18nKey = `rentalStatusRenter${rentalStatus}${capitalizeEachWord(deliveryMethod)}`;
        }

        if (type === 'sale') {
            i18nKey = `rentalStatusLender${rentalStatus}${capitalizeEachWord(deliveryMethod)}`;
        }
    }

    return (
        <Trans
            i18nKey={i18nKey}
            values={translationValues}
            components={{ b: <b />, h3: <h3 />, underline: <span style={{ textDecoration: 'underline' }} /> }}
        />
    );
};

const getRenterConfirmPaymentTranslationKey: ParamDependantTranslation = (t, params) => {
    const { lastTransitionedAt } = params;
    const now = new Date();
    const deadlineForAcceptance = addDays(new Date(lastTransitionedAt), 2);
    const hoursDifference = differenceInHours(deadlineForAcceptance, now);

    if (hoursDifference < 0) {
        return '';
    }

    return (
        <Trans
            i18nKey="pendingLenderResponse"
            values={{ time: hoursDifference }}
            components={{ b: <b />, h3: <h3 />, underline: <span style={{ textDecoration: 'underline' }} /> }}
        />
    );
};

const getLenderConfirmPaymentTranslationKey: ParamDependantTranslation = (t, params) => {
    const { lastTransitionedAt } = params;
    const now = new Date();
    const deadlineForAcceptance = addDays(new Date(lastTransitionedAt), 2);
    const hoursDifference = differenceInHours(deadlineForAcceptance, now);

    if (hoursDifference < 0) {
        return '';
    }

    return (
        <Trans
            i18nKey="pendingResponse"
            values={{ time: hoursDifference }}
            components={{ b: <b />, underline: <span style={{ textDecoration: 'underline' }} /> }}
        />
    );
};

const nextStepConfigs: Partial<Record<TransitionName, NextStepConfig>> = {
    [TransitionName.ACCEPT]: {
        order: {
            alert: {
                title: 'nextStep',
                body: getTranslationKey,
            },
        },
        sale: {
            alert: {
                title: getAlertTitle,
                body: getTranslationKey,
            },
        },
    },
    [TransitionName.CONFIRM_PAYMENT]: {
        order: {
            text: getRenterConfirmPaymentTranslationKey,
            alert: {
                title: 'nextStep',
                body: getTranslationKey,
            },
        },
        sale: {
            text: getLenderConfirmPaymentTranslationKey,
            alert: {
                title: getAlertTitle,
                body: getTranslationKey,
            },
        },
    },
    [TransitionName.REQUEST_PAYMENT]: {
        order: {
            alert: {
                body: 'pendingPayment',
            },
        },
        sale: {
            alert: {
                body: 'pendingPayment',
            },
        },
    },
    [TransitionName.COMPLETE]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_1_BY_CUSTOMER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_1_BY_PROVIDER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_2_BY_CUSTOMER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_2_BY_PROVIDER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
};

interface NextTransactionActionProps {
    lastTransition: TransitionName;
    type: 'order' | 'sale';
    translationParams: TranslationParams;
}

export const NextTransactionAction: React.FC<NextTransactionActionProps> = ({ lastTransition, type, translationParams }) => {
    const { t } = useTranslation();
    const nextStepConfig = nextStepConfigs[lastTransition];

    if (!nextStepConfig) {
        return null;
    }

    const translationKeysForType = nextStepConfig[type];

    const resolveTranslationKey = (translationKey: string | ParamDependantTranslation | undefined) => {
        if (!translationKey) return null;

        let resolvedKey;

        if (typeof translationKey === 'function') {
            resolvedKey = translationKey(t, { ...translationParams, type, lastTransition }) as React.ReactNode;
        } else {
            resolvedKey = t(translationKey);
        }

        return resolvedKey;
    };

    const hideAlert = () => {
        return lastTransition === TransitionName.CONFIRM_PAYMENT && type === 'order';
    };

    const showAlert = !hideAlert();

    const resolvedBodyText = resolveTranslationKey(translationKeysForType.text);
    const resolvedAlertTitle = showAlert && resolveTranslationKey(translationKeysForType.alert?.title);
    const resolvedAlertBody = showAlert && resolveTranslationKey(translationKeysForType.alert?.body);

    const hasAlert = resolvedAlertTitle || resolvedAlertBody;

    return (
        <>
            {hasAlert && (
                <Alert severity="info" sx={{ borderRadius: '15px' }}>
                    {resolvedAlertTitle && <AlertTitle sx={{ fontWeight: 'bold' }}>{resolvedAlertTitle}</AlertTitle>}
                    {resolvedAlertBody && <Typography sx={{ fontSize: '0.8rem' }}>{resolvedAlertBody}</Typography>}
                </Alert>
            )}

            {resolvedBodyText && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                    {resolvedBodyText}
                </Typography>
            )}
        </>
    );
};
