import { useQuery } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { Article } from './Article.types';
import { sleep } from '../../helpers/commonHelpers';
import { matchPath, useLocation } from 'react-router-dom';

export const useArticle = (articleId: string | undefined) => {
    const { pathname } = useLocation();
    const articlePathMatch = matchPath('/article/:id', pathname);

    const getArticle = async () => {
        if (!articleId) {
            return;
        }
        const url = `/articles/${articleId}`;
        const { data } = await getApiClient('strapi').get<Article>(url);

        await sleep(400);

        return data;
    };

    return useQuery(['article', { articleId }], getArticle, { enabled: Boolean(articlePathMatch) });
};
