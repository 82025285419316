import axios from 'axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getAppConfig, resolveStrapiUrl } from '../../countryConfigs';

interface Root {
    id: number;
    attributes: Attributes;
}

interface Attributes {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    defaultWeekly: number;
    defaultMonthly: number;
    discountGroups: IDiscountGroup[];
}

interface IDiscountGroup {
    id: number;
    categories: string;
    weekly: number;
    monthly: number;
    title: string;
}

interface DiscountGroup {
    id: number;
    categories: string[];
    weekly: number;
    monthly: number;
    title: string;
}

export interface Discount {
    weekly: number;
    monthly: number;
}

export interface DiscountDefinition {
    defaultWeekly: number;
    defaultMonthly: number;
    discountGroups: DiscountGroup[];
}

const formatDiscountDefinition = (definition: Root): DiscountDefinition | undefined => {
    if (!definition || !definition.attributes) {
        return;
    }

    const { defaultWeekly, defaultMonthly, discountGroups } = definition.attributes;
    const formattedGroups = discountGroups.map((group) => {
        const categories = group.categories.split('\n');
        return { ...group, categories };
    });

    return { defaultWeekly, defaultMonthly, discountGroups: formattedGroups };
};

export const useDiscountDefinition = (): UseQueryResult<DiscountDefinition> => {
    const getDiscountGroupsFn = async () => {
        const { countryCode } = getAppConfig();
        const url = `${resolveStrapiUrl(countryCode)}/api/discount-definition?populate=*`;
        const { data } = await axios.get<{ data: Root }>(url);

        return formatDiscountDefinition(data.data);
    };

    return useQuery(['discount-groups'], getDiscountGroupsFn);
};
