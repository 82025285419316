import { cloneDeep } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { ExtendedListing, Listing } from '../../types/apiTypes';

export const useToggleAvailability = () => {
    const queryClient = useQueryClient();

    const togglePublicity = (listing: Listing) => {
        const getUpdatedPublicity = () => {
            if (listing.state === 'published') {
                return 'closed';
            }
            if (listing.state === 'closed') {
                return 'published';
            }
            throw new Error(`Listing in unknown state: ${listing.state}`);
        };

        return { ...listing, state: getUpdatedPublicity() };
    };

    const toggleAvailabilityFn = async (listing: ExtendedListing) => {
        if (!listing) {
            throw new Error('Reference to non-existing listing?');
        }

        const isPublic = listing.state === 'published';

        const url = `/set-publicity/${listing.id}`;
        const { data } = await getApiClient('listings').post<{ attributes: { state: string } }>(url, {
            publicity: !isPublic,
        });

        return data;
    };

    return useMutation(toggleAvailabilityFn, {
        onMutate: async (listing) => {
            await queryClient.cancelQueries(['own-listings']);

            if (!listing) {
                throw new Error('Reference to non-existing listing?');
            }

            const listingCopy = cloneDeep(listing);
            const updatedListing = togglePublicity(listingCopy);

            queryClient.setQueryData(['listing', { listingId: listing.id }], () => updatedListing);

            return { originalListing: listing };
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['own-listings']);
        },
        onError: (_err, listing, context) => {
            const { originalListing } = context || {};

            if (originalListing) {
                queryClient.setQueryData(['listing', { listingId: listing.id }], () => originalListing);
            }
        },
    });
};
