import { uniq } from 'lodash';
import { invariant } from '../../helpers/commonHelpers';
import { OnboardingState, User } from '../../types/apiTypes';
import { OnboardingStep } from './UserOnboarding.types';
import { Capacitor } from '@capacitor/core';
import { getAppIdentificationProvider } from '../../countryConfigs';

const introductorySteps = ['greetings', 'rentalDescription', 'lendingDescription'];
const allSteps = [...introductorySteps, 'notificationPermissions', 'picture', 'bio', 'complete'];

export const generateOnboardingSteps = (onboardingState: OnboardingState, user: User): OnboardingStep[] => {
    const steps: OnboardingStep[] = [];

    const showServiceDescription = !onboardingState || introductorySteps.includes(onboardingState);

    const shouldShowStep = (step: OnboardingStep) => {
        if (!onboardingState || !allSteps.includes(onboardingState)) {
            return false;
        }

        const currentStateIdx = allSteps.indexOf(onboardingState);
        const stepIdx = allSteps.indexOf(step);

        return currentStateIdx <= stepIdx;
    };

    if (showServiceDescription) {
        steps.push(...(['greetings', 'rentalDescription', 'lendingDescription'] as const));
    }

    if (Capacitor.isNativePlatform() && (showServiceDescription || shouldShowStep('notificationPermissions'))) {
        steps.push('notificationPermissions');
    }

    if ((getAppIdentificationProvider() === 'signicat' && showServiceDescription) || shouldShowStep('verification')) {
        steps.push('verification');
    }

    if (showServiceDescription || shouldShowStep('picture')) {
        steps.push('picture');
    }

    if (showServiceDescription || shouldShowStep('bio')) {
        steps.push('bio');
    }

    if (onboardingState === 'existing-user') {
        steps.push('greetings');

        if (!user.profileImage) {
            steps.push('picture');
        }
        if (!user.profile.bio) {
            steps.push('bio');
        }
    }

    steps.push('complete');

    invariant(steps.length === uniq(steps).length, 'Duplicate steps');

    return steps;
};

export const getNextOnboardingState = (step: OnboardingStep, steps: OnboardingStep[]): undefined | OnboardingState => {
    const current = steps.indexOf(step);

    invariant(current > -1, 'Invalid step');

    const nextIdx = current + 1;

    if (!nextIdx) {
        return 'complete';
    }

    return steps[nextIdx];
};
