/**
 * Shirts, jeans etc
 */

import { ListElement } from '../../components/SelectList/types';
import { getAppCountryCode } from '../../countryConfigs';

export const sizingOptionsOneByCountry = {
    FI: [
        {
            type: 'header',
            value: 'clothingSizes',
            label: 'clothingSizes',
        },
        {
            type: 'item',
            value: 'oneSize',
            label: 'oneSize',
        },
        {
            type: 'item',
            value: '32 - XS',
            label: '32 - XS',
        },
        {
            type: 'item',
            value: '34 - S',
            label: '34 - S',
        },
        {
            type: 'item',
            value: '36 - M',
            label: '36 - M',
        },
        {
            type: 'item',
            value: '38 - M',
            label: '38 - M',
        },
        {
            type: 'item',
            value: '40 - L',
            label: '40 - L',
        },
        {
            type: 'item',
            value: '42 - L',
            label: '42 - L',
        },
        {
            type: 'item',
            value: '44 - XL',
            label: '44 - XL',
        },
        {
            type: 'item',
            value: '46 - XL',
            label: '46 - XL',
        },
        {
            type: 'item',
            value: '48 - XXL',
            label: '48 - XXL',
        },
        {
            type: 'item',
            value: '50 - XXL',
            label: '50 - XXL',
        },
        {
            type: 'item',
            value: '52 - 3XL',
            label: '52 - 3XL',
        },
        {
            type: 'item',
            value: '54 - 4XL',
            label: '54 - 4XL',
        },
    ],
    US: [
        {
            type: 'header',
            value: 'clothingSizes',
            label: 'clothingSizes',
        },
        {
            type: 'item',
            value: 'oneSize',
            label: 'oneSize',
        },
        {
            type: 'item',
            value: '00',
            label: '00',
        },
        {
            type: 'item',
            value: '0',
            label: '0',
        },
        {
            type: 'item',
            value: '2',
            label: '2',
        },
        {
            type: 'item',
            value: '4',
            label: '4',
        },
        {
            type: 'item',
            value: '6',
            label: '6',
        },
        {
            type: 'item',
            value: '8',
            label: '8',
        },
        {
            type: 'item',
            value: '10',
            label: '10',
        },
        {
            type: 'item',
            value: '12',
            label: '12',
        },
        {
            type: 'item',
            value: '14',
            label: '14',
        },
        {
            type: 'item',
            value: '16',
            label: '16',
        },
        {
            type: 'item',
            value: '18',
            label: '18',
        },
        {
            type: 'item',
            value: '20',
            label: '20',
        },
        {
            type: 'item',
            value: '22',
            label: '22',
        },
    ],
};

/**
 * Shoes, bags, accessories etc
 */
export const sizingOptionsTwoByCountry = {
    FI: [
        {
            type: 'header',
            value: 'shoeSizes',
            label: 'shoeSizes',
        },
        {
            type: 'item',
            value: 'oneSize',
            label: 'oneSize',
        },
        {
            type: 'item',
            value: '35',
            label: '35',
        },
        {
            type: 'item',
            value: '36',
            label: '36',
        },
        {
            type: 'item',
            value: '37',
            label: '37',
        },
        {
            type: 'item',
            value: '38',
            label: '38',
        },
        {
            type: 'item',
            value: '39',
            label: '39',
        },
        {
            type: 'item',
            value: '40',
            label: '40',
        },
        {
            type: 'item',
            value: '41',
            label: '41',
        },
        {
            type: 'item',
            value: '42',
            label: '42',
        },
        {
            type: 'item',
            value: '43',
            label: '43',
        },
        {
            type: 'item',
            value: '44',
            label: '44',
        },
        {
            type: 'item',
            value: '45',
            label: '45',
        },
        {
            type: 'item',
            value: '46',
            label: '46',
        },
        {
            type: 'item',
            value: '47',
            label: '47',
        },
        {
            type: 'item',
            value: '48',
            label: '48',
        },
        {
            type: 'item',
            value: '49',
            label: '49',
        },
    ],
    US: [
        {
            type: 'header',
            value: 'shoeSizes',
            label: 'shoeSizes',
        },
        {
            type: 'item',
            value: 'oneSize',
            label: 'oneSize',
        },
        {
            type: 'item',
            value: '4',
            label: '4',
        },
        {
            type: 'item',
            value: '4.5',
            label: '4.5',
        },
        {
            type: 'item',
            value: '5',
            label: '5',
        },
        {
            type: 'item',
            value: '5.5',
            label: '5.5',
        },
        {
            type: 'item',
            value: '6',
            label: '6',
        },
        {
            type: 'item',
            value: '6.5',
            label: '6.5',
        },
        {
            type: 'item',
            value: '7',
            label: '7',
        },
        {
            type: 'item',
            value: '7.5',
            label: '7.5',
        },
        {
            type: 'item',
            value: '8',
            label: '8',
        },
        {
            type: 'item',
            value: '8.5',
            label: '8.5',
        },
        {
            type: 'item',
            value: '9',
            label: '9',
        },
        {
            type: 'item',
            value: '9.5',
            label: '9.5',
        },
        {
            type: 'item',
            value: '10',
            label: '10',
        },
        {
            type: 'item',
            value: '10.5',
            label: '10.5',
        },
        {
            type: 'item',
            value: '11',
            label: '11',
        },
        {
            type: 'item',
            value: '11.5',
            label: '11.5',
        },
        {
            type: 'item',
            value: '12',
            label: '12',
        },
    ],
};

export const colorOptions = [
    {
        value: 'black',
        colorCode: '#000',
        label: 'black',
    },
    {
        value: 'white',
        colorCode: '#fff',
        label: 'white',
    },
    {
        value: 'metallic',
        colorCode: '#aaa9ad',
        label: 'metallic',
    },
    {
        value: 'grey',
        colorCode: '#808080',
        label: 'grey',
    },
    {
        value: 'beige',
        colorCode: '#F5F5DC',
        label: 'beige',
    },
    {
        value: 'yellow',
        colorCode: '#FFFF00',
        label: 'yellow',
    },
    {
        value: 'gold',
        colorCode: '#FFD700',
        label: 'gold',
    },
    {
        value: 'orange',
        colorCode: '#FFA500',
        label: 'orange',
    },
    {
        value: 'brown',
        colorCode: '#964B00',
        label: 'brown',
    },
    {
        value: 'burgundy',
        colorCode: '#800020',
        label: 'burgundy',
    },
    {
        value: 'red',
        colorCode: '#CD001A',
        label: 'red',
    },
    {
        value: 'pink',
        colorCode: '#FFC0CB',
        label: 'pink',
    },
    {
        value: 'violet',
        colorCode: '#7F00FF',
        label: 'violet',
    },
    {
        value: 'blue',
        colorCode: '#0C71E0',
        label: 'blue',
    },
    {
        value: 'turquoise',
        colorCode: '#30D5C8',
        label: 'turquoise',
    },
    {
        value: 'green',
        colorCode: '#008000',
        label: 'green',
    },
    {
        value: 'multiple',
        src: '/multiple_colors.jpg',
        label: 'multiple',
    },
];

export const deliveryOptions = [
    { value: 'wolt', label: 'woltDelivery' },
    { value: 'showroom', label: 'showroomDelivery' },
    { value: 'faceToFace', label: 'faceToFaceDelivery' },
    { value: 'uber', label: 'uberDelivery' },
] as const;

export const categories = [
    'dresses',
    'bags',
    'shoes',
    'tops',
    'jewellery',
    'coatsAndJackets',
    'accessories',
    'knitwear',
    'suits',
    'trousers',
    'skirts',
    'homewear',
    'shirtsAndTshirts',
    'shorts',
    'jumpsuits',
    'maternityWear',
] as const;

export type Category = typeof categories[number];

export const getSizingOptionsForCategory = (category: Category): ListElement[] => {
    const countryCode = getAppCountryCode();
    const sizingOptionTwoCategories: Category[] = ['shoes', 'bags', 'accessories'];
    const sizingOptionsOne = sizingOptionsOneByCountry[countryCode];
    const sizingOptionsTwo = sizingOptionsTwoByCountry[countryCode];

    let opts: ListElement[] = sizingOptionsOne;

    if (sizingOptionTwoCategories.includes(category)) {
        opts = sizingOptionsTwo;
    }

    // No need to show header if showing options only for one category
    return opts.filter((option) => option.type !== 'header');
};

export const getSizingOptionsForSelectedCategories = (selectedCategories: Category[]): ListElement[] => {
    const countryCode = getAppCountryCode();
    const sizingOptionTwoCategories: Category[] = ['shoes', 'bags', 'accessories'];
    const includeOptionOne = selectedCategories.some((category) => !sizingOptionTwoCategories.includes(category));
    const includeOptionTwo = selectedCategories.some((category) => sizingOptionTwoCategories.includes(category));

    const sizingOptionsOne = sizingOptionsOneByCountry[countryCode];
    const sizingOptionsTwo = sizingOptionsTwoByCountry[countryCode];

    if (selectedCategories.length === 0 || (includeOptionOne && includeOptionTwo)) {
        // Remove duplicate 'oneSize' option
        return [...sizingOptionsOne, ...sizingOptionsTwo.filter((option) => option.value !== 'oneSize')];
    }

    if (includeOptionOne) {
        return sizingOptionsOne;
    }

    if (includeOptionTwo) {
        return sizingOptionsTwo;
    }

    return [];
};

export type Bodytype = 'male' | 'female' | 'unisex';

export const getBodytypeOptions = (): Bodytype[] => ['male', 'female', 'unisex'];
