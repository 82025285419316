import { useQuery } from '@tanstack/react-query';
import { getApiClient } from '../../../services/sharetribe/apiClients';

export interface CreditsConfiguration {
    minimumPurchase: number;
    expirationDays: number;
    currency: string;
}

export const useCreditsConfiguration = () => {
    const getCreditsConfiguration = async () => {
        const { data } = await getApiClient('credits').get<CreditsConfiguration>('configuration');
        return data;
    };

    return useQuery(['credits-configuration'], getCreditsConfiguration);
};
