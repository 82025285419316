import { Box, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SettingsContainer } from '../UserProfile.components';
import { RobesLogoFull } from '../../../components/RobesLogo/RobesLogoFull';
import { ChatWithSupportButton } from '../../../components/ChatWithUserButton/ChatWithSupportButton';

export const ContactUs: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <SettingsContainer>
            <Box display="flex" flexDirection="column" alignItems="center">
                <RobesLogoFull height={80} containerStyle={{ marginBottom: 5 }} />

                <Typography variant="h6" sx={{ width: '100%', fontWeight: 'bold' }}>
                    {t('contactUsPageTitle')}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    {t('contactUsPageBody')}
                </Typography>

                <Divider variant="middle" flexItem sx={{ my: 3 }} />

                <Typography variant="h6" sx={{ width: '100%', fontWeight: 'bold' }}>
                    {t('contactUsChatWithSupportTitle')}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    {t('contactUsChatWithSupportBody')}
                </Typography>

                <ChatWithSupportButton sx={{ my: 2 }} />

                <Divider variant="middle" flexItem sx={{ my: 2 }} />

                <Typography variant="h6" sx={{ width: '100%', fontWeight: 'bold' }}>
                    {t('contactUsEmailSectionTitle')}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    <Trans i18nKey="contactUsEmailSectionBody">
                        Write to us at our email
                        <a href="mailto: hello@robesrental.com" style={{ textDecoration: 'underline', color: theme.palette.primary.main }}>
                            hello@robesrental.com
                        </a>
                        and we will contact you soon.
                    </Trans>
                </Typography>
            </Box>
        </SettingsContainer>
    );
};
