import { ExternalAccount, StripeAccount } from '../../../transactions/apiTypes';

type Requirement = 'currently_due' | 'past_due' | 'eventually_due';

export const hasRequirements = (stripeAccountData: StripeAccount['stripeAccountData'] | undefined, requirements: Requirement[]) => {
    if (!stripeAccountData) {
        return false;
    }

    return requirements.some(
        (requirementType) =>
            stripeAccountData &&
            stripeAccountData.requirements &&
            Array.isArray(stripeAccountData.requirements[requirementType]) &&
            // @ts-ignore
            stripeAccountData.requirements[requirementType].length > 0,
    );
};

export const getBankAccountPlaceholder = (stripeAccountData: StripeAccount['stripeAccountData'] | undefined, country: 'FI' | 'US') => {
    if (!stripeAccountData) {
        return '';
    }

    const predicate = (account: ExternalAccount) => account.default_for_currency && account.country === country;

    if (country === 'FI') {
        const externalAccountLast4 = stripeAccountData?.external_accounts?.data.find(predicate)?.last4;

        return externalAccountLast4 ? ` •••• •••• •••• ${externalAccountLast4}` : '';
    } else if (country === 'US') {
        const externalAccountLast4 = stripeAccountData?.external_accounts?.data.find(predicate)?.last4;
        return externalAccountLast4 ? `•••• •••• ${externalAccountLast4}` : '';
    }

    return '';
};

export const getRoutingNumberPlaceholderMaybe = (stripeAccountData: StripeAccount['stripeAccountData'] | undefined, country: 'FI' | 'US') => {
    if (!stripeAccountData) {
        return '';
    }

    if (country === 'FI') {
        return '';
    } else if (country === 'US') {
        const routingNumber = stripeAccountData?.external_accounts?.data.find((account) => account.default_for_currency)?.routing_number;
        return routingNumber ? routingNumber : '';
    }

    return '';
};
