import React from 'react';
import { OwnTransaction, BookingState } from '../../../transactions/apiTypes';
import { ReactFCWithChildren } from '../../../types/types';
import { DisplayableTransaction } from './DisplayableTransaction';
import { ActionableTransaction } from './ActionableTransaction';
import { DeliveryMethod, OfficeConfigurationMethods } from '../../../queries/useDeliveryTiming';

interface TransactionCardProps {
    transactionData: OwnTransaction;
    deliveryMethods: DeliveryMethod[];
    onAction: (action: 'decline' | 'confirm', transaction: OwnTransaction) => void;
    type: 'sale' | 'order';
}

export const TransactionCard: ReactFCWithChildren<TransactionCardProps> = ({ transactionData, type, onAction, deliveryMethods }) => {
    const actionableStates: BookingState[] = ['pending', 'proposed'];
    const bookingState = transactionData.booking.attributes.state;
    const userNeedsToConfirm = type === 'sale';
    const isActionable =
        userNeedsToConfirm && actionableStates.includes(bookingState) && !transactionData.customer.banned && !transactionData.customer.deleted;

    return (
        <>
            {isActionable ? (
                <ActionableTransaction deliveryMethods={deliveryMethods} transactionData={transactionData} type={type} onAction={onAction} />
            ) : (
                <DisplayableTransaction deliveryMethods={deliveryMethods} transactionData={transactionData} type={type} />
            )}
        </>
    );
};
