import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { isAxiosError, sleep } from '../../../helpers/commonHelpers';

export interface AddedCredits {
    amount: number;
    currency: string;
    expiresAt: string;
}

export interface UserCreditsBalance {
    balance: number;
    currency: string;
    reserve: number;
}

export const useUpdateCreditsBalance = () => {
    const queryClient = useQueryClient();

    return async (data: AddedCredits) => {
        await queryClient.cancelQueries(['credits-balance']);

        const previousCredits = queryClient.getQueryData<UserCreditsBalance>(['credits-balance']);

        const newCreditBalance = previousCredits
            ? {
                  ...previousCredits,
                  balance: previousCredits.balance + data.amount,
              }
            : {
                  balance: data.amount,
                  currency: data.currency,
                  reserve: 0,
              };

        queryClient.setQueryData(['credits-balance'], newCreditBalance);
    };
};

export const useRedeemPromotionalCode = (onSuccess: (data: AddedCredits) => void, onError: (msg: string) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (code: string) => {
            await sleep(500);
            const { data } = await getApiClient('referrals').post<{ data: AddedCredits }>('/code/register', { code });

            return data.data;
        },
        {
            onSuccess: async (data: AddedCredits) => {
                // Backend re-calculates the line items
                await queryClient.invalidateQueries(['line-items']);
                onSuccess(data);
            },
            onError: (err) => {
                let msg = 'invalidCodeError';
                if (isAxiosError(err) && err.response?.data?.msg) {
                    msg = err.response.data.msg;
                }

                onError(msg);
            },
        },
    );
};
