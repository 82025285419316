import { useQuery } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { capitalizeEachWord, capitalizeString } from '../../helpers/commonHelpers';

interface Brand {
    tag: string;
    type: string;
    rank: number;
}

export const useBrands = () => {
    const getBrands = async () => {
        const { data: tags } = await getApiClient('tags').get<Brand[]>('/');

        const brands = tags.sort((a, b) => a.tag.localeCompare(b.tag)).map((tag) => ({ value: tag.tag, label: capitalizeEachWord(tag.tag) }));

        return brands;
    };

    return useQuery(['brands'], getBrands);
};
