import React from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { Location, NavigateFunction, matchPath, useLocation } from 'react-router-dom';
import { ReactFCWithChildren } from '../../types/types';
import { useLogout, UseLogoutArgs } from '../../hooks/auth/useLogout';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { UseMutateFunction } from '@tanstack/react-query';

const logoutOnRoutes = ['/verification-pending', '/verify-otp', '/identify'];
const navigateHomeOnRoutes = ['/wardrobe', '/add-listing', '/listings'];

const handleNavigateBack = (location: Location, logoutFn: (args: UseLogoutArgs) => void, navigateFn: NavigateFunction) => {
    const { pathname, search } = location;

    if (matchPath('/listing/:id/checkout', pathname)) {
        const hasIdentificationState = new URLSearchParams(search).get('identification');
        if (hasIdentificationState) {
            navigateFn('/');
            return;
        }
    }

    if (pathname === '/login/email') {
        navigateFn('/login', { replace: true });
    } else if (pathname.includes('/reset-password')) {
        navigateFn('/login/email', { replace: true });
    } else if (logoutOnRoutes.some((route) => pathname.includes(route))) {
        logoutFn({});
    } else if (pathname.includes('/create-password')) {
        navigateFn('/profile/delete-account', { replace: true });
    } else if (navigateHomeOnRoutes.some((route) => pathname.includes(route))) {
        navigateFn('/');
    } else {
        navigateFn(-1);
    }
};

interface CustomBackButtonProps {
    isBackButtonVisible?: boolean;
}

export const CustomBackButton: ReactFCWithChildren<CustomBackButtonProps> = ({ isBackButtonVisible }) => {
    const location = useLocation();
    const { mutate: logout } = useLogout();
    const navigate = useSafeNavigate();

    return (
        <AnimatePresence>
            {isBackButtonVisible && (
                <motion.div
                    initial={{ x: -10, width: 0, scale: 0 }}
                    animate={{ x: 0, rotate: 0, opacity: 1, width: '40px', scale: 1 }}
                    exit={{ x: -10, opacity: 0, width: 0, scale: 0 }}
                >
                    <IconButton
                        onClick={() => {
                            handleNavigateBack(location, logout, navigate);
                        }}
                    >
                        <ChevronLeft />
                    </IconButton>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
