import React from 'react';
import { SuggestedPrices } from './PricingModelForm.types';
import { Box, Button, Typography } from '@mui/material';
import { formatPercentage, formatPrice } from '../../helpers/commonHelpers';
import { useTranslation } from 'react-i18next';
import { minimumRentalLength } from '../../constants';

const SummaryItem: React.FC<{ primary: React.ReactNode; secondary: React.ReactNode }> = ({ primary, secondary }) => {
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2">{primary}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {secondary}
            </Typography>
        </Box>
    );
};

export const PricingSummary: React.FC<{ prices: SuggestedPrices; toggleEdit: () => void; editable: boolean }> = ({
    prices,
    toggleEdit,
    editable,
}) => {
    const { daily, weekly, monthly } = prices;
    const { t } = useTranslation();

    const formatPercentageString = (amount: number | undefined) => {
        if (amount === 0) {
            return ` (${t('noDiscount')})`;
        }
        const formatted = formatPercentage(amount);
        return formatted ? ` ${formatted}` : '';
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="h6" fontWeight={600}>
                    {t('pricingSummary')}
                </Typography>
            </Box>

            <SummaryItem primary={t('dailyPrice')} secondary={formatPrice(daily.value)} />
            <SummaryItem primary={t('minimumRental', { value: minimumRentalLength })} secondary={formatPrice(daily.value * 3)} />
            <SummaryItem primary={`${t('weeklyRental')}${formatPercentageString(weekly.percentage)}`} secondary={formatPrice(weekly.value)} />
            <SummaryItem primary={`${t('monthlyRental')}${formatPercentageString(monthly.percentage)}`} secondary={formatPrice(monthly.value)} />

            <Button variant="text" onClick={toggleEdit} sx={{ p: '6px 0px', minWidth: '0' }}>
                {editable ? t('cancel') : t('editPricing')}
            </Button>
        </Box>
    );
};
