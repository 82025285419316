import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { TagTypeEnum } from '../../types/enums';

export interface TagObject {
    id?: string;
    tag: string;
    type: string;
    new?: boolean;
}

export const useSearchTags = (type: TagTypeEnum, query: string, includeNew = true): UseQueryResult<TagObject[]> => {
    const searchTagsFn = async (): Promise<TagObject[]> => {
        const url = `/search/?type=${type}&query=${query}`;
        const response = await getApiClient('tags').get<TagObject[]>(url);

        const isNewTag = response.data.every((tag) => tag.tag !== query.toLowerCase());

        if (query.length >= 3 && isNewTag && includeNew) {
            return response.data.concat({ tag: query, type: TagTypeEnum.BRAND, new: true });
        }

        return response.data;
    };

    return useQuery(['tags', { type, query }], searchTagsFn, {
        staleTime: 5 * 60 * 1000,
    });
};
