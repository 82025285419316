import {
    addMinutes,
    differenceInDays,
    differenceInHours,
    format,
    formatDistance,
    formatDistanceToNow,
    formatISO,
    getDate,
    getMonth,
    getYear,
    isAfter,
    isSameDay,
    parse,
    parseISO,
    set,
} from 'date-fns';
import { enGB, fi, enUS } from 'date-fns/locale';
import i18next from 'i18next';
import { assertNever } from './commonHelpers';
import { getAppCountryCode } from '../countryConfigs';
import { toZonedTime, format as tzFormat } from 'date-fns-tz';
import { AvailabilityException } from '../queries/useDeliveryTiming';

export type NullableDateLike = string | Date | undefined | null;

export const getCurrentLanguage = () => {
    return i18next.language || window.localStorage.i18nextLng || 'en';
};

export const getCurrentLocale = () => {
    const currentLang = i18next.language || window.localStorage.i18nextLng || '';
    if (currentLang === 'fi') {
        return fi;
    }
    if (currentLang === 'en') {
        return enGB;
    }
    return enGB;
};

function formatDate(date: NullableDateLike, dateFormat: string, utc = false): string {
    if (!date) {
        return '';
    }

    const countryCode = getAppCountryCode();
    const locale = countryCode === 'US' ? enUS : getCurrentLocale();
    const formatString = countryCode === 'US' ? dateFormat.replace('HH:mm', 'hh:mm a') : dateFormat;

    const toDateFormat = (date: Date) => {
        if (utc) {
            const utcDate = toZonedTime(date, 'UTC');

            return tzFormat(utcDate, formatString, { timeZone: 'UTC' });
        }

        return format(date, formatString, { locale });
    };

    if (typeof date === 'string') {
        return toDateFormat(parseISO(date));
    }

    if (date instanceof Date) {
        return toDateFormat(date);
    }

    return assertNever(date);
}

export const formatDateAndTimeLong = (date: NullableDateLike): string => formatDate(date, 'PPp');

// e.g "10:00"
export const formatTime = (date: NullableDateLike): string => formatDate(date, 'HH:mm');

// e.g "Mon 11:38"
export const formatShortDateAndTime = (date: NullableDateLike): string => formatDate(date, 'ccc HH:mm');

// e.g "May 15"
export const formatMonthAndDate = (date: NullableDateLike): string => formatDate(date, 'MMMM d');

// e.g "15 May 2020"
export const formatDayMonthAndYear = (date: NullableDateLike): string => formatDate(date, 'PPPP');

// e.g "15.5.2020"
export const formatShortDate = (date: NullableDateLike): string => formatDate(date, 'P');

// e.g "Monday, May 15 January"
export const formatDayMonthAndDate = (date: NullableDateLike): string => formatDate(date, 'EEEE, d MMMM');

// e.g "Nov 15"
export const formatShortMonthAndDate = (date: NullableDateLike, utc?: boolean): string => formatDate(date, 'MMM d', utc);

// e.g "Nov 15, 10:00"
export const formatShortMonthAndDateAndTime = (date: NullableDateLike): string => formatDate(date, 'MMM d, HH:mm');

// e.g 10/05/2020, 23:10
export const formatLongDateAndTime = (date: NullableDateLike): string => formatDate(date, 'P, HH:mm');

// e.g "May 15, 2020"
export const formatLongDateAndYear = (date: NullableDateLike): string => formatDate(date, 'PP');

// e.g "Monday, May 15, 23:10"
export const formatDayOfWeekAndMonthAndDateAndTime = (date: NullableDateLike): string => formatDate(date, 'EEEE, MMMM d, HH:mm');

export const formatDateRange = (startDate: NullableDateLike, endDate: NullableDateLike): string => {
    if (!startDate || !endDate) {
        return '';
    }

    if (isSameDay(startDate, endDate)) {
        return formatDate(startDate, 'd MMMM');
    }

    const startDay = formatDate(startDate, 'd');
    const endDay = formatDate(endDate, 'd MMMM');

    return `${startDay}-${endDay}`;
};

// e.g Monday, May 15, 23:10 - 23:40
export const formatDateAndTimeRange = (d: NullableDateLike, interval: number): string => {
    if (!d) {
        return '';
    }

    const date = typeof d === 'string' ? parseISO(d) : d;
    return `${formatDayOfWeekAndMonthAndDateAndTime(date)} - ${formatTime(addMinutes(date, interval))}`;
};

export const formatTimeRange = (d: NullableDateLike, durationInMinutes = 30) => {
    if (!d) {
        return '';
    }

    const date = typeof d === 'string' ? parseISO(d) : d;

    return `${formatTime(date)} - ${formatTime(addMinutes(date, durationInMinutes))}`;
};

// Note: use only with hours in format "HH:mm"
export const formatHHMMTimeRange = (hhmm: string, durationInMinutes = 30) => {
    const d = parseHHMMTimeString(hhmm);

    return formatTimeRange(d, durationInMinutes);
};

export const parseHHMMTimeString = (hhmm: string) => {
    const d = new Date();

    // Check if the input contains "AM" or "PM"
    let timeParts = hhmm.trim().toUpperCase();
    let isPM = timeParts.includes('PM');
    timeParts = timeParts.replace(/AM|PM/, '').trim(); // Remove AM/PM for further processing

    const [hoursString, minutesString] = timeParts.split(':');
    let hours = Number(hoursString);
    const minutes = Number(minutesString);

    // Convert 12-hour format to 24-hour format if necessary
    if (isPM && hours < 12) {
        hours += 12; // Add 12 hours for PM times, except for 12 PM
    } else if (!isPM && hours === 12) {
        hours = 0; // Midnight case
    }

    d.setHours(hours, minutes, 0, 0);

    return d;
};

// Note: accepts only hours in format "HH:mm:ss"
export const formatHoursAndMinutes = (hour: string) => {
    const countryCode = getAppCountryCode();
    const date = parse(hour, 'HH:mm:ss', new Date());

    if (countryCode === 'US') {
        return format(date, 'h:mm a');
    }

    return format(date, 'HH:mm');
};

export const getHoursDifference = (date: string, compareDate: string): number => differenceInHours(new Date(date), new Date(compareDate));

export const getDaysDifference = (date: string, compareDate: string): number => differenceInDays(new Date(date), new Date(compareDate));

export const getDurationInDays = (startDate: string, endDate: string): number => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    return differenceInDays(end, start) + 1;
};

export const isDifferentDate = (date: string, compareDate: string): boolean => {
    const d1 = formatISO(parseISO(date), { representation: 'date' });
    const d2 = formatISO(parseISO(compareDate), { representation: 'date' });

    return d1 !== d2;
};

export const formatFlexibleDateTime = (date: string): string => {
    const now = new Date();
    const compareDate = new Date(date);

    const daysDifference = differenceInDays(now, compareDate);

    if (daysDifference < 1) {
        return formatTime(date);
    }
    if (daysDifference >= 1 && daysDifference <= 7) {
        return formatShortDateAndTime(date);
    }
    if (daysDifference > 7 && daysDifference <= 365) {
        return formatMonthAndDate(date);
    }
    return formatLongDateAndTime(date);
};

export const formatDistanceLocalized = (d1: Date, d2: Date) => formatDistance(d1, d2, { locale: getCurrentLocale() });
export const formatDistanceNow = (date: string): string => formatDistanceToNow(new Date(date), { locale: getCurrentLocale() });

export const convertToMidnightUTC = (referenceDate: Date) => {
    const year = getYear(referenceDate);
    const month = getMonth(referenceDate);
    const date = getDate(referenceDate);

    const midnightUTC = new Date(Date.UTC(year, month, date, 0, 0, 0));

    return midnightUTC;
};

export const getLaterDate = (d1: Date | null, d2: Date | null) => {
    if (!d1) {
        return d2;
    }
    if (!d2) {
        return d1;
    }
    return isAfter(d1, d2) ? d1 : d2;
};

export const formatAvailabilityExceptions = (exceptions: AvailabilityException[]) => {
    if (!exceptions?.length) {
        return '';
    }

    return exceptions.map((exception) => formatDateRange(exception.startDate, exception.endDate)).join(',<br />');
};

export const parseDateStringUTC = (d: string | undefined) => {
    if (!d) {
        return undefined;
    }

    const parsedDate = parseISO(d);
    const utcDate = set(parsedDate, { year: parsedDate.getUTCFullYear(), month: parsedDate.getUTCMonth(), date: parsedDate.getUTCDate() });
    return utcDate;
};
