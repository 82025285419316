import React, { useState } from 'react';
import { Direction, Stepper } from '../Stepper/Stepper';
import { StartStep } from './Steps/Start';
import { LendingDescriptionStep } from './Steps/LendingDescription';
import { Step } from '../Stepper/Step';
import { AddBioStep } from './Steps/AddBio';
import { AddProfilePictureStep } from './Steps/AddProfilePicture';
import { RentingDescriptionStep } from './Steps/RentingDescription';
import { FinishStep } from './Steps/Finish';
import { OnboardingState, User } from '../../types/apiTypes';
import { useUpdateOnboardingState } from './useUpdateOnboardingState';
import { useQueryClient } from '@tanstack/react-query';
import { OnboardingStep } from './UserOnboarding.types';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { generateOnboardingSteps, getNextOnboardingState } from './UserOnboarding.helpers';
import { VerificationStep } from './Steps/Verification';
import { IdentityVerificationState } from '../../views/IdentityVerification/IdentityVerification';
import { analytics } from '../../analytics/events/helpers';
import { NotificationPermissionsStep } from './Steps/NotificationPermissions';
import { dispatchCustomEvent } from '../../store/listeners';

interface UserOnboardingSteps {
    onEnd: () => void;
    user: User;
    onboardingState: OnboardingState;
    identification: IdentityVerificationState;
}

export const UserOnboarding: React.FC<UserOnboardingSteps> = ({ onboardingState, onEnd, user, identification }) => {
    const [existingUser] = useState(() => onboardingState === 'existing-user');
    const [steps] = useState<OnboardingStep[]>(() => generateOnboardingSteps(onboardingState, user));
    const [activeStep, setActiveStep] = useState(0);
    const [direction, setDirection] = useState<Direction>();

    const queryClient = useQueryClient();

    const goForward = () => {
        analytics.onboardingStepComplete(steps[activeStep]);

        setActiveStep(activeStep + 1);
        setDirection('right');
    };

    const goBack = () => {
        setActiveStep(activeStep - 1);
        setDirection('left');
    };

    const { mutate: updateOnboardingState, isLoading } = useUpdateOnboardingState(goForward);

    const handleStepComplete = (value: OnboardingStep) => {
        const nextOnboardingState = getNextOnboardingState(value, steps);

        if (nextOnboardingState) {
            updateOnboardingState(nextOnboardingState);
        } else {
            goForward();
        }
    };

    const handleFinishOnboarding = async () => {
        await getApiClient('user').put('/', { privateData: { onboardingState: 'complete' } });

        queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
            if (!userData) {
                return undefined;
            }
            return { ...userData, privateData: { ...userData.profile.privateData, onboardingState: 'complete' } };
        });

        onEnd();
    };

    const handleLendingStepComplete = async (interestedInLending: boolean) => {
        const handleMarkUserAsInterestedInLending = async () => {
            await getApiClient('user').put('/', { privateData: { interestedInLending: true } });

            queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
                if (!userData) {
                    return undefined;
                }
                return { ...userData, privateData: { ...userData.profile.privateData, interestedInLending: true } };
            });
        };

        if (interestedInLending) {
            await handleMarkUserAsInterestedInLending();
        }

        handleStepComplete('lendingDescription');
    };

    // Request push notification permissions and update user settings
    const handleNotificationPermissionsStepComplete = (notificationsAllowed: boolean) => {
        if (notificationsAllowed) {
            dispatchCustomEvent('enabledPushNotifications');
        }

        handleStepComplete('notificationPermissions');
    };

    const hideIndicators = activeStep === steps.length - 1;

    return (
        <Stepper activeStep={activeStep} direction={direction} hideIndicators={hideIndicators}>
            {steps.map((step, i) => (
                <Step key={step}>
                    {step === 'greetings' && (
                        <StartStep user={user} loading={isLoading} onComplete={() => handleStepComplete('greetings')} existingUser={existingUser} />
                    )}

                    {step === 'rentalDescription' && (
                        <RentingDescriptionStep loading={isLoading} onComplete={() => handleStepComplete('rentalDescription')} />
                    )}

                    {step === 'lendingDescription' && (
                        <LendingDescriptionStep user={user} loading={isLoading} onComplete={handleLendingStepComplete} onGoBack={goBack} />
                    )}

                    {step === 'notificationPermissions' && (
                        <NotificationPermissionsStep
                            user={user}
                            loading={isLoading}
                            onComplete={handleNotificationPermissionsStepComplete}
                            onGoBack={goBack}
                            hasPreviousStep={i > 0}
                        />
                    )}

                    {step === 'verification' && (
                        <VerificationStep
                            identificationState={identification}
                            loading={isLoading}
                            onComplete={() => handleStepComplete('verification')}
                            onGoBack={goBack}
                            hasPreviousStep={i > 0}
                        />
                    )}

                    {step === 'picture' && (
                        <AddProfilePictureStep
                            loading={isLoading}
                            user={user}
                            hasPreviousStep={i > 0}
                            existingUser={existingUser}
                            onComplete={() => handleStepComplete('picture')}
                            onGoBack={goBack}
                        />
                    )}

                    {step === 'bio' && (
                        <AddBioStep
                            loading={isLoading}
                            user={user}
                            hasPreviousStep={i > 0}
                            onComplete={() => handleStepComplete('bio')}
                            onGoBack={goBack}
                        />
                    )}

                    {step === 'complete' && <FinishStep key={step} onComplete={handleFinishOnboarding} />}
                </Step>
            ))}
        </Stepper>
    );
};
