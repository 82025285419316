import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationResult } from '../../types/apiTypes';
import { Transaction } from '../apiTypes';
import { TransactionTransition } from '../types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { sleep } from '../../helpers/commonHelpers';

/**
 * Transitions a single transaction from a state to another.
 * Required params are different for each transition: see TransactionTransition
 */
const transitionTransactionFn = async (params: TransactionTransition): Promise<Transaction['data']> => {
    const url = `/transition/`;
    const { data } = await getApiClient('transactions').post<Transaction>(url, {
        ...params,
    });

    return data.data;
};

export const useTransitionTransaction = (onSuccess?: () => void): MutationResult<Transaction['data']> => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(transitionTransactionFn, {
        onSuccess: (_, params) => {
            onSuccess?.();
            const msg = t(params.transition);
            toast.success(msg, { hideProgressBar: true, autoClose: 3000 });

            const invalidationKeys = [['own-transactions'], ['transaction', { transactionId: params.transactionId }]];
            invalidationKeys.forEach((key) => queryClient.invalidateQueries(key));
        },
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg);
        },
    });
};
