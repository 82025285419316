import { QueryClient, QueryKey, InfiniteData, useQueryClient, useMutation } from '@tanstack/react-query';
import { assertIsDefined } from '../../helpers/commonHelpers';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { ConversationHistory, MessageHistory } from '../apiTypes';
import { updateReadStatusInConversationsOverview } from './useMarkAsSeen';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';

const markAsUnreadFn = async (conversationId: string) => {
    const { data } = await getApiClient('conversations').put(`${conversationId}/mark-as-unread`);
    return data;
};

const markLastMessageAsUnreadInMessageHistory = (queryClient: QueryClient, queryKey: QueryKey) => {
    const messageHistory = queryClient.getQueryData<InfiniteData<MessageHistory>>(queryKey);

    let markedAsUnread = false;

    if (messageHistory) {
        queryClient.setQueryData<InfiniteData<MessageHistory>>(queryKey, (history) => {
            assertIsDefined(history, 'Message history is undefined');

            history.pages[0].data[0].readAt = null;

            markedAsUnread = true;
            return history;
        });
    }

    return markedAsUnread;
};

export const markLastMessageAsUnreadInConversationHistory = (queryClient: QueryClient, conversationId: string) => {
    const conversationHistory = queryClient.getQueryData<InfiniteData<ConversationHistory> | undefined>(['conversation-history']);

    if (conversationHistory) {
        queryClient.setQueryData<InfiniteData<ConversationHistory> | undefined>(['conversation-history'], (history) => {
            assertIsDefined(history, 'Conversation history is undefined');

            const idx = history.pages.findIndex((page) => page?.data.find((conversation) => conversation.conversationId === conversationId));

            if (idx > -1) {
                const page = history.pages[idx];
                const conversation = page?.data.find((conversation) => conversation.conversationId === conversationId);
                if (conversation) {
                    conversation.lastMessage.readAt = null;
                }
            }

            return history;
        });
    }
};

type MarkAsUnreadOptions = { onSuccess?: () => void; onError?: () => void; onMutate?: () => void };

export const useMarkConversationAsUnread = ({ onMutate }: MarkAsUnreadOptions) => {
    const queryClient = useQueryClient();

    return useMutation(markAsUnreadFn, {
        onMutate: (conversationId: string) => {
            if (!conversationId) {
                return;
            }

            const queryKey: QueryKey = ['message-history', { conversationId }];
            const previousMessages = cloneDeep(queryClient.getQueryData<InfiniteData<MessageHistory>>(queryKey));
            const previousConversationHistory = cloneDeep(queryClient.getQueryData<InfiniteData<ConversationHistory>>(['conversation-history']));
            const previousConversationsOverview = cloneDeep(queryClient.getQueryData<InfiniteData<ConversationHistory>>(['conversations-overview']));

            markLastMessageAsUnreadInMessageHistory(queryClient, queryKey);
            updateReadStatusInConversationsOverview(queryClient, conversationId, false);
            markLastMessageAsUnreadInConversationHistory(queryClient, conversationId);

            onMutate?.();

            return { previousMessages, previousConversationHistory, previousConversationsOverview };
        },
        onError: (_err, conversationId, context) => {
            // Revert the optimistic update on error
            toast.error('Failed to mark as unread, reverting unread status');

            if (context) {
                const { previousMessages, previousConversationHistory, previousConversationsOverview } = context;

                queryClient.setQueryData(['message-history', { conversationId }], previousMessages);
                queryClient.setQueryData<InfiniteData<ConversationHistory>>(['conversation-history'], previousConversationHistory);
                queryClient.setQueryData<InfiniteData<ConversationHistory>>(['conversations-overview'], previousConversationsOverview);
            }
        },
    });
};
