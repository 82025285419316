import { Box } from '@mui/material';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { SliderRange } from '../../../../components/SliderRange/SliderRange';
import { getCurrencySymbol } from '../../../../helpers/commonHelpers';
import { defaultPriceRange } from '../../constants';
import { ApplyFiltersButton } from './ApplyFiltersButton';
import { getAppCurrency } from '../../../../countryConfigs';

interface PriceFilterOptionsProps {
    selectedValue: number[];
    totalItems: number;
    loading: boolean;
    onUpdate: (values: number[]) => void;
    onApplyFilters: () => void;
}

export const PriceFilterOptions = forwardRef(({ selectedValue, totalItems, loading, onUpdate, onApplyFilters }: PriceFilterOptionsProps, ref) => {
    const { t } = useTranslation();

    const [range, setRange] = useState(selectedValue);

    const onPriceRangeChange = (values: number[]) => {
        onUpdate(values);
    };

    useImperativeHandle(ref, () => ({
        resetPriceRange: () => {
            setRange(defaultPriceRange);
            onUpdate(defaultPriceRange);
        },
    }));

    const currency = getAppCurrency();
    const label = `${t('dailyPrice')} (${getCurrencySymbol(currency)})`;

    return (
        <Box sx={{ p: 0, paddingBottom: 'env(safe-area-inset-bottom)' }}>
            <Box sx={{ padding: '30px 20px 100px' }}>
                <SliderRange onChangeCommitted={onPriceRangeChange} value={range} onChange={setRange} label={label} />
            </Box>
            <ApplyFiltersButton
                onClick={onApplyFilters}
                totalItems={totalItems}
                loading={loading}
                style={{ bottom: 'calc(10px + env(safe-area-inset-bottom) / 2)' }}
            />
        </Box>
    );
});
