import React from 'react';
import { Typography } from '@mui/material';
import { CenteredBox } from './IdentityVerification.components';
import { Image, rem } from '@mantine/core';
import { IdentityVerificationSuccess } from './IdentityVerificationSuccess';
import { IdentityVerificationError } from './IdentityVerificationError';
import { Trans, useTranslation } from 'react-i18next';
import { StyledLinkColor } from '../../components/Styled/Styled.components';
import { StaggerList, StaggerListElement } from '../../animations/components/Stagger';
import { fadeInOutScale } from '../../animations/constants';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { StripeIdentityPending } from './StripeIdentityPending';
import { StripeIdentityRequiresInput } from './StripeIdentityRequiresInput';

export type IdentityVerificationState = 'initial' | 'success' | 'error' | 'abort' | 'pending-stripe' | 'requires-input';

interface IdentityVerificationProps {
    identificationProvider: 'signicat' | 'stripe';
    state: IdentityVerificationState;
    stripeIdentificationErrorReason?: string;
    timedOut?: boolean;
    refreshDuration?: number;
    onTimeout?: () => void;
    onRefresh?: () => Promise<void>;
}

export const IdentityVerification: React.FC<IdentityVerificationProps> = ({
    identificationProvider,
    state,
    stripeIdentificationErrorReason,
    timedOut,
    onTimeout,
    refreshDuration,
    onRefresh,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <AnimatedContainer variants={fadeInOutScale} style={{ width: '100%' }}>
                <Image src="https://robes-marketing.s3.eu-north-1.amazonaws.com/signicat_banner.png" withPlaceholder height={200} />
            </AnimatedContainer>

            <CenteredBox sx={{ px: 2 }}>
                {state === 'success' && <IdentityVerificationSuccess />}
                {state === 'error' && <IdentityVerificationError title={t('verificationError')} />}
                {state === 'abort' && <IdentityVerificationError title={t('verificationAborted')} />}
                {state === 'requires-input' && <StripeIdentityRequiresInput reason={stripeIdentificationErrorReason} />}
                {state === 'pending-stripe' && (
                    <StripeIdentityPending refreshDuration={refreshDuration} timedOut={timedOut} onTimeout={onTimeout} onRefresh={onRefresh} />
                )}
                {state === 'initial' && (
                    <StaggerList
                        custom={{ stagger: 0.3, delay: 0.6 }}
                        style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
                    >
                        <StaggerListElement>
                            <Typography variant="h6" fontWeight="bold">
                                {t('verifyIdentity')}
                            </Typography>
                        </StaggerListElement>

                        <StaggerListElement>
                            <Typography variant="subtitle2">{t('verifyIdentityDescription')}</Typography>
                        </StaggerListElement>

                        <StaggerListElement>
                            <Typography variant="subtitle2" sx={{ fontSize: '0.65rem', opacity: 0.7 }}>
                                <Trans
                                    i18nKey={`${identificationProvider}PolicyConsent`}
                                    components={{ button: <StyledLinkColor to="/privacy-policy">{t('privacyPolicy')}</StyledLinkColor> }}
                                />
                            </Typography>
                        </StaggerListElement>
                    </StaggerList>
                )}
            </CenteredBox>
        </>
    );
};
