import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { toast } from 'react-toastify';

export const useRequestResetPassword = (onSuccess: () => void) => {
    const { t } = useTranslation();

    const requestResetPasswordFn = async (email: string) => {
        const url = '/request';
        const { data } = await getApiClient('password-reset').post(url, { email });

        return data;
    };

    return useMutation(requestResetPasswordFn, {
        onSuccess,
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg);
        },
    });
};
