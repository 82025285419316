import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { messagesPerPage } from '../../constants';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { Conversation, ConversationResponse } from '../../types/apiTypes';
import { LoadingStatus } from '../../types/shared';
import { useCurrentUser } from '../../user/hooks/useUser';
import { Message, MessageHistory } from '../apiTypes';

export interface ChatInformation {
    conversationId: string | undefined;
    messageHistory: InfiniteData<Message[]> | undefined;
    participantId: string;
    disabled?: boolean;
    topic?: { listingId: string };
}

interface UseChat {
    chatInfo: ChatInformation;
    loadingStatus: LoadingStatus;
    isFetchingNextPage: boolean;
    hasNextPage: boolean | undefined;
    fetchNextPage: () => Promise<unknown>;
}

export const fetchConversation = async (participantId: string) => {
    if (!participantId) {
        return null;
    }

    const url = `/${participantId}`;
    const { data } = await getApiClient('conversations').get<ConversationResponse>(url);

    return data;
};

/**
 * Returns a conversation between participants along with participant data
 */
export const useConversation = (participantId: string): UseQueryResult<Conversation> => {
    const fetchFn = async () => {
        const data = await fetchConversation(participantId);
        return data;
    };

    return useQuery(['conversation', { participantId }], fetchFn, {
        staleTime: 5 * 60 * 1000,
    });
};

/**
 * Returns a specific conversation's message history
 */
export const useMessageHistory = (conversationId: string | undefined, startingPage = 1): UseInfiniteQueryResult<Message[]> => {
    const getMessages = async ({ pageParam = startingPage }) => {
        if (!conversationId) {
            return null;
        }
        const url = `/${conversationId}/messages?page=${pageParam}&perPage=${messagesPerPage}`;
        const { data } = await getApiClient('conversations').get<MessageHistory>(url);

        return { data: data.data, nextPage: data?.meta?.hasNextPage ? data.meta.page + 1 : undefined };
    };

    return useInfiniteQuery(['message-history', { conversationId }], getMessages, {
        getNextPageParam: (lastPage) => lastPage?.nextPage,
        select: (data) => ({ ...data, pages: data.pages.map((page) => page?.data || []) }),
        enabled: !!conversationId,
        refetchOnWindowFocus: 'always',
    });
};

/**
 * Returns message history for given participants.
 * If the page has access to a specific conversationId, use useMessageHistory instead
 */
export const useChat = (participantId: string): UseChat => {
    const { data: conversation, isLoading: conversationLoading, status: conversationLoadingStatus } = useConversation(participantId);
    const {
        data: messageHistory,
        status: messagesLoadingStatus,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useMessageHistory(conversation?.conversationId);

    const loadingStatus = conversationLoading ? conversationLoadingStatus : messagesLoadingStatus;

    return {
        chatInfo: {
            conversationId: conversation?.conversationId,
            topic: conversation?.topic,
            messageHistory,
            disabled: conversation?.disabled,
            participantId,
        },
        loadingStatus,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    };
};
