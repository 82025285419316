import { ChevronRight, Engineering } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLogout } from '../../../hooks/auth/useLogout';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { UserInfo } from '../UserProfile';
import { routeLinks } from './routes';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { DeveloperSettings } from '../../DeveloperSettings/DeveloperSettings';

const devRoute = {
    label: 'Developer settings',
    path: 'dev',
    icon: <Engineering />,
    component: <DeveloperSettings />,
};

export const Menu: React.FC = () => {
    const { t } = useTranslation();
    const { data: user } = useCurrentUser();
    const { mutate: handleLogout, status: logoutStatus } = useLogout();
    const isDesktop = useBreakpoint('sm');

    if (!user) {
        return null;
    }

    let links = routeLinks;

    if (user.profile.privateData.developer) {
        links = [...routeLinks, devRoute];
    }

    return (
        <>
            <UserInfo user={user} />
            <List sx={{ width: '100%', padding: isDesktop ? '20px 20px 26px' : '20px 20px 86px' }}>
                {links.map((route) => (
                    <ListItem
                        key={route.path}
                        to={route.path}
                        component={Link}
                        sx={{ color: 'text.primary' }}
                        secondaryAction={
                            route.suffix ? (
                                <Box sx={{ display: 'flex' }}>
                                    {route.suffix}
                                    <IconButton edge="end" aria-label="delete">
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            ) : (
                                <IconButton edge="end" aria-label="delete">
                                    <ChevronRight />
                                </IconButton>
                            )
                        }
                    >
                        <ListItemIcon>{route.icon}</ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={t(route.label)} />
                    </ListItem>
                ))}

                <ListItem>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        onClick={() => handleLogout({})}
                        loading={logoutStatus === 'loading'}
                        sx={{ width: '100%', borderRadius: '10px', marginTop: '20px' }}
                    >
                        {t('logout')}
                    </LoadingButton>
                </ListItem>
            </List>
        </>
    );
};
