import React from 'react';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { useQuery } from '@tanstack/react-query';
import { StrapiComponent } from '../types';
import { renderStrapiComponent } from '../helpers';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { DynamicComponentSkeleton } from './Skeletons/DynamicComponentSkeleton';
import { sleep } from '../../helpers/commonHelpers';
import { Fab, useTheme } from '@mui/material';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { ChevronLeft } from '@mui/icons-material';
import { getPathConfig, resolveConfigValue } from '../../routes/helpers';
import { useBreakpoint } from '../../hooks/useBreakpoint';

type DynamicStrapiPageName = 'fit-guarantee' | 'renter-protection' | 'how-renting-works';

const useDynamicStrapiPage = (name: DynamicStrapiPageName) => {
    const getFn = async () => {
        const url = `/${name}-page`;
        const { data } = await getApiClient('strapi').get<{ components: StrapiComponent[] }>(url);

        await sleep(500);

        return data;
    };

    return useQuery([name], getFn);
};

const BackButton: React.FC = () => {
    const theme = useTheme();
    const navigate = useSafeNavigate();

    return (
        <Fab
            size="small"
            sx={{
                zIndex: 12,
                margin: '10px',
                position: 'absolute',
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
            }}
            onClick={() => navigate(-1)}
        >
            <ChevronLeft />
        </Fab>
    );
};

export const DynamicStrapiPage: React.FC<{ name: DynamicStrapiPageName }> = ({ name }) => {
    const { data, status } = useDynamicStrapiPage(name);
    const { hideTopbar } = getPathConfig(`/${name}`);
    const isDesktop = useBreakpoint('sm');
    const resolvedHideTopbar = resolveConfigValue(hideTopbar, isDesktop);

    return (
        <>
            {resolvedHideTopbar && <BackButton />}

            <ContentLoader
                status={status}
                skeleton={<DynamicComponentSkeleton />}
                style={{ display: 'block', overflowY: 'auto', paddingBottom: '30px' }}
            >
                {data?.components.map((component) => (
                    <React.Fragment key={`${component.type}-${component.id}`}>{renderStrapiComponent(component, { isDesktop })}</React.Fragment>
                ))}
            </ContentLoader>
        </>
    );
};
