import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ApplyFiltersButton, FooterContent, StyledFooter } from './Filter.components';
import { SliderRange } from '../../../../components/SliderRange/SliderRange';
import { useAppSelector } from '../../../../store/hooks';
import { defaultPriceRange, Filter } from '../../constants';
import { selectPriceFilter } from '../../../../store/listingsReducer';

import { ReactFCWithChildren } from '../../../../types/types';
import { Sort } from '../Sort/Sort';
import { Category, getSizingOptionsForSelectedCategories } from '../../../AddListing/constants';
import { DrawerMultiSelect } from '../../../../components/DrawerSelect/DrawerMultiSelect';
import { isEqual } from 'lodash';
import { ListingsState, ListingsStateAction } from '../../types';
import { FilterOptions } from './helpers';
import { getCurrencySymbol } from '../../../../helpers/commonHelpers';
import { getAppCurrency } from '../../../../countryConfigs';

export interface FooterProps {
    totalItems?: number;
    loading: boolean;
    t: TFunction<'translation', undefined>;
    onApply: () => void;
}

export interface HeaderProps {
    onResetFilters: () => void;
}

const Footer: ReactFCWithChildren<FooterProps> = ({ totalItems, onApply, loading, t }) => (
    <StyledFooter>
        <Divider />
        <FooterContent>
            <ApplyFiltersButton variant="contained" onClick={onApply} loading={loading}>
                {totalItems ? t('showXResults', { amount: totalItems }) : t('noResults')}
            </ApplyFiltersButton>
        </FooterContent>
    </StyledFooter>
);

interface SliderRangeWrapperProps {
    refresh: number;
    onUpdate: (values: number[]) => void;
}

export const SliderRangeWrapper: ReactFCWithChildren<SliderRangeWrapperProps> = ({ onUpdate, refresh }) => {
    const value = useAppSelector(selectPriceFilter);
    const { t } = useTranslation();

    const [range, setRange] = useState(value);

    const onPriceRangeChange = (values: number[]) => {
        onUpdate(values);
    };

    useEffect(() => {
        if (refresh) {
            setRange(defaultPriceRange);
            onUpdate(defaultPriceRange);
        }
    }, [refresh, onUpdate]);

    const currency = getAppCurrency();

    const label = `${t('dailyPrice')} (${getCurrencySymbol(currency)})`;

    return <SliderRange onChangeCommitted={onPriceRangeChange} value={range} onChange={setRange} label={label} />;
};

interface AllFiltersProps {
    stateDraft: ListingsState;
    filterOptions: FilterOptions;
    loading: boolean;
    totalItems: number;
    onApply: () => void;
    onUpdateStateDraft: (value: ListingsStateAction) => void;
}

export const AllFilters = forwardRef(({ stateDraft, totalItems, filterOptions, loading, onApply, onUpdateStateDraft }: AllFiltersProps, ref) => {
    const { t } = useTranslation();
    const [count, resetSliderRange] = useState(0);

    const onResetFilters = useCallback((): void => {
        onUpdateStateDraft({ type: 'reset' });
        resetSliderRange((prev) => prev + 1);
    }, [onUpdateStateDraft]);

    useImperativeHandle(ref, () => ({
        resetPriceRange: onResetFilters,
    }));

    const onUpdatePrice = useCallback((values: number[]) => {
        onUpdateStateDraft({ type: Filter.PRICE, payload: values });
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    paddingBottom: 'calc(76px + env(safe-area-inset-bottom))',
                }}
            >
                <Divider />

                <Box sx={{ p: 2 }}>
                    <Sort onSetSort={(payload) => onUpdateStateDraft({ type: 'sort', payload })} value={stateDraft.sortBy} />
                </Box>

                <Box sx={{ p: 2 }}>
                    <SliderRangeWrapper onUpdate={onUpdatePrice} refresh={count} />
                </Box>

                <DrawerMultiSelect
                    label={t('delivery')}
                    options={filterOptions.delivery}
                    hideSearch
                    fillHeight={false}
                    totalItems={totalItems}
                    loading={loading}
                    selectedValues={stateDraft.filters.delivery}
                    onChange={(payload) => onUpdateStateDraft({ type: Filter.DELIVERY, payload })}
                />

                <DrawerMultiSelect
                    label={t('category')}
                    options={filterOptions.category}
                    totalItems={totalItems}
                    loading={loading}
                    selectedValues={stateDraft.filters.category}
                    onChange={(payload) => {
                        onUpdateStateDraft({ type: Filter.CATEGORY, payload });

                        const validSizingOptionsForCategories = getSizingOptionsForSelectedCategories(payload as Category[]).map((el) => el.value);
                        const validSizes = [];

                        for (const size of stateDraft.filters.size) {
                            if (validSizingOptionsForCategories.includes(size)) {
                                validSizes.push(size);
                            }
                        }

                        if (!isEqual(validSizes, stateDraft.filters.size)) {
                            onUpdateStateDraft({ type: 'size', payload: validSizes });
                        }
                    }}
                />

                <DrawerMultiSelect
                    label={t('size')}
                    options={filterOptions.size}
                    totalItems={totalItems}
                    loading={loading}
                    selectedValues={stateDraft.filters.size}
                    onChange={(payload) => onUpdateStateDraft({ type: Filter.SIZE, payload })}
                />

                <DrawerMultiSelect
                    label={t('brand')}
                    options={filterOptions.brand}
                    totalItems={totalItems}
                    loading={loading}
                    selectedValues={stateDraft.filters.brand}
                    onChange={(payload) => onUpdateStateDraft({ type: Filter.BRAND, payload })}
                />

                <DrawerMultiSelect
                    label={t('bodytype')}
                    options={filterOptions.bodytype}
                    hideSearch
                    fillHeight={false}
                    totalItems={totalItems}
                    loading={loading}
                    selectedValues={stateDraft.filters.bodytype}
                    onChange={(payload) => onUpdateStateDraft({ type: Filter.BODYTYPE, payload })}
                />

                <DrawerMultiSelect
                    label={t('color')}
                    options={filterOptions.color}
                    totalItems={totalItems}
                    loading={loading}
                    selectedValues={stateDraft.filters.color}
                    onChange={(payload) => onUpdateStateDraft({ type: Filter.COLOR, payload })}
                />
            </Box>

            <Footer totalItems={totalItems} onApply={onApply} loading={loading} t={t} />
        </>
    );
});
