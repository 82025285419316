import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { IdentityVerification, IdentityVerificationState } from './IdentityVerification';
import { StepContent, StepContentContainer } from '../../components/NewUserOnboarding/UserOnboarding.components';
import { useSignicatIdentificationFlow } from '../../hooks/auth/signicat/useIdentification';
import { LoadingButton } from '@mui/lab';

interface SignicatIdentityVerificationResultModalProps {
    open: boolean;
    onClose: () => void;
    redirectUrl: string;
    state?: IdentityVerificationState;
}

export const SignicatIdentityVerificationResultModal: React.FC<SignicatIdentityVerificationResultModalProps> = ({
    open,
    onClose,
    redirectUrl,
    state = 'initial',
}) => {
    const { t } = useTranslation();

    const redirectParams = {
        path: redirectUrl,
    };

    const { mutate: startRedirectFlow, isLoading } = useSignicatIdentificationFlow(redirectParams);

    return (
        <Modal open={open} onClose={onClose}>
            <StepContent sx={{ p: 2 }}>
                <StepContentContainer>
                    <IdentityVerification identificationProvider="signicat" state={state} />
                </StepContentContainer>

                {(state === 'abort' || state === 'error') && (
                    <>
                        <LoadingButton variant="contained" onClick={() => startRedirectFlow()}>
                            {t('tryAgain')}
                        </LoadingButton>
                        <LoadingButton variant="text" onClick={onClose}>
                            {t('cancel')}
                        </LoadingButton>
                    </>
                )}

                {state === 'initial' && (
                    <>
                        <LoadingButton loading={isLoading} variant="contained" onClick={() => startRedirectFlow()}>
                            {t('verifyIdentityShort')}
                        </LoadingButton>
                        <LoadingButton variant="text" onClick={onClose}>
                            {t('cancel')}
                        </LoadingButton>
                    </>
                )}

                {state === 'success' && (
                    <>
                        <LoadingButton variant="contained" onClick={onClose}>
                            {t('continue')}
                        </LoadingButton>
                    </>
                )}
            </StepContent>
        </Modal>
    );
};
