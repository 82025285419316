import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useFormContext, Controller, RegisterOptions, FieldErrors, FieldValues } from 'react-hook-form';
import { SelectItem } from '../FormRenderer.types';
import { getAppConfig } from '../../../countryConfigs';

interface FormSelectProps {
    controlName: string;
    label: string;
    items: SelectItem[];
    registerOptions?: RegisterOptions;
    onSelect?: () => void;
}

export const FormSelectMenu: React.FC<FormSelectProps> = ({ label, controlName, items, registerOptions }) => {
    const { control } = useFormContext();
    const currency = getAppConfig().stripeConfig.currency;

    return (
        <Controller
            name={controlName}
            control={control}
            rules={{ ...registerOptions }}
            render={({ field, fieldState }) => (
                <FormControl fullWidth>
                    <InputLabel id={`${controlName}-label`}>{label}</InputLabel>
                    <Select labelId={`${controlName}-label`} {...field} label={label} defaultValue={currency}>
                        {items.map((item, index) => (
                            <MenuItem key={index} value={item.value} selected={field.value === item.value}>
                                {item.content}
                            </MenuItem>
                        ))}
                    </Select>

                    <FormHelperText error={fieldState.invalid}>{fieldState.error?.message}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
