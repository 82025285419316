import { App } from '@capacitor/app';
import { assertIsDefined } from './commonHelpers';
import { Capacitor } from '@capacitor/core';

let appVersion: string | undefined = undefined;

export const initializeAppVersion = async () => {
    if (Capacitor.isNativePlatform()) {
        const { version } = await App.getInfo();
        appVersion = version;
    } else {
        appVersion = process.env.REACT_APP_VERSION;
    }
};

export const getAppVersion = () => {
    assertIsDefined(appVersion, 'App version is not defined');
    return appVersion;
};
