import { useMutation } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { User } from '../../types/apiTypes';

type IssueCreditsArgs = { amount: number; user: User; currency: string; reason: string };

export const useIssueCredits = (onSuccess: () => void) => {
    const issueCreditsFn = async ({ amount, user, currency, reason }: IssueCreditsArgs) => {
        // User input is in euros, but the API expects the amount in cents
        const amountInSubdivisions = amount * 100;
        const receiverId = user.id;
        const dto = { amount: amountInSubdivisions, receiverId, currency, reason };
        const { data } = await getApiClient('credits').post('/issue', dto);

        return data;
    };

    return useMutation(issueCreditsFn, {
        onSuccess: (_res, variables) => {
            toast.success(`${variables.amount} ${variables.currency} Credits issued successfully to ${variables.user.profile.displayName}`);
            onSuccess();
        },
        onError: () => {
            toast.error('Error while attempting to issue credits');
        },
    });
};
