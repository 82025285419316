import { Box, Typography, useTheme } from '@mui/material';
import { ExtendedListing } from '../../types/apiTypes';
import { Trans, useTranslation } from 'react-i18next';
import { Check, InfoOutlined, LocalShipping } from '@mui/icons-material';
import { IconSection } from '../../components/IconSection.tsx/IconSection';
import { DeliveryMethod, useOfficeConfigurationMethods } from '../../queries/useDeliveryTiming';
import { formatPrice } from '../../helpers/commonHelpers';
import { getPriceForDeliveryMethod } from '../../helpers/delivery';

interface DeliveryOptionsSectionProps {
    listing: ExtendedListing;
    deliveryOptions: DeliveryMethod[];
    onClick: () => void;
}

export const DeliveryOptionsSection: React.FC<DeliveryOptionsSectionProps> = ({ listing, deliveryOptions, onClick }) => {
    const { t } = useTranslation();

    const listItems = listing.publicData.deliveryOptions
        ?.map((option) => {
            const price = formatPrice(getPriceForDeliveryMethod(deliveryOptions, option));

            return (
                <span style={{ display: 'flex', gap: '5px', alignItems: 'center' }} key={option}>
                    <Typography variant="subtitle2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                        {t(`${option}Delivery`)}

                        {option === 'wolt' && <i style={{ marginLeft: '5px' }}>{t('woltDeliveryArea')}</i>}
                    </Typography>
                    <Typography variant="subtitle2">{price}</Typography>
                </span>
            );
        })
        .concat(
            <span style={{ display: 'flex', gap: '5px', alignItems: 'center' }} key={'matkahuolto'}>
                <Typography variant="subtitle2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                    <Trans i18nKey="matkahuoltoDeliveryComingSoon" components={{ i: <i /> }} />
                </Typography>
            </span>,
        );

    return (
        <IconSection
            icon={<LocalShipping color="primary" sx={{ mt: '1px' }} />}
            title={
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }} onClick={onClick}>
                    <Typography variant="body1" fontWeight="bold">
                        {t('deliveryOptionsSectionTitle')}
                    </Typography>
                    <InfoOutlined sx={{ opacity: 0.7, fontSize: '1rem' }} />
                </Box>
            }
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>{listItems}</Box>
        </IconSection>
    );
};
