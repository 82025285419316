import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { StripeAccountLink } from '../../../types/apiTypes';
import { Capacitor } from '@capacitor/core';
import { openNativeBrowserWithCloseCallback } from '../../../helpers/browser';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';

type StripeConnectType = 'custom_account_verification' | 'custom_account_update';

export const useCreateStripeAccountLink = (locationBaseUrl: string, onError: () => void) => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();

    const createAccountLinkFn = async (type: StripeConnectType = 'custom_account_verification') => {
        if (!locationBaseUrl) {
            throw new Error('URL missing');
        }

        const url = '/account-link';

        const payload = {
            failureURL: `${locationBaseUrl}/error`,
            successURL: `${locationBaseUrl}/success`,
            type,
            isNative: String(Capacitor.isNativePlatform()),
        };

        const { data } = await getApiClient('stripe').post<StripeAccountLink>(url, payload, { timeout: 30 * 1000 });

        const beforeClose = async () => await queryClient.refetchQueries({ queryKey: ['current-user'] });
        openNativeBrowserWithCloseCallback(data.url, navigate, beforeClose);

        return data.url;
    };

    return useMutation(createAccountLinkFn, {
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg);
            onError();
        },
    });
};
