import React, { useCallback, useEffect } from 'react';
import { useSearchConfiguration } from './useSearchConfiguration';
import { SearchResultsSkeleton } from './SearchResultsSkeleton';
import { useBrands } from '../../../components/FilterBar/useBrands';
import { renderStrapiComponent } from '../../../strapi/helpers';
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { ChipSection } from '../../../components/ChipSection/ChipSection';
import Fuse, { IFuseOptions } from 'fuse.js';
import { AnimatePresence } from 'framer-motion';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';
import { fadeIn, fadeInOut } from '../../../animations/constants';
import { useTranslation } from 'react-i18next';
import { categories } from '../../AddListing/constants';
import { capitalizeEachWord, formatPriceStyled, invariant } from '../../../helpers/commonHelpers';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { useSearchContext } from './SearchBar';
import { analytics } from '../../../analytics/events/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { Listing } from '../../../types/apiTypes';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { dispatchCustomEvent } from '../../../store/listeners';

type Option = { value: string; label: string };

interface ListingSearchResultsProps {
    searchTerm: string;
    loading: boolean;
    results: Listing[];
}

const options: IFuseOptions<Option> = {
    includeScore: true,
    threshold: 0.3,
    ignoreLocation: true,
    keys: ['label'],
    isCaseSensitive: false,
};

const fuseBrand = new Fuse<Option>([], options);
const fuseCategory = new Fuse<Option>([], options);

interface ListingItemProps {
    listing: Listing;
    onClick: (listing: Listing) => void;
}

const ListingItem: React.FC<ListingItemProps> = ({ listing, onClick }) => {
    const { t } = useTranslation();

    const subtitle = [listing.publicData.category, listing.publicData.size].map((attribute) => t(attribute)).join(', ');
    const priceText = `${formatPriceStyled(listing.price.amount)} / ${t('dayAbbreviated')}`;

    return (
        <ListItem disableGutters>
            <ListItemButton sx={{ px: 2 }} onClick={() => onClick(listing)}>
                <ListItemAvatar sx={{ mr: 2 }}>
                    <Avatar src={listing.images[0].url} sx={{ width: 70, height: 70 }} variant="rounded" />
                </ListItemAvatar>

                <ListItemText
                    sx={{ height: '70px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
                    primary={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>{capitalizeEachWord(listing.publicData.brand)}</Typography>
                            <Typography>{priceText}</Typography>
                        </div>
                    }
                    secondary={subtitle}
                />
            </ListItemButton>
        </ListItem>
    );
};

export const ListingSearchResults: React.FC<ListingSearchResultsProps> = ({ searchTerm, loading, results }) => {
    const { data: configuration, isLoading: configurationLoading } = useSearchConfiguration('listing');
    const { data: brands } = useBrands();
    const { t } = useTranslation();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();
    const isDesktop = useBreakpoint('sm');

    const { setIsOpen } = useSearchContext();

    useEffect(() => {
        if (!brands) {
            return;
        }

        fuseBrand.setCollection(brands);
    }, [brands]);

    useEffect(() => {
        const categoryOptions = categories.map((category) => ({ value: category, label: t(category) }));
        fuseCategory.setCollection(categoryOptions);
    }, [t]);

    const handleClickListing = useCallback(
        (listing: Listing) => {
            const queryKey = ['listing', { listingId: listing.id }];
            analytics.listingClick(listing.id, listing.title, 'listings-page');

            const cachedData = queryClient.getQueryData(queryKey);
            if (!cachedData) {
                queryClient.setQueryData(queryKey, () => listing);
                queryClient.invalidateQueries(queryKey);
            }

            navigate(`/listing/${listing.id}`);
            setIsOpen(false);
        },
        [navigate, queryClient],
    );

    if (loading || configurationLoading) {
        return <SearchResultsSkeleton />;
    }

    const handleChipClick = (val: string, type: string) => {
        invariant(type === 'brand' || type === 'category', 'Unknown chip type');
        const url = `/listings?${type}=${encodeURIComponent(val)}`;
        dispatchCustomEvent('replaceFilterAndNavigate', { type, payload: [val] });
        navigate(url, { replace: true });
        setIsOpen(false);
    };

    if (!searchTerm) {
        return (
            <AnimatedContainer variants={fadeIn} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', gap: '30px' }}>
                {configuration?.components.map((component) => (
                    <React.Fragment key={`${component.type}-${component.id}`}>
                        {renderStrapiComponent(component, { translate: t, onChipClick: handleChipClick })}
                    </React.Fragment>
                ))}
            </AnimatedContainer>
        );
    }

    const filteredBrands = fuseBrand
        .search(searchTerm)
        .map((result) => result.item)
        .slice(0, 6);

    const filteredCategories = fuseCategory
        .search(searchTerm)
        .map((result) => result.item)
        .slice(0, 6);

    const hasFilteredBrands = filteredBrands.length > 0;
    const hasFilteredCategories = filteredCategories.length > 0;
    const hasSearchResults = results.length > 0;
    const hasResults = hasFilteredBrands || hasFilteredCategories || hasSearchResults;
    const paddingBottom = isDesktop ? 'env(safe-area-inset-bottom)' : 'calc(70px + env(safe-area-inset-bottom))';

    return (
        <AnimatePresence initial={false}>
            <AnimatedContainer style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', gap: '10px' }} variants={fadeInOut}>
                {hasFilteredBrands && <ChipSection chips={filteredBrands} label={t('brands')} onClick={(val) => handleChipClick(val, 'brand')} />}
                {hasFilteredCategories && (
                    <ChipSection chips={filteredCategories} label={t('categories')} onClick={(val) => handleChipClick(val, 'category')} />
                )}
                {hasSearchResults && (
                    <Box sx={{ mt: 1 }}>
                        <Typography sx={{ px: 2 }} variant="subtitle1" fontWeight="bold">
                            {t('listings')}
                        </Typography>

                        <List sx={{ paddingBottom }}>
                            {results.map((listing, i) => (
                                <React.Fragment key={listing.id}>
                                    <ListingItem listing={listing} onClick={handleClickListing} />

                                    {results.length > 1 && i !== results.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    </Box>
                )}

                {!hasResults && (
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">{t('noSearchResultsFor', { searchTerm })}</Typography>
                    </Box>
                )}
            </AnimatedContainer>
        </AnimatePresence>
    );
};
