import React, { useCallback, useEffect } from 'react';
import { Box, Button, Card, CardActions, CardContent, Divider, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { assertIsDefined, assertNever, formatDateRange, noop } from '../../../helpers/commonHelpers';
import { OwnTransaction } from '../../../transactions/apiTypes';
import { ReactFCWithChildren } from '../../../types/types';
import { CardSummary, CardSummaryDetails, CardTitle, NextStep } from './TransactionCard.components';
import { UsernameLabel } from './UsernameLabel';
import { FormInputWithLabel } from '../../../components/FormInputWithLabel/FormInputWithLabel';
import { LastTransitionBadge } from './LastTransitionBadge';
import { BasicMenu } from '../../../components/BasicMenu/BasicMenu';
import { Image, rem } from '@mantine/core';
import { Chat } from '@mui/icons-material';
import { useChatWindow } from '../../../context/chat';

import { NextTransactionAction } from './NextTransactionAction';
import { useConfirm } from '../../../context/confirm';
import { useTransitionTransaction } from '../../../transactions/hooks/useTransitionTransaction';
import { TransitionName } from '../../../transactions/constants';
import { addDays, isBefore } from 'date-fns';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { getDurationInDays } from '../../../helpers/dateAndTimeHelpers';
import { UnstyledLink } from '../../..';
import { DeliveryInformationSection } from './DeliveryDetails';
import { DeliveryMethod, OfficeConfigurationMethods } from '../../../queries/useDeliveryTiming';
import { ChatWithSupportButton } from '../../../components/ChatWithUserButton/ChatWithSupportButton';
import { getDisplayLastTransition } from './helpers';

const allowedTransitionsForChat = [TransitionName.ACCEPT, TransitionName.CONFIRM_PAYMENT];
const allowedTransitionsForCancellation = [TransitionName.ACCEPT, TransitionName.CONFIRM_PAYMENT];
const hideDeliveryInformationForTransitions = [TransitionName.DECLINE, TransitionName.OPERATOR_DECLINE];

interface DisplayableTransactionCardProps {
    transactionData: OwnTransaction;
    deliveryMethods: DeliveryMethod[];
    type: 'sale' | 'order';
}

export const DisplayableTransaction: ReactFCWithChildren<DisplayableTransactionCardProps> = ({ deliveryMethods, transactionData, type }) => {
    const { provider, customer, listing, transaction, booking } = transactionData;
    const { t } = useTranslation();
    const { openChat, closeChat } = useChatWindow();
    const theme = useTheme();
    const navigate = useSafeNavigate();

    const receiverText = t(`transaction-card-counterparty-${type}`);
    const chatText = t(`transaction-card-chat-text-${type}`);
    const participant = type === 'sale' ? customer : provider;

    const handleClickUser = useCallback((userId: string) => {
        closeChat();
        navigate(`/user/${userId}`);
    }, []);

    const handleOpenChat = useCallback(() => {
        const chatProps = {
            participant,
            onClickUser: handleClickUser,
        };

        openChat(chatProps);
    }, []);

    const { confirm, close, updateOptions } = useConfirm();
    const { mutate: transitionTransaction, isLoading } = useTransitionTransaction(close);
    const lastTransition = transaction.attributes.lastTransition;

    const isInsideAllowedTimePeriodForCancellation = isBefore(new Date(), addDays(new Date(booking.attributes.displayStart), -3));
    const allowCancel = allowedTransitionsForCancellation.includes(lastTransition) && isInsideAllowedTimePeriodForCancellation;

    let menuItems = [];

    const isTransactionAccepted = lastTransition === TransitionName.ACCEPT;

    if (allowCancel) {
        const cancelLabel = isTransactionAccepted ? t('cancelRental') : t('cancelRentalRequest');
        menuItems.push(...[t('viewRentalDetails'), cancelLabel]);
    } else {
        menuItems.push(t('viewRentalDetails'));
    }

    const handleCancelRequest = () => {
        const cancelType = isTransactionAccepted ? 'rental' : 'request';
        const message = t(`transaction-dialog-message-cancel-${cancelType}`);
        const title = t(`transaction-dialog-title-cancel-${cancelType}`);

        const getCancelTransition = () => {
            if (!isTransactionAccepted) {
                return TransitionName.CUSTOMER_CANCEL;
            }

            if (type === 'sale') {
                return TransitionName.PROVIDER_CANCEL_AFTER_ACCEPT;
            }
            if (type === 'order') {
                return TransitionName.CUSTOMER_CANCEL_AFTER_ACCEPT;
            }

            assertNever(type);
        };

        const onConfirm = () => {
            const payload = {
                transactionId: transaction.id,
                transition: getCancelTransition(),
                params: {},
            };
            transitionTransaction(payload);
        };

        confirm({ title, message, loading: isLoading, onConfirm }).then(noop).catch(noop);
    };

    const handleMenuItemClick = (i: number) => {
        if (i === 0) {
            navigate(`/transaction/${transaction.id}`);
        }
        if (i === 1) {
            handleCancelRequest();
        }
    };

    useEffect(() => {
        updateOptions({ loading: isLoading });
    }, [isLoading]);

    const periodText = `${formatDateRange(new Date(booking.attributes.displayStart), new Date(booking.attributes.displayEnd))}`;
    const durationText = ` (${getDurationInDays(booking.attributes.displayStart, booking.attributes.displayEnd)} ${t('days')})`;

    const nextActionTranslationParams = {
        type,
        bookingStart: new Date(booking.attributes.displayStart),
        bookingEnd: new Date(booking.attributes.displayEnd),
        protectedData: transaction.attributes.protectedData,
        lastTransitionedAt: transaction.attributes.lastTransitionedAt,
        isPresentInShowroom: listing.presentInShowroom,
        deliveryMethods,
    };

    const deliveryMethod = transaction.attributes.protectedData.deliveryMethod;

    // May differ from the actual last transition, see comment in getDisplayLastTransition
    const displayLastTransition = getDisplayLastTransition(transaction, type, deliveryMethods);

    const showChatWithSupportButton = deliveryMethod === 'wolt' || deliveryMethod === 'showroom' || deliveryMethod === 'uber';

    return (
        <Card raised>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
                <CardTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <UnstyledLink href={`/listing/${listing.id}`}>
                            <Typography
                                variant="overline"
                                sx={{ maxWidth: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.85rem' }}
                            >
                                {listing.title || t('deleted')}
                            </Typography>
                        </UnstyledLink>
                        <BasicMenu menuItems={menuItems} menuButtonSxProps={{ p: 0 }} onClickItem={handleMenuItemClick} />
                    </Box>
                    <LastTransitionBadge transition={displayLastTransition} chipProps={{ size: 'small' }} />
                </CardTitle>

                <CardSummary>
                    <UnstyledLink href={`/listing/${listing.id}`}>
                        <Image
                            src={listing.imageUrl}
                            alt={listing.title}
                            withPlaceholder
                            height={110}
                            width={110}
                            styles={{ placeholder: { backgroundColor: theme.palette.background.paper } }}
                            sx={{
                                objectFit: 'cover',
                                borderRadius: '5px',
                                boxShadow: theme.palette.boxShadow.medium,
                            }}
                        />
                    </UnstyledLink>

                    <CardSummaryDetails>
                        <FormInputWithLabel
                            label={t('period')}
                            value={
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <span style={{ fontWeight: 'bold' }}>{periodText}</span>
                                    <span>&nbsp;{durationText}</span>
                                </div>
                            }
                        />

                        <FormInputWithLabel label={receiverText} value={<UsernameLabel user={type === 'sale' ? customer : provider} />} />
                    </CardSummaryDetails>
                </CardSummary>

                {!hideDeliveryInformationForTransitions.includes(lastTransition) && (
                    <Box sx={{ mt: 2 }}>
                        <DeliveryInformationSection
                            deliveryMethods={deliveryMethods}
                            transactionProtectedData={transaction.attributes.protectedData}
                            transactionType={type}
                        />
                    </Box>
                )}

                <Box sx={{ mt: 2 }}>
                    <NextTransactionAction type={type} lastTransition={displayLastTransition} translationParams={nextActionTranslationParams} />
                </Box>

                {allowedTransitionsForChat.includes(displayLastTransition) && (
                    <CardActions sx={{ mt: 2, py: 1, px: 0 }}>
                        {showChatWithSupportButton ? (
                            <ChatWithSupportButton listingId={listing.id} />
                        ) : (
                            <Button variant="contained" fullWidth startIcon={<Chat />} onClick={handleOpenChat}>
                                {chatText}
                            </Button>
                        )}
                    </CardActions>
                )}
            </CardContent>
        </Card>
    );
};
