import { GoogleAuthPlugin } from '@codetrix-studio/capacitor-google-auth';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from 'react-toastify';
import { fetchProfile, getOnboardingFlowUrl } from '../../helpers/authHelpers';
import { assertNever } from '../../helpers/commonHelpers';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { useAppDispatch } from '../../store/hooks';
import { storeToken } from '../../store/userReducer';
import { LoginSuccess } from '../../types/apiTypes';
import { logger } from '../../helpers/logger';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { useSafeNavigate } from '../useSafeNavigate';
import useSafeNavigateWithParams from '../useSafeNavigateWithParams';

const APPLE_CLIENT_ID = 'com.robesrental.robes.sign-in';
const GOOGLE_WEB_CLIENT_ID = process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID || '';
const CLIENT_ORIGIN = process.env.REACT_APP_CLIENT_ORIGIN || '';

export type IdpProvider = 'google' | 'apple';

interface AuthPayload {
    idpToken: string;
    email: string | null;
    firstname: string | null;
    lastname: string | null;
}

interface IdpLoginPayload {
    idpId: IdpProvider;
    idpToken: string;
    idpClientId: string;
}

export const useIdpLogin = (GoogleAuth: GoogleAuthPlugin) => {
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    const navigateWithParams = useSafeNavigateWithParams();
    const { t } = useTranslation();

    const handleIdpLogin = async (authPayload: AuthPayload, idpLoginPayload: IdpLoginPayload) => {
        const url = '/login/idp';

        try {
            const { data } = await getApiClient('auth').post<LoginSuccess>(url, idpLoginPayload);
            return { success: true, data } as const;
        } catch (err: unknown) {
            const data = { ...authPayload, ...idpLoginPayload };
            return { success: false, data } as const;
        }
    };

    const googleAuthFn = async () => {
        await GoogleAuth.signOut();
        const response = await GoogleAuth.signIn();
        const authPayload = {
            idpToken: response.authentication.idToken,
            email: response.email,
            firstname: response.givenName,
            lastname: response.familyName,
        };

        const idpLoginPayload = {
            idpId: 'google',
            idpToken: authPayload.idpToken,
            idpClientId: GOOGLE_WEB_CLIENT_ID,
        } as const;

        return handleIdpLogin(authPayload, idpLoginPayload);
    };

    const appleAuthFn = async () => {
        const { response } = await SignInWithApple.authorize({
            clientId: APPLE_CLIENT_ID,
            redirectURI: `${CLIENT_ORIGIN}/login`,
            scopes: 'email name',
        });

        const storeUserCredentials = async () => {
            const payload = {
                idpToken: response.identityToken,
                email: response.email,
                firstName: response.givenName,
                lastName: response.familyName,
                authorizationCode: response.authorizationCode,
            };
            await getApiClient('auth').post('/apple-credentials', payload);
        };

        const fetchUserCredentials = async () => {
            const { data } = await getApiClient('auth').get(`/apple-credentials?token=${response.identityToken}`);
            return data;
        };

        const fetchIdpToken = async () => {
            const { data } = await getApiClient('auth').post('/idp-token', {
                email: authPayload.email,
                firstName: authPayload.firstname,
                lastName: authPayload.lastname,
            });

            return data;
        };

        let authPayload: any = {
            idpToken: response.identityToken,
            email: response.email,
        };

        // Apple returns user name only the first time the authorization is made - store in DB
        if (response.givenName) {
            await storeUserCredentials();
            authPayload = { ...authPayload, firstName: response.givenName, lastName: response.familyName };
        } else {
            const storedCredentials = await fetchUserCredentials();
            authPayload = { ...authPayload, ...storedCredentials };
        }

        const idpToken = await fetchIdpToken();

        const idpLoginPayload = {
            idpId: 'applesignin',
            idpToken,
            idpClientId: APPLE_CLIENT_ID,
        } as const;

        // @ts-ignore
        return handleIdpLogin(authPayload, idpLoginPayload);
    };

    const loginFn = async (provider: IdpProvider) => {
        if (provider === 'google') {
            const data = await googleAuthFn();
            return data;
        }

        if (provider === 'apple') {
            const data = await appleAuthFn();
            return data;
        }

        return assertNever(provider);
    };

    const queryClient = useQueryClient();

    return useMutation(loginFn, {
        onSuccess: async (response) => {
            if (response && response.success) {
                const { data } = response;
                const token = JSON.stringify(data);

                dispatch(storeToken(token));

                const { response: authResponse } = await fetchProfile(token);

                if (authResponse) {
                    queryClient.setQueryData(['current-user'], () => authResponse.data);

                    navigate(getOnboardingFlowUrl(authResponse.data), { replace: true });
                }
            } else if (response && !response.success) {
                navigateWithParams('/signup', { ...response.data });
            }
        },
        onError: (err, loginProvider) => {
            logger.error(err);
            const msg = t('idpLoginError', { provider: loginProvider });
            toast.error(msg);
        },
    });
};
