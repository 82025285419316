import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { Money } from '../../../transactions/types';
import { assertNever } from '../../../helpers/commonHelpers';
import { Listing, ResponseMeta } from '../../../types/apiTypes';

export interface User {
    userId: string;
    profileImage: string;
    displayName: string;
    bio: string;
}

interface SearchResultTypes {
    listing: Listing[];
    user: User[];
}

type SearchResponse<T> = { data: T; meta: ResponseMeta };

export type SearchMode = keyof SearchResultTypes;

export const useSearch = <T extends SearchMode>(search: string, mode: T, enabled?: boolean): UseQueryResult<SearchResultTypes[T]> => {
    const searchFn = async () => {
        if (!search) {
            return [];
        }

        const url = `/${mode}?keywords=${search}`;

        if (mode === 'listing') {
            const { data } = await getApiClient('search').get<SearchResponse<Listing[]>>(url);
            return data.data;
        } else if (mode === 'user') {
            const { data } = await getApiClient('search').get<SearchResponse<User[]>>(url);
            return data.data;
        }

        assertNever(mode);
    };

    return useQuery(['search', { search, mode }], searchFn, { enabled });
};
