import React from 'react';
import { formatPrice } from '../../../helpers/commonHelpers';
import { useCreditsBalance } from '../hooks/useCreditsBalance';
import { Skeleton, Typography } from '@mui/material';
import { Center } from '@mantine/core';

export const CreditsBalance: React.FC = () => {
    const { data: balance, isLoading } = useCreditsBalance();

    const getFormattedBalance = () => {
        if (!balance) {
            return formatPrice(0);
        }

        const totalAvailable = balance.balance - balance.reserved;
        return formatPrice(totalAvailable);
    };

    return (
        <Center>
            {isLoading ? (
                <Skeleton variant="text" height={15} width={50} />
            ) : (
                <Typography variant="body2" fontWeight="bold">
                    {getFormattedBalance()}
                </Typography>
            )}
        </Center>
    );
};
