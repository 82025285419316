import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { StripeAccount } from '../../../transactions/apiTypes';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { StripeConnectFormData } from '../Stripe/StripeConnectForm.types';

export type UpdateStripeAccountFnArgs = { country: string };

export const useUpdateStripeAccount = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const { data: user } = useCurrentUser();
    const queryClient = useQueryClient();
    const stripe = useStripe();
    const stripeConnected = user?.stripeConnected;

    const createStripeAccountFn = async (formData: StripeConnectFormData) => {
        if (!stripe || !stripeConnected) {
            throw new Error('Stripe not loaded or stripe is not connected');
        }

        // To stay PCI Compliant, account tokens are created client-side
        const { token, error } = await stripe.createToken('account', {
            business_type: 'individual',
            individual: { first_name: user.profile.firstName, last_name: user.profile.lastName },
            tos_shown_and_accepted: true,
        });

        if (error) {
            throw new Error('Invalid account token');
        }

        const accountToken = token.id;
        const url = '/account';
        const { data } = await getApiClient('stripe').put<StripeAccount>(url, { ...formData, accountToken });

        return data;
    };

    return useMutation(createStripeAccountFn, {
        onSuccess: (stripeAccount) => {
            queryClient.setQueryData(['stripe-account'], () => stripeAccount);

            const msg = t('payoutDetailsUpdatedMsg');
            toast.success(msg);

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            const msg = t('unexpectedError');
            toast.error(msg);
        },
    });
};
