import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getApiClient } from '../apiClients';

const upload = async (file: File, onProgress?: (progress: number) => void): Promise<{ response?: AxiosResponse; error?: any }> => {
    const url = '/upload';
    const data = new FormData();
    data.append('file', file);

    const config: AxiosRequestConfig = {
        timeout: 2 * 60 * 1000,
        ...(onProgress && {
            onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                return onProgress(percentCompleted);
            },
        }),
    };

    try {
        const response = await getApiClient('image').post(url, data, config);
        return { response };
    } catch (error: any) {
        return { error };
    }
};

export const imageService: {
    upload: (file: File, onProgress?: (progress: number) => void) => Promise<{ response?: AxiosResponse; error?: any }>;
} = {
    upload,
};
