import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { Session, StripeVerificationSession } from './types';
import { makeQueryString } from '../../../helpers/urlHelpers';
import { Capacitor } from '@capacitor/core';
import { openNativeBrowserWithCloseCallback } from '../../../helpers/browser';
import { useSafeNavigate } from '../../useSafeNavigate';
import { IdentityVerificationSheetEventsEnum, StripeIdentity } from '@capacitor-community/stripe-identity';

const stripePublishableKey = process.env.REACT_APP_STRIPE_KEY || '';

const initStripeIdentityMaybe = async () => {
    if (!Capacitor.isNativePlatform()) {
        await StripeIdentity.initialize({ publishableKey: stripePublishableKey });
    }
};

initStripeIdentityMaybe();

/**
 * @param params returned in the callbackUrl as query params after signicat authentication flow.
 */

export const useSignicatIdentificationFlow = (params?: Record<string, string>) => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();

    const connectFn = async () => {
        const isNative = String(Capacitor.isNativePlatform());
        const resolvedParams = params ? { ...params, isNative } : { isNative };
        const url = `/start?${makeQueryString(resolvedParams)}`;
        const { data } = await getApiClient('identification').post<Session>(url);

        return data;
    };

    return useMutation(connectFn, {
        onSuccess: async (session: Session) => {
            const beforeClose = async () => await queryClient.refetchQueries({ queryKey: ['current-user'] });
            openNativeBrowserWithCloseCallback(session.authenticationUrl, navigate, beforeClose);
        },
        onError: () => {
            toast.error<string>(t('unexpectedError'));
        },
    });
};
type UseStripeIdentificationFlowArgs = {
    onSuccess: () => void;
    onAbort: () => void;
    onError: () => void;
    onSettled?: () => void;
};

export const useStripeIdentificationFlow = ({ onSuccess, onError, onAbort, onSettled }: UseStripeIdentificationFlowArgs) => {
    const connectFn = async () => {
        const isWeb = !Capacitor.isNativePlatform();

        const { data } = await getApiClient('stripe').post<StripeVerificationSession>('/create-verification-session');
        const { ephemeralKeySecret, verificationId, clientSecret } = data;

        const args = {
            ephemeralKeySecret,
            verificationId,
            ...(isWeb && { clientSecret }),
        };

        await StripeIdentity.create(args);

        const { identityVerificationResult } = await StripeIdentity.present();

        return identityVerificationResult;
    };

    return useMutation(connectFn, {
        onSuccess: async (identityVerificationResult) => {
            if (identityVerificationResult === IdentityVerificationSheetEventsEnum.Completed) {
                onSuccess();
            } else if ((identityVerificationResult = IdentityVerificationSheetEventsEnum.Canceled)) {
                onAbort();
            } else {
                onError();
            }
        },
        onError,
        onSettled,
    });
};
