import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthType, hasRequiredAuth, useAuth } from '../context/auth';
import { useLogout } from '../hooks/auth/useLogout';
import { ReactFCWithChildren } from '../types/types';
import { useSafeNavigate } from '../hooks/useSafeNavigate';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { resetToken, selectToken } from '../store/userReducer';
import { useQueryClient } from '@tanstack/react-query';
import { clearStoredPaths } from '../components/ScrollRestore/ScrollRestore';

const defaultRequiredAuth = [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED];

interface ProtectedRouteProps {
    requiredAuth?: Omit<AuthType, 'error'>[];
}

export const ProtectedRoute: ReactFCWithChildren<ProtectedRouteProps> = ({ children, requiredAuth = defaultRequiredAuth }) => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const token = useAppSelector(selectToken);

    const { grantedAuth, isLoading } = useAuth();
    const { mutate: logout, isLoading: isLoggingOut, isError: isLogoutError } = useLogout();

    useEffect(() => {
        if (isLoading || isLoggingOut) {
            return;
        }

        const shouldLogout = grantedAuth.includes(AuthType.INVALID);
        const shouldLogin = isLogoutError || (requiredAuth.includes(AuthType.LOGGED_IN) && !hasRequiredAuth(AuthType.LOGGED_IN, grantedAuth));
        const shouldVerifyEmail = requiredAuth.includes(AuthType.EMAIL_VERIFIED) && !hasRequiredAuth(AuthType.EMAIL_VERIFIED, grantedAuth);

        if (shouldLogout) {
            logout({ successToast: { type: 'error', msg: t('loginExpired') } });
        } else if (shouldLogin) {
            dispatch(resetToken());
            queryClient.clear();
            clearStoredPaths();

            navigate('/login', { replace: true });
        } else if (shouldVerifyEmail) {
            navigate('/verification-pending', { replace: true });
        }
    }, [grantedAuth, isLoading, isLoggingOut, isLogoutError, logout, navigate, requiredAuth, t, token]);

    return <>{children}</>;
};
