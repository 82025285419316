import { getApiClient } from './services/sharetribe/apiClients';
import { invariant } from './helpers/commonHelpers';
import { Language } from './types/enums';
import { Preferences } from '@capacitor/preferences';
import i18next from 'i18next';
import { getQueryClientInstance } from './queryClient';

const resolveApiUrl = (countryCode: 'fi' | 'us') => {
    if (process.env.NODE_ENV === 'development') {
        return `http://localhost:3001`;
    }

    const environment = process.env.REACT_APP_ENVIRONMENT;
    invariant(environment, 'Environment is not set');

    return `https://api.${countryCode}.${environment}.robesrental.com`;
};

export const resolveStrapiUrl = (countryCode: 'FI' | 'US') => {
    const resolvedCountryCode = countryCode.toLowerCase();
    const environment = process.env.REACT_APP_ENVIRONMENT;
    invariant(environment, 'Environment is not set');

    return `https://strapi.${resolvedCountryCode}.${environment}.robesrental.com`;
};

export const countryConfig = {
    FI: {
        serverUrl: resolveApiUrl('fi'),
        countryCode: 'FI',
        region: 'europe',
        countryCallingCode: '+358',
        identificationProvider: 'signicat',
        languages: [
            {
                value: Language.FI,
                label: 'Suomi',
                default: true,
            },
            {
                value: Language.EN,
                label: 'English',
            },
        ],
        stripeConfig: {
            currency: 'EUR',
            countryCode: 'FI',
            accountConfig: {
                iban: true,
            },
        },
        deliveryOptions: ['faceToFace', 'wolt', 'showroom'],
    },
    US: {
        serverUrl: resolveApiUrl('us'),
        countryCode: 'US',
        region: 'america',
        countryCallingCode: '+1',
        identificationProvider: 'stripe',
        languages: [
            {
                value: Language.EN,
                label: 'English',
                default: true,
            },
        ],
        stripeConfig: {
            currency: 'USD',
            countryCode: 'US',
            accountConfig: {
                routingNumber: true,
                accountNumber: true,
            },
        },
        deliveryOptions: ['faceToFace', 'uber'],
    },
} as const;

export type CountryCode = typeof countryConfig['FI' | 'US']['countryCode'];
type AppConfig = typeof countryConfig[CountryCode];

export let appConfig: AppConfig | null = null;

export const setAppConfig = async (countryCode: string) => {
    invariant(countryCode === 'FI' || countryCode === 'US', `Invalid country code ${countryCode}`);

    appConfig = { ...countryConfig[countryCode] };
    await Preferences.set({ key: 'country', value: countryCode });
};

export const getAppConfig = () => {
    return countryConfig['FI'];
    return appConfig || countryConfig['FI'];
};

export const getAppCurrency = () => {
    return getAppConfig().stripeConfig.currency;
};

export const getAppCountryCode = () => {
    return getAppConfig().countryCode;
};

export const getAppIdentificationProvider = () => {
    return getAppConfig().identificationProvider;
};

export const getAppDeliveryOptions = () => {
    return getAppConfig().deliveryOptions;
};

export const resolveServerBaseUrl = () => {
    return getAppConfig().serverUrl;
};

export const tryResolveUserCountry = async () => {
    try {
        const { data } = await getApiClient('location').get<{ country: string; promptSelectCountry: boolean; promptReason: string | null }>(
            '/country',
        );

        if (!data.promptReason) {
            return data.country;
        }

        return null;
    } catch (err) {
        console.error('Failed to fetch user country', err);
        return null;
    }
};

export const handleChangeCountryOfResidence = async (changedCountryOfResidence: string) => {
    invariant(changedCountryOfResidence === 'FI' || changedCountryOfResidence === 'US', `Invalid country selected: ${changedCountryOfResidence}`);

    const newAppConfig = countryConfig[changedCountryOfResidence];

    const defaultLanguage = newAppConfig.languages.find((lang) => 'default' in lang);
    i18next.changeLanguage(defaultLanguage?.value);

    setAppConfig(changedCountryOfResidence);
    getQueryClientInstance().clear();
    await Preferences.set({ key: 'country', value: changedCountryOfResidence });

    return changedCountryOfResidence;
};
