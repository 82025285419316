import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { User } from '../../../types/apiTypes';
import { cloneDeep, defaultsDeep } from 'lodash';

const updateProfileFn = async (profile: Partial<User['profile']>) => {
    const { data } = await getApiClient('user').put<User>('/', profile);
    return data;
};

export const useUpdateProfile = (onSuccess?: () => void, showSuccessMsg = true) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(updateProfileFn, {
        onMutate: async (updatedInfo) => {
            await queryClient.cancelQueries(['current-user']);

            let originalUser;

            queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
                if (!userData) {
                    return undefined;
                }

                originalUser = cloneDeep(userData);

                const updatedProfileData = defaultsDeep(updatedInfo, userData.profile);

                return { ...userData, profile: updatedProfileData };
            });

            return { originalUser };
        },
        onSuccess: () => {
            if (showSuccessMsg) {
                const msg = t('profileUpdatedMsg');
                toast.success(msg, { hideProgressBar: true, autoClose: 3000 });
            }

            onSuccess?.();
        },
        onError: (_err, _userInfo, context) => {
            const msg = t('profileUpdateErrorMsg');
            toast.error(msg, { hideProgressBar: true, autoClose: 3000 });

            const { originalUser } = context || {};

            if (originalUser) {
                queryClient.setQueryData(['current-user'], () => originalUser);
            }
        },
    });
};
