import { isAfter } from 'date-fns';
import { Booking, BookingDraft, BookingDraftWithRequiredProperties } from '../../../store/bookingReducer';
import { Change } from '../../../types/types';
import { User } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { isEmpty, omit } from 'lodash';
import { getApiClient } from '../../../services/sharetribe/apiClients';

export const isBookingExpired = (booking: Booking | BookingDraft) => {
    if (!booking.expiresAt) {
        return false;
    }

    const d1 = new Date();
    const d2 = new Date(booking.expiresAt);

    return isAfter(d1, d2);
};

export const hasChanged = (change: Change<any> | undefined): boolean => {
    return change?.from !== undefined && change?.to !== undefined && change.from !== change.to;
};

export const hasNestedChanged = (change: Change<any> | undefined, keys: string[]): boolean => {
    return keys.some((key) => hasChanged(change?.from?.[key]) || hasChanged(change?.to?.[key]));
};

export const isBookingDraftWithRequiredProperties = (booking: BookingDraft): booking is BookingDraftWithRequiredProperties =>
    !!booking.range.bookingStart && !!booking.range.bookingEnd && !!booking.productLabel && !!booking.paymentMethod && !!booking.deliveryMethod;

export const isBookingDraft = (booking: Booking | BookingDraft): booking is BookingDraft => !booking.status;

export const isBooking = (booking: Booking | BookingDraft): booking is Booking =>
    !!(booking.listingId && booking.paymentMethod && booking.paymentIntentClientSecret && booking.customer && booking.transaction);

type AsyncFunc<T, U> = (input: T) => Promise<U>;

// This is pretty much the same as:
// fnRequestPayment({...initialParams})
//   .then(result => fnConfirmCardPayment({...result}))
//   .then(result => fnConfirmPayment({...result}))
export function composeAsync<T, U>(...funcs: [AsyncFunc<T, any>, ...AsyncFunc<any, any>[]]): (input: T) => Promise<U> {
    return (initialInput: T) =>
        funcs.reduce<Promise<any>>((promiseChain, currentFunc) => promiseChain.then(currentFunc), Promise.resolve(initialInput)) as Promise<U>;
}

export const updateUserDeliveryDetailsMaybe = async (booking: BookingDraft, queryClient: QueryClient) => {
    if (booking.deliveryDetails?.rememberForLater) {
        const currentUserData = queryClient.getQueryData<User | undefined>(['current-user']);

        if (!currentUserData) {
            return null;
        }

        const { data } = await getApiClient('user').put('/', {
            privateData: {
                ...currentUserData.profile.privateData,
                deliveryDetails: omit(booking.deliveryDetails, 'rememberForLater'),
            },
        });

        queryClient.setQueryData<User | undefined>(['current-user'], data);
    }
};

// Check if any of the booking parameters that may have an effect on the delivery / price have changed since the transaction was created.
export const checkShouldRecreateTransaction = (booking: Booking) => {
    const { changes } = booking;

    if (isEmpty(changes)) {
        return false;
    }

    const hasDeliveryDetailsChanged = hasNestedChanged(changes.deliveryDetails, ['street', 'postalCode', 'city', 'phone']);
    const hasRangeChanged = hasNestedChanged(changes.range, ['bookingStart', 'bookingEnd']);
    const hasDeliveryMethodChanged = hasChanged(changes.deliveryMethod);

    return hasDeliveryDetailsChanged || hasRangeChanged || hasDeliveryMethodChanged;
};
