import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BreakdownAccordion } from '../../../components/BreakdownAccordion/BreakdownAccordion';
import { formatDateRange, formatPrice } from '../../../helpers/commonHelpers';
import { OwnTransaction } from '../../../transactions/apiTypes';
import { ReactFCWithChildren } from '../../../types/types';
import { CardSummary, CardSummaryDetails, CardTitle } from './TransactionCard.components';
import { UsernameLabel } from './UsernameLabel';
import { FormInputWithLabel } from '../../../components/FormInputWithLabel/FormInputWithLabel';
import { Image } from '@mantine/core';
import { NextTransactionAction } from './NextTransactionAction';
import { getDurationInDays } from '../../../helpers/dateAndTimeHelpers';
import { UnstyledLink } from '../../..';
import { DeliveryInformationSection } from './DeliveryDetails';
import { DeliveryMethod, OfficeConfigurationMethods } from '../../../queries/useDeliveryTiming';
import { calculateTotalFromLineItems } from '../../../helpers/currency';

interface ActionableTransactionCardProps {
    transactionData: OwnTransaction;
    type: 'sale' | 'order';
    deliveryMethods: DeliveryMethod[];
    onAction: (action: 'confirm' | 'decline', transaction: OwnTransaction) => void;
}

/**
 * Card to display transactions that require user action
 */
export const ActionableTransaction: ReactFCWithChildren<ActionableTransactionCardProps> = ({ deliveryMethods, transactionData, type, onAction }) => {
    const { customer, listing, transaction, booking } = transactionData;
    const { t } = useTranslation();
    const theme = useTheme();

    const receiverText = t(`transaction-card-counterparty-${type}`);
    const periodText = `${formatDateRange(new Date(booking.attributes.displayStart), new Date(booking.attributes.displayEnd))}`;
    const durationText = ` (${getDurationInDays(booking.attributes.displayStart, booking.attributes.displayEnd)} ${t('days')})`;

    const nextActionTranslationParams = {
        type,
        bookingStart: new Date(booking.attributes.displayStart),
        bookingEnd: new Date(booking.attributes.displayEnd),
        protectedData: transaction.attributes.protectedData,
        lastTransitionedAt: transaction.attributes.lastTransitionedAt,
        isPresentInShowroom: listing.presentInShowroom,
        deliveryMethods,
    };

    const payoutTotal = calculateTotalFromLineItems(transaction.attributes.lineItems);

    return (
        <Card raised>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
                <CardTitle>
                    <UnstyledLink href={`/listing/${listing.id}`}>
                        <Typography variant="overline">{listing.title}</Typography>
                    </UnstyledLink>
                </CardTitle>
                <CardSummary>
                    <UnstyledLink href={`/listing/${listing.id}`}>
                        <Image
                            src={listing.imageUrl}
                            alt={listing.title}
                            withPlaceholder
                            height={110}
                            width={110}
                            styles={{ placeholder: { backgroundColor: theme.palette.background.paper } }}
                            sx={{
                                objectFit: 'cover',
                                borderRadius: '5px',
                                boxShadow: theme.palette.boxShadow.medium,
                            }}
                        />
                    </UnstyledLink>

                    <CardSummaryDetails>
                        <FormInputWithLabel label={receiverText} value={<UsernameLabel user={customer} />} />
                        <FormInputWithLabel
                            label={t('period')}
                            value={
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <span style={{ fontWeight: 'bold' }}>{periodText}</span>
                                    <span>&nbsp;{durationText}</span>
                                </div>
                            }
                        />
                    </CardSummaryDetails>
                </CardSummary>

                <Box sx={{ mt: 2 }}>
                    <DeliveryInformationSection
                        deliveryMethods={deliveryMethods}
                        transactionProtectedData={transaction.attributes.protectedData}
                        transactionType={type}
                    />
                </Box>

                <BreakdownAccordion
                    lineItems={transaction.attributes.lineItems}
                    containerSX={{ mt: 2 }}
                    summary={
                        <span
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '10px',
                                alignItems: 'center',
                                padding: '10px 0',
                            }}
                        >
                            {t('youEarn')}:<span style={{ fontWeight: 'bold', fontSize: '16px' }}>{formatPrice(payoutTotal.amount)}</span>
                        </span>
                    }
                />
                <Box sx={{ mt: 2 }}>
                    <NextTransactionAction
                        lastTransition={transaction.attributes.lastTransition}
                        type={type}
                        translationParams={nextActionTranslationParams}
                    />
                </Box>
            </CardContent>

            {!customer.deleted && !customer.banned && (
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button size="small" onClick={() => onAction('decline', transactionData)}>
                        {t('decline')}
                    </Button>
                    <Button size="small" onClick={() => onAction('confirm', transactionData)} variant="contained">
                        {t('confirm')}
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};
