import React, { useState } from 'react';
import { ArrowRight } from '@mui/icons-material';
import { ButtonBase, styled, SxProps, Typography } from '@mui/material';
import { SelectList } from '../SelectList/SelectList';
import { SwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { SimpleSelect } from '../SimpleSelect/SimpleSelect';
import { useTranslation } from 'react-i18next';
import { capitalizeString } from '../../helpers/commonHelpers';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
    padding: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.default,
    borderRadius: '15px',
    '& p': {
        opacity: 0.8,
    },
}));

interface SelectButtonProps {
    label: string;
    selectedValue: string;
    onClick: () => void;
    renderSelectedValue?: () => React.ReactNode;
    buttonSx?: SxProps;
}

export const SelectButton: React.FC<SelectButtonProps> = ({ onClick, label, renderSelectedValue, selectedValue, buttonSx }) => {
    const { t } = useTranslation();

    const getDisplayValue = () => {
        if (selectedValue && renderSelectedValue) {
            return renderSelectedValue();
        }
        if (selectedValue) {
            return capitalizeString(selectedValue);
        }
        return capitalizeString(`${t('select')} ${label}`);
    };

    return (
        <StyledButton onClick={onClick} sx={buttonSx}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>{getDisplayValue()}</div>

            <ArrowRight />
        </StyledButton>
    );
};

interface DrawerSelectProps {
    label: string;
    options: { value: string; label: string }[];
    selectedValue: string;
    closeOnChange?: boolean;
    hideSearch?: boolean;
    fillHeight?: boolean;
    inputValue?: string;
    onSelect: (value: string) => void;
    onInputChange?: (value: string) => void;
    renderSelectedValue?: () => React.ReactNode;
    ModalStyle?: React.CSSProperties;
    buttonSx?: SxProps;
}
export const DrawerSelect: React.FC<DrawerSelectProps> = ({
    label,
    options,
    hideSearch,
    inputValue,
    onInputChange,
    fillHeight = true,
    closeOnChange = true,
    renderSelectedValue,
    selectedValue,
    onSelect,
    ModalStyle,
    buttonSx,
}) => {
    const [open, setOpen] = useState(false);
    const isDesktop = useBreakpoint('sm');

    const handleSelect = (value: string) => {
        if (!isDesktop && closeOnChange) {
            window.history.back();
        }
        onSelect(value);
    };

    return (
        <>
            {isDesktop ? (
                <SimpleSelect options={options} onChange={handleSelect} label={label} selectedValue={selectedValue} />
            ) : (
                <>
                    <SelectButton
                        onClick={() => setOpen(true)}
                        label={label}
                        selectedValue={selectedValue}
                        renderSelectedValue={renderSelectedValue}
                        buttonSx={buttonSx}
                    />
                    <SwipeableDrawer open={open} onClose={() => setOpen(false)} headerText={label} fillHeight={fillHeight} ModalStyle={ModalStyle}>
                        <SelectList
                            options={options}
                            onSelectOption={handleSelect}
                            inputValue={inputValue}
                            hideSearch={hideSearch}
                            onInputChange={onInputChange}
                            selectedValue={selectedValue}
                        />
                    </SwipeableDrawer>
                </>
            )}
        </>
    );
};
