import { Box, Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { IdentityVerification, IdentityVerificationState } from './IdentityVerification';
import { StepContent } from '../../components/NewUserOnboarding/UserOnboarding.components';
import { useSignicatIdentificationFlow, useStripeIdentificationFlow } from '../../hooks/auth/signicat/useIdentification';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import { useSocket } from '../../context/socket';
import { useCurrentUser } from '../../user/hooks/useUser';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { useQueryClient } from '@tanstack/react-query';
import { User } from '../../types/apiTypes';
import { match, P } from 'ts-pattern';

interface SignicatIdentityVerificationModalProps {
    redirectUrl: string;
    state?: IdentityVerificationState;
}

export const SignicatIdentityVerificationModal: React.FC<SignicatIdentityVerificationModalProps> = ({ redirectUrl, state = 'initial' }) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const redirectParams = {
        path: redirectUrl,
    };

    const { mutate: startRedirectFlow, isLoading } = useSignicatIdentificationFlow(redirectParams);

    return (
        <>
            <Button variant="contained" sx={{ m: '15px', width: 'calc(100% - 30px)' }} onClick={() => setOpen(true)}>
                <motion.div animate={{ opacity: [0.3, 1, 0.3] }} transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}>
                    {t('verificationRequired')}
                </motion.div>
            </Button>

            <Modal open={open} onClose={() => setOpen(false)} variant="lg">
                <StepContent sx={{ pb: 2, overflowY: 'auto' }}>
                    <IdentityVerification identificationProvider="signicat" state={state} />

                    <LoadingButton loading={isLoading} variant="contained" onClick={() => startRedirectFlow()}>
                        {t('verifyIdentityShort')}
                    </LoadingButton>
                </StepContent>
            </Modal>
        </>
    );
};

const initStripeEventState = (user: User | undefined): StripeEventState => {
    const identificationErrorMaybe = user?.profile?.publicData?.identityVerificationErrorReason;

    if (identificationErrorMaybe) {
        return { status: 'requires-input', message: identificationErrorMaybe };
    }

    if (user?.profile?.publicData?.identityProcessing) {
        return { status: 'processing' };
    }

    return { status: 'initial' };
};

type StripeEventStatus = 'initial' | 'success' | 'requires-input' | 'processing';
type StripeEventState = { status: StripeEventStatus; message?: string };

export const StripeIdentityVerificationModal: React.FC = () => {
    const { t } = useTranslation();
    const { listen } = useSocket();
    const { data: currentUser } = useCurrentUser();

    const [timedOut, setTimedOut] = useState(false);
    const [isVerified, setIsVerified] = useState(!!currentUser?.profile?.publicData?.identityVerified);

    const [stripeEventState, setStripeEventState] = useState<{ status: StripeEventStatus; message?: string }>(initStripeEventState(currentUser));
    const [identificationSheetState, setIdentificationSheetState] = useState<IdentityVerificationState>('initial');
    const [open, setOpen] = useState(false);

    const queryClient = useQueryClient();

    const handleRefreshVerificationState = async () => {
        const { data } = await getApiClient('user').get<User>('/current');

        if (!data) {
            return;
        }

        if (data.profile.publicData.identityVerified) {
            setStripeEventState({ status: 'success' });
        }

        // If the user has started the identification process, but it's not yet completed
        else if (data.profile.publicData.identityProcessing) {
            setStripeEventState({ status: 'processing' });
        }

        // If the user has started the identification process, but it requires additional input
        else if (data.profile.publicData.identityVerificationErrorReason) {
            setStripeEventState({ status: 'requires-input', message: data.profile.publicData.identityVerificationErrorReason });
        }
    };

    const handleStripeIdentityVerified = async () => {
        await handleRefreshVerificationState();
    };

    useEffect(() => {
        if (!currentUser?.id) return;

        const handleStripeWebhook = async (message: string) => {
            const parsed = JSON.parse(message);
            const { type, payload } = parsed;

            if (type === 'stripe-identity-verified') {
                await handleStripeIdentityVerified();
            }

            if (type === 'stripe-identity-requires-input') {
                setStripeEventState({ status: 'requires-input', message: payload });
            }

            if (type === 'stripe-identity-processing') {
                setStripeEventState({ status: 'processing' });
            }
        };

        const unsubscribe = listen(currentUser.id, handleStripeWebhook);

        return () => {
            unsubscribe();
        };
    }, [currentUser, listen]);

    const handleIdentitySheetSuccess = async () => {
        await handleRefreshVerificationState();

        setIdentificationSheetState('success');
    };

    const handleIdentitySheetError = () => {
        setIdentificationSheetState('error');
    };

    const handleIdentitySheetAbort = () => {
        setIdentificationSheetState('abort');
    };

    const handleCloseModal = () => {
        setIdentificationSheetState('initial');
        setStripeEventState(initStripeEventState(currentUser));
        setIsVerified(!!currentUser?.profile?.publicData?.identityVerified);

        setOpen(false);
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const { mutate: startIdentification, isLoading } = useStripeIdentificationFlow({
        onSuccess: handleIdentitySheetSuccess,
        onError: handleIdentitySheetError,
        onAbort: handleIdentitySheetAbort,
    });

    const handleStartIdentification = () => {
        setTimedOut(false);
        startIdentification();
    };

    const handleTimeout = async () => {
        const { data } = await getApiClient('user').get<User>('/current');

        if (data) {
            queryClient.setQueryData(['current-user'], data);
        }

        const verified = data?.profile?.publicData?.identityVerified;

        if (verified) {
            setIsVerified(verified);
        } else {
            setTimedOut(true);
        }
    };

    /**
     * Resolved state is used to determine the state of the modal.
     *
     * The state can be one of the following:
     *
     * 1. initial: User has not yet started the identification.
     * 2. requires-input: User has submitted their information but needs to provide additional information.
     * 3. success: User has successfully verified their identity.
     * 4. pending-stripe: User has submitted their information and is waiting for the response from Stripe via webhook
     * 5. error or abort: If something went wrong with the Stripe Identity plugin, or the user canceled the identification.
     */
    const resolvedState = useMemo(() => {
        const identityVerified = currentUser?.profile?.publicData?.identityVerified;
        const identificationErrorMaybe = currentUser?.profile?.publicData?.identityVerificationErrorReason;
        const stripeEventStatus = stripeEventState.status;

        type MatchInput = {
            identificationSheetState: IdentityVerificationState;
            stripeEventStatus: StripeEventStatus;
            identityVerified: boolean | undefined;
            identificationErrorMaybe: string | undefined | null;
        };

        type MatchOutput = IdentityVerificationState;

        const result = match<MatchInput, MatchOutput>({
            identificationSheetState,
            stripeEventStatus,
            identityVerified,
            identificationErrorMaybe,
        })
            .with({ identityVerified: true }, { stripeEventStatus: 'success' }, () => 'success')
            .with({ identificationSheetState: 'success', stripeEventStatus: 'initial' }, { stripeEventStatus: 'processing' }, () => 'pending-stripe')
            .with({ identificationSheetState: 'abort' }, () => 'abort')
            .with({ identificationSheetState: 'error' }, () => 'error')
            .with(
                { identificationErrorMaybe: P.not(P.nullish) },
                { identificationSheetState: 'success', stripeEventStatus: 'requires-input' },
                () => 'requires-input',
            )
            .otherwise(() => identificationSheetState);

        return result;
    }, [stripeEventState, identificationSheetState, currentUser]);

    const resolvedButtonText = useMemo(() => {
        if (resolvedState === 'requires-input') {
            return t('retryVerification');
        }

        if (resolvedState === 'success') {
            return t('continue');
        }

        return t('verifyIdentityShort');
    }, [resolvedState, t]);

    const resolvedClickHandler = useCallback(() => {
        if (resolvedState === 'success') {
            handleCloseModal();
        } else {
            handleStartIdentification();
        }
    }, [resolvedState, handleCloseModal, handleStartIdentification]);

    if (isVerified) {
        return null;
    }

    return (
        <>
            {resolvedState !== 'success' && (
                <Button variant="contained" sx={{ m: '15px', width: 'calc(100% - 30px)' }} onClick={handleOpenModal}>
                    <motion.div animate={{ opacity: [0.3, 1, 0.3] }} transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}>
                        {t('verificationRequired')}
                    </motion.div>
                </Button>
            )}

            <Modal open={open} onClose={handleCloseModal} variant="lg">
                <StepContent sx={{ pb: 2, overflowY: 'auto' }}>
                    <IdentityVerification
                        identificationProvider="stripe"
                        stripeIdentificationErrorReason={stripeEventState.message}
                        state={resolvedState}
                        timedOut={timedOut}
                        onTimeout={handleTimeout}
                        onRefresh={handleRefreshVerificationState}
                    />

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        disabled={resolvedState === 'pending-stripe'}
                        onClick={resolvedClickHandler}
                    >
                        {resolvedButtonText}
                    </LoadingButton>
                </StepContent>
            </Modal>
        </>
    );
};
