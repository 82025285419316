import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeUp } from '../../animations/constants';
import { DeliveryMethod, Methods, ReturnMethod } from '../../queries/useDeliveryTiming';
import {
    BookingDetails,
    getReturnMethodFormFields,
    getReturnMethodsForDeliveryOption,
    getReturnTimeFormFields,
    renderReturnMethodValue,
    renderTimeValue,
} from './LogisticsSection.helpers';
import { useFormContext } from 'react-hook-form';
import SwipeableFormDrawer from '../../components/SwipeableFormDrawer/SwipeableFormDrawer';
import { ArrowForwardIos } from '@mui/icons-material';
import { FormRenderer } from '../../components/FormRenderer/FormRenderer';
import { StyledSvgIcon } from '../../components/Styled/Styled.components';
import { OpenDrawersState } from '../../context/drawer';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { RobesAddressSection } from './RobesAddressSection';
import { DisplayReturnTime } from './DisplayReturnTime';
import { returnTimeInterval } from '../../constants';
import { getTransactionDate } from '../../helpers/delivery';

interface ReturnDetailsSectionProps {
    booking: Booking | BookingDraft;
    bookingDetails: BookingDetails;
    deliveryMethods: DeliveryMethod[];
    openDrawersState: OpenDrawersState;
    onClickDrawerButton: (drawerName: string) => void;
    onCloseDrawer: (drawerName: string) => void;
}

export const ReturnDetailsSection: React.FC<ReturnDetailsSectionProps> = ({
    bookingDetails,
    deliveryMethods,
    openDrawersState,
    onClickDrawerButton,
    onCloseDrawer,
}) => {
    const { watch } = useFormContext();
    const { t } = useTranslation();
    const { deliveryMethod } = bookingDetails;

    const returnMethod = watch('returnMethod') as ReturnMethod['method'] | undefined;
    const returnTime = watch('returnTime');

    const returnMethods = getReturnMethodsForDeliveryOption(deliveryMethods, deliveryMethod);
    const returnMethodFormFields = getReturnMethodFormFields(t, returnMethods);
    const returnTimeFormFields = getReturnTimeFormFields(deliveryMethods, bookingDetails, t);
    const returnDate = getTransactionDate(deliveryMethods, deliveryMethod, 'renterReturnDate');

    return (
        <AnimatedContainer variants={fadeUp} transition={{ delay: 0.1 }} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('returnSectionTitle')}
            </Typography>

            <SwipeableFormDrawer
                id="returnMethod"
                label={t('selectReturnMethod')}
                buttonLabel={t('returnMethod')}
                open={openDrawersState.returnMethod || false}
                onClick={() => onClickDrawerButton('returnMethod')}
                onClose={() => onCloseDrawer('returnMethod')}
                renderValue={renderReturnMethodValue()}
                sx={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={
                    <StyledSvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                            <path d="M372.308-267.692 160-480l212.308-212.308L400.615-664 236.616-500H760v-160h40v200H236.616l163.999 164-28.307 28.308Z" />
                        </svg>
                    </StyledSvgIcon>
                }
            >
                <FormRenderer formFields={returnMethodFormFields} />
            </SwipeableFormDrawer>

            {returnMethod && (
                <AnimatedContainer variants={fadeUp} transition={{ delay: 0.1 }} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Divider />

                    {returnMethod === 'showroom' && (
                        <>
                            <RobesAddressSection sectionLabel={t('returnAddress')} showDescription />
                            <Divider />
                            <DisplayReturnTime t={t} bookingDetails={bookingDetails} deliveryMethods={deliveryMethods} />
                        </>
                    )}

                    {returnMethod === 'uber' && (
                        <>
                            <SwipeableFormDrawer
                                id="returnTime"
                                label={t('returnTime')}
                                open={openDrawersState.returnTime || false}
                                buttonLabel={t('returnTime')}
                                onClick={() => onClickDrawerButton('returnTime')}
                                onClose={() => onCloseDrawer('returnTime')}
                                renderValue={renderTimeValue('returnTime', returnDate, returnTimeInterval)}
                                sx={{
                                    padding: '16px 16px calc(16px + env(safe-area-inset-bottom))',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '15px',
                                }}
                                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                                buttonPrefix={
                                    <StyledSvgIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                            <path d="m618.924-298.924 42.152-42.152-151.077-151.087V-680h-59.998v212.154l168.923 168.922ZM480.067-100.001q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-480Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
                                        </svg>
                                    </StyledSvgIcon>
                                }
                            >
                                <FormRenderer formFields={returnTimeFormFields} />
                            </SwipeableFormDrawer>
                        </>
                    )}
                </AnimatedContainer>
            )}
        </AnimatedContainer>
    );
};
