import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../helpers/commonHelpers';
import { Nil } from '../../types/types';

export const DisplayTotal: React.FC<{ total: number; discountedTotal: number | Nil }> = ({ total, discountedTotal }) => {
    const { t } = useTranslation();

    if (discountedTotal) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>{formatPrice(discountedTotal)}</span>
                </Typography>
                <Typography variant="overline" sx={{ textDecoration: 'line-through', opacity: 0.5 }}>
                    {formatPrice(total)}
                </Typography>
                <span style={{ opacity: 1 }}>{` ${t('total')}`}</span>
            </div>
        );
    }
    return (
        <Typography variant="body1">
            <span style={{ fontWeight: 'bold' }}>{formatPrice(total)}</span>

            <span style={{ opacity: 0.7 }}>{` ${t('total')}`}</span>
        </Typography>
    );
};
