import { DependencyList, SetStateAction, useEffect, useState } from 'react';

export const useDuration = (deps: DependencyList, duration = 1000): [boolean, React.Dispatch<SetStateAction<boolean>>] => {
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setFinished(true);
        }, duration);

        return () => clearTimeout(timer);
    }, [deps, duration]);

    return [finished, setFinished];
};
