import { AxiosResponse } from 'axios';
import { User } from '../../../types/apiTypes';
import { getApiClient } from '../apiClients';

export interface UpdateUserRequestParams {
    firstName: string;
    lastName: string;
    displayName: string;
    bio: string;
    publicData: any;
    protectedData: any;
    privateData: any;
    profileImageId: string;
}

const create = async (
    email: string,
    firstName: string,
    lastName: string,
    password?: string,
    idpId?: string,
    idpToken?: string,
    idpClientId?: string,
): Promise<{ response?: AxiosResponse<User>; error?: any }> => {
    const url = idpToken && idpId && idpClientId ? '/idp' : '/';
    const data = {
        email,
        password,
        firstName,
        lastName,
        idpId,
        idpToken,
        idpClientId,
    };
    try {
        const response = await getApiClient('user').post(url, data);
        return { response };
    } catch (error: any) {
        return { error };
    }
};

const update = async (props: Partial<UpdateUserRequestParams>): Promise<{ response?: AxiosResponse<User>; error?: any }> => {
    const url = '/';
    const data = {
        ...props,
    };
    try {
        const response = await getApiClient('user').put(url, data);
        return { response };
    } catch (error: any) {
        return { error };
    }
};

export const userService = {
    create,
    update,
};
