import { LineItem } from '../transactions/types';

import Decimal from 'decimal.js';
import { logger } from './logger';
import { getAppConfig } from '../countryConfigs';

export class Money {
    amount: number;
    currency: string;
    _sdkType? = 'Money';
    constructor(amount: number, currency: string) {
        this.amount = amount;
        this.currency = currency;
    }
}

export const convertDecimalJSToNumber = (decimalValue: Decimal) => {
    if (!isSafeNumber(decimalValue)) {
        throw new Error(`Cannot represent Decimal.js value ${decimalValue.toString()} safely as a number`);
    }

    return decimalValue.toNumber();
};

// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/MIN_SAFE_INTEGER
// https://stackoverflow.com/questions/26380364/why-is-number-max-safe-integer-9-007-199-254-740-991-and-not-9-007-199-254-740-9
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || -1 * (2 ** 53 - 1);
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 2 ** 53 - 1;

const isSafeNumber = (decimalValue: Decimal) => {
    if (!(decimalValue instanceof Decimal)) {
        logger.error(decimalValue);
        throw new Error('Value must be a Decimal');
    }
    return decimalValue.gte(MIN_SAFE_INTEGER) && decimalValue.lte(MAX_SAFE_INTEGER);
};

const isNumber = (value: number) => {
    return typeof value === 'number' && !isNaN(value);
};

// Detect if the given value is a goog.math.Long object
// See: https://google.github.io/closure-library/api/goog.math.Long.html
const isGoogleMathLong = (value: any) => {
    return typeof value === 'object' && isNumber(value.low_) && isNumber(value.high_);
};

export const getAmountAsDecimalJS = (value: Money) => {
    if (!value.amount || !value.currency) {
        logger.error(value);
        throw new Error('Value must be a Money type');
    }
    let amount: Decimal;

    if (isGoogleMathLong(value.amount)) {
        console.warn('goog.math.Long value in money amount:', value.amount, value.amount.toString());

        amount = new Decimal(value.amount.toString());
    } else {
        amount = new Decimal(value.amount);
    }

    if (!isSafeNumber(amount)) {
        throw new Error(`Cannot represent money minor unit value ${amount.toString()} safely as a number`);
    }

    return amount;
};

const calculateTotalPriceFromQuantity = (unitPrice: Money, unitCount: string | number): Money => {
    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);

    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice.times(unitCount).toNearest(1, Decimal.ROUND_HALF_UP);
    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);

    return new Money(numericTotalPrice, unitPrice.currency);
};

const calculateTotalPriceFromPercentage = (unitPrice: Money, percentage: string | number): Money => {
    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);

    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice.times(percentage).dividedBy(100).toNearest(1, Decimal.ROUND_HALF_UP);

    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);

    return new Money(numericTotalPrice, unitPrice.currency);
};

const calculateLineTotal = (lineItem: LineItem) => {
    const { code, unitPrice, quantity, percentage } = lineItem;

    if (quantity) {
        const resolvedQuantity = typeof quantity === 'object' ? quantity.value : quantity;
        return calculateTotalPriceFromQuantity(unitPrice, resolvedQuantity);
    } else if (percentage) {
        const resolvedPercentage = typeof percentage === 'object' ? percentage.value : percentage;
        return calculateTotalPriceFromPercentage(unitPrice, resolvedPercentage);
    } else {
        throw new Error(`Can't calculate the lineTotal of lineItem: ${code}. Make sure the lineItem has quantity or percentage.`);
    }
};

export const calculateTotalFromLineItems = (lineItems: LineItem[]) => {
    if (!lineItems.length) {
        return new Money(0, getAppConfig().stripeConfig.currency);
    }

    const totalPrice = lineItems.reduce((sum: Decimal, lineItem: LineItem) => {
        const lineTotal = calculateLineTotal(lineItem);
        return getAmountAsDecimalJS(lineTotal).add(sum);
    }, new Decimal(0));

    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);
    const unitPrice = lineItems[0].unitPrice;

    return new Money(numericTotalPrice, unitPrice.currency);
};

export const calculateTotalFromLineItemsExcludeCredits = (lineItems: LineItem[]) => {
    return calculateTotalFromLineItems(lineItems.filter((lineItem) => lineItem.code !== 'line-item/robes-credits'));
};

export const getCreditsFromLineItems = (lineItems: LineItem[]) => {
    const creditsLineItem = lineItems.find((item) => item.code === 'line-item/robes-credits');
    return creditsLineItem ? creditsLineItem : null;
};

export const multiplyMoney = (money: Money, multiplier: number) => {
    const amount = getAmountAsDecimalJS(money).times(multiplier);
    const numericAmount = convertDecimalJSToNumber(amount);

    return new Money(numericAmount, money.currency);
};
