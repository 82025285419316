import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';

export interface ConversationsOverview {
    conversationIds: string[];
    unreadMessages: string[];
}

// TODO: "unreadMessages" does not really convey the meaning of the array. It is actually a list of conversation IDs with unread messages (any amount).
export const useConversationsOverview = (): UseQueryResult<ConversationsOverview> => {
    const token = useAppSelector(selectToken);

    return useQuery(
        ['conversations-overview'],
        async () => {
            const res = await getApiClient('conversations').get<ConversationsOverview>(`/overview`);
            return res.data;
        },
        { enabled: !!token },
    );
};
