import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from 'react-toastify';
import { clearStoredPaths } from '../../components/ScrollRestore/ScrollRestore';

import { logger } from '../../helpers/logger';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { useAppDispatch } from '../../store/hooks';
import { resetToken } from '../../store/userReducer';
import { useSafeNavigate } from '../useSafeNavigate';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { countryConfig, getAppConfig, handleChangeCountryOfResidence, setAppConfig } from '../../countryConfigs';
import i18next from 'i18next';
import { getCurrentLanguage } from '../../helpers/dateAndTimeHelpers';
import { Preferences } from '@capacitor/preferences';

export type UseLogoutArgs = {
    successToast?: { type: 'info' | 'success' | 'error'; msg: string };
    changedCountryOfResidence?: 'FI' | 'US';
};

export const useLogout = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();
    const queryClient = useQueryClient();

    const resetUser = () => {
        dispatch(resetToken());
        queryClient.clear();
        clearStoredPaths();
    };

    return useMutation(
        async (args: UseLogoutArgs) => {
            const { successToast, changedCountryOfResidence } = args;
            const url = '/logout';

            await getApiClient('auth').get(url);

            if (changedCountryOfResidence) {
                await handleChangeCountryOfResidence(changedCountryOfResidence);
            }

            return successToast;
        },
        {
            onSuccess: async (successToast) => {
                resetUser();
                navigate('/login', { state: { loggedOut: true } });

                if (Capacitor.getPlatform() !== 'web') {
                    await PushNotifications.unregister();
                }

                if (successToast) {
                    toast[successToast.type](successToast.msg, {
                        autoClose: 3000,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    const msg = t('loggedOut');
                    toast.info(msg, {
                        autoClose: 3000,
                        draggable: true,
                        progress: undefined,
                    });
                }
            },
            onError: async (error) => {
                resetUser();

                if (Capacitor.getPlatform() !== 'web') {
                    await PushNotifications.unregister();
                }

                logger.error('Failed to logout user', error);
                const msg = t('unexpectedError');
                toast.error(msg, { toastId: 'logout' });
            },
        },
    );
};
