import React, { useCallback, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import Grid from '@mui/material/Grid/Grid';
import LoadingButton from '@mui/lab/LoadingButton';

import { ColorIndicator, ErrorText, SectionContainer } from './AddListing.components';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { ListingData } from '../Listings/types';
import { useAddListing } from './useAddListing';
import { ListingDetails } from '../ViewListing/ListingDetails';
import { ListingPreview, User, ExtendedListing, Image } from '../../types/apiTypes';
import { useParams } from 'react-router-dom';
import { useListing } from '../../queries/useListing';
import { formatPriceString, sortCollectionByArr, stringToMoney } from '../../helpers/commonHelpers';
import { Alert, Box, CircularProgress, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { useCurrentUser } from '../../user/hooks/useUser';
import { categories, Category, colorOptions, getBodytypeOptions, getSizingOptionsForCategory } from './constants';
import { SwipeableDrawer } from '../../components/SwipeableDrawer/SwipeableDrawer';
import { DrawerSelect, SelectButton } from '../../components/DrawerSelect/DrawerSelect';
import { TagSingleSelectDrawer } from './TagSelectDrawer';
import { isEmpty } from 'lodash';
import { useStripeAccount } from '../UserProfile/hooks/useStripeAccount';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { routeVariants } from '../../animations/constants';
import { getDefaultFormValues, getInitialData, listingStateReducer, successVariants } from './AddListing.helpers';
import { Success } from './AddListingSuccess';
import { ListingHeader } from './AddListingHeader';
import { toast } from 'react-toastify';
import { StyledSpan } from '../../App.components';
import { BannedBrandsDrawer } from './BannedBrandsDrawer';
import { PricingModelForm } from './PricingModelForm';
import { useDiscountDefinition } from '../../strapi/hooks/useDiscountDefinition';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { StripeAccountAlertMaybe } from '../../components/StripeAccountAlert/StripeAccountAlertMaybe';
import { getListingTitle } from '../ViewListing/ViewListing.helpers';
import { useImageUpload } from '../../components/AddImage/useImageUpload';
import { ImageUploader } from '../../components/ImageUploader/ImageUploader';
import { Nil } from '../../types/types';
import { hasRequirements } from '../UserProfile/Stripe/helpers';

const MAX_IMAGES_FOR_LISTING = 10;
const MIN_IMAGES_FOR_LISTING = 3;

const renderErrorText = (text: string) => <ErrorText>{text}</ErrorText>;

interface ListingFormProps {
    listingData?: ExtendedListing | Nil;
    isNew?: boolean;
}

// Already uploaded listings are excempt from the minimum image requirement if they have less than MIN_IMAGES_FOR_LISTING images
const resolveMinImages = (listingData: ExtendedListing | Nil, isNew: boolean): number => {
    if (isNew) {
        return MIN_IMAGES_FOR_LISTING;
    }

    return Math.min(listingData?.images?.length || 0, MIN_IMAGES_FOR_LISTING);
};

export const ListingForm: React.FC<ListingFormProps> = ({ listingData, isNew = false }): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useSafeNavigate();

    const [success, setSuccess] = useState(false);
    const [isPreviewing, setIsPreviewing] = useState(false);
    const [listingState, setListingState] = useReducer(listingStateReducer, getInitialData(isNew, listingData));

    const [drawerState, setDrawerState] = useState<string | null>(null);

    const { images: allImages, pickImages, setImages, uploadImages } = useImageUpload(listingState.images, MAX_IMAGES_FOR_LISTING);
    const { data: loggedInUser } = useCurrentUser();

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<ListingData>(listingData ? getDefaultFormValues(listingData) : undefined);

    const onSuccess = () => {
        if (isNew) {
            setSuccess(true);
        } else {
            const msg = t('updateSuccess');
            toast.success(msg);
        }
    };

    const { mutate: addListing, status: submitStatus } = useAddListing(onSuccess);

    const { data: stripeAccount } = useStripeAccount();

    const stripeConnected = loggedInUser?.stripeConnected;

    const { brand, category, size, color, bodytype, price } = listingState;

    const listingTitle = getListingTitle(listingState);

    const handleResetForm = () => {
        setListingState({ type: 'reset' });
        reset();
        setSuccess(false);
        setImages([]);
    };

    const submitHandler = async (formData: ListingData) => {
        const formattedListingData = {
            ...formData,
            price: listingState.price.dailyPrice,
            title: listingTitle,
            publicData: {
                color,
                size,
                brand: brand.toLocaleLowerCase(),
                category,
                bodytype,
                pricing: {
                    ...(price.originalPrice && { original: price.originalPrice }),
                    ...(price.weeklyPrice && { weekly: price.weeklyPrice }),
                    ...(price.monthlyPrice && { monthly: price.monthlyPrice }),
                },
            },
        };

        addListing({
            listingData: formattedListingData,
            images: allImages,
            id: isNew ? undefined : listingData?.id,
            uploadImagesFn: uploadImages,
            errorMsg: t('addListingError'),
        });
    };

    const handlePickPhotos = async () => {
        await pickImages();
    };

    const handleRemoveImage = (id: string) => {
        setImages((images) => {
            const imageIds = images.map((image) => image.id);
            const filtered = images.filter((image) => image.id !== id);
            const sorted = sortCollectionByArr(filtered, imageIds, 'id');

            return sorted;
        });
    };

    const [description] = getValues(['description']);

    const getListingPreview = (): ListingPreview => {
        if (isNew) {
            return {
                images: allImages,
                title: listingTitle,
                description,
                price: stringToMoney(listingState.price.dailyPrice),
                publicData: {
                    color,
                    size,
                    brand: brand.toLocaleLowerCase(),
                    category,
                    bodytype,
                    pricing: {
                        ...(price.originalPrice && { original: stringToMoney(price.originalPrice).amount }),
                        ...(price.weeklyPrice && { weekly: stringToMoney(price.weeklyPrice).amount }),
                        ...(price.monthlyPrice && { monthly: stringToMoney(price.monthlyPrice).amount }),
                    },
                },
                author: loggedInUser as User,
                preview: true,
            };
        } else {
            return {
                images: allImages,
                title: listingTitle,
                description,
                author: loggedInUser as User,
                preview: true,
                publicData: listingData?.publicData,
                price: listingData?.price,
            };
        }
    };

    const listingPreview = getListingPreview();

    const minImages = resolveMinImages(listingData, isNew);

    const stripeRequirementsMissing = hasRequirements(stripeAccount, ['currently_due', 'past_due']);
    const stripeSetupIncomplete = !stripeConnected || stripeRequirementsMissing;
    const hasEnoughImages = allImages.length >= minImages;
    const formValid = !!(isEmpty(errors) && brand && category && size && bodytype && hasEnoughImages);

    const {
        data: discountDefinition,
        failureCount: discountFetchFailureCount,
        isLoading: discountLoading,
        isError: discountFetchError,
    } = useDiscountDefinition();

    return (
        <AnimatePresence mode="popLayout" initial={false}>
            {success ? (
                <AnimatedContainer key="success" variants={successVariants} style={{ height: '100%', width: '100%' }}>
                    <Success onClick={handleResetForm} />
                </AnimatedContainer>
            ) : (
                <AnimatedContainer key="add-listing" variants={routeVariants} custom="right" style={{ width: '100%' }}>
                    <ListingHeader setIsPreviewing={() => setIsPreviewing(true)} isNew={isNew} />

                    <Box sx={{ pt: 2, mt: 2, width: '100%', paddingBottom: 'calc(16px + env(safe-area-inset-bottom))' }}>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, width: '100%' }}>
                                <SectionContainer sx={{ px: 2 }}>
                                    <StripeAccountAlertMaybe
                                        stripeConnected={stripeConnected}
                                        stripeAccount={stripeAccount}
                                        onClick={() => navigate('/profile/payments-and-payouts')}
                                    />

                                    <Typography variant="h6" fontWeight={600}>
                                        {t('addImages')}
                                    </Typography>

                                    <Typography variant="subtitle2" sx={{ opacity: 0.8, mb: 2 }}>
                                        {t('imageHelperText', { minImages: minImages, maxImages: MAX_IMAGES_FOR_LISTING })}
                                    </Typography>
                                </SectionContainer>

                                <SectionContainer>
                                    <ImageUploader
                                        images={allImages}
                                        onClickAdd={handlePickPhotos}
                                        setImages={setImages}
                                        onRemove={handleRemoveImage}
                                        limit={MAX_IMAGES_FOR_LISTING}
                                    />

                                    <Box sx={{ height: '15px', width: '100%', textAlign: 'center', mt: 2 }}>
                                        {hasEnoughImages ? (
                                            <Typography variant="caption" sx={{ px: 2, opacity: 0.8 }}>
                                                {t('clickAndDragToAdjustOrder')}
                                            </Typography>
                                        ) : (
                                            <>
                                                {allImages.length > 0 && (
                                                    <Typography variant="caption" sx={{ px: 2, opacity: 0.8 }}>
                                                        {t('addNMoreImages', { count: minImages - allImages.length })}
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </SectionContainer>

                                <SectionContainer sx={{ px: 2 }}>
                                    <Typography variant="h6" fontWeight={600}>
                                        {t('whatAreYouLending')}
                                    </Typography>

                                    <Typography variant="subtitle2" sx={{ opacity: 0.8, mb: 3 }}>
                                        <Trans
                                            i18nKey="fastFashionStatement"
                                            components={{ button: <StyledSpan onClick={() => setDrawerState('banned-brands')} /> }}
                                        />
                                    </Typography>

                                    <BannedBrandsDrawer open={drawerState === 'banned-brands'} onClose={() => setDrawerState(null)} />

                                    <TagSingleSelectDrawer
                                        label={t('brand')}
                                        onSelect={(value) => setListingState({ type: 'brand', payload: value })}
                                        selectedValue={brand}
                                    />

                                    <DrawerSelect
                                        label={t('category')}
                                        onSelect={(value) => {
                                            setListingState({ type: 'category', payload: value });
                                            const isValidSizeForCategory =
                                                getSizingOptionsForCategory(value as Category)
                                                    .map((el) => el.value)
                                                    .indexOf(size) >= 0;

                                            if (!isValidSizeForCategory) {
                                                setListingState({ type: 'size', payload: '' });
                                            }
                                            setDrawerState(null);
                                        }}
                                        renderSelectedValue={() => (
                                            <Typography variant="subtitle2" fontWeight="bold">
                                                {t(category)}
                                            </Typography>
                                        )}
                                        options={categories
                                            .map((item) => ({ value: item, label: t(item) }))
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        selectedValue={category}
                                    />

                                    <DrawerSelect
                                        label={t('size')}
                                        onSelect={(value) => {
                                            setListingState({ type: 'size', payload: value });
                                            setDrawerState(null);
                                        }}
                                        renderSelectedValue={() => (
                                            <Typography variant="subtitle2" fontWeight="bold">
                                                {size}
                                            </Typography>
                                        )}
                                        options={getSizingOptionsForCategory(category as Category).map((el) => ({
                                            ...el,
                                            label: t(el.value),
                                        }))}
                                        selectedValue={size}
                                    />

                                    <DrawerSelect
                                        label={t('bodytype')}
                                        hideSearch
                                        fillHeight={false}
                                        onSelect={(value) => {
                                            setListingState({ type: 'bodytype', payload: value });
                                            setDrawerState(null);
                                        }}
                                        renderSelectedValue={() => (
                                            <Typography variant="subtitle2" fontWeight="bold">
                                                {t(bodytype)}
                                            </Typography>
                                        )}
                                        options={getBodytypeOptions().map((item) => ({
                                            value: item,
                                            label: t(item),
                                        }))}
                                        selectedValue={bodytype}
                                    />
                                </SectionContainer>

                                <SectionContainer sx={{ px: 2 }}>
                                    <Typography variant="h6" fontWeight={600}>
                                        {t('color')}
                                    </Typography>

                                    <SelectButton
                                        onClick={() => setDrawerState('color')}
                                        label={t('color')}
                                        selectedValue={color}
                                        renderSelectedValue={() => {
                                            const match = colorOptions.find((option) => option.value === color);
                                            if (match) {
                                                return (
                                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                        <ColorIndicator
                                                            style={{
                                                                ...(match.colorCode && { backgroundColor: match.colorCode }),
                                                                ...(match.src && {
                                                                    backgroundImage: `url(${match.src})`,
                                                                    backgroundSize: 'cover',
                                                                }),
                                                            }}
                                                        />
                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                            {t(color)}
                                                        </Typography>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        }}
                                    />

                                    <SwipeableDrawer open={drawerState === 'color'} onClose={() => setDrawerState(null)} fillHeight={false}>
                                        <Grid container rowSpacing={2} sx={{ pb: 3 }}>
                                            {colorOptions.map((option) => (
                                                <Grid item xs={4} key={option.value}>
                                                    <MenuItem
                                                        sx={{ borderRadius: '15px' }}
                                                        selected={option.value === color}
                                                        onClick={() => {
                                                            setListingState({ type: 'color', payload: option.value });
                                                            window.history.back();
                                                            setDrawerState(null);
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                gap: '10px',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                justifyContent: 'center',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <ColorIndicator
                                                                style={{
                                                                    flexShrink: '0',
                                                                    ...(option.colorCode && { backgroundColor: option.colorCode }),
                                                                    ...(option.src && {
                                                                        backgroundImage: `url(${option.src})`,
                                                                        backgroundSize: 'cover',
                                                                    }),
                                                                }}
                                                            />
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                                            >
                                                                {t(option.value)}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </SwipeableDrawer>
                                </SectionContainer>

                                <SectionContainer sx={{ px: 2 }}>
                                    <Typography variant="h6" fontWeight={600}>
                                        {t('itemDescription')}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.8, mb: 3 }}>
                                        {t('itemDescriptionBody')}
                                    </Typography>

                                    <TextField label={t('details')} multiline rows={4} fullWidth {...register('description')} />
                                    {errors.description && renderErrorText(t('descriptionError'))}
                                </SectionContainer>

                                <SectionContainer sx={{ px: 2 }}>
                                    <Typography variant="h6" fontWeight={600}>
                                        {t('price')}
                                    </Typography>

                                    {discountFetchError || discountFetchFailureCount ? (
                                        <>
                                            {discountLoading && <CircularProgress sx={{ mt: 2 }} size="24px" />}
                                            {discountFetchError && <Alert severity="error">{t('discountFetchError')}</Alert>}
                                        </>
                                    ) : (
                                        <>
                                            <SelectButton
                                                onClick={() => setDrawerState('price')}
                                                label={t('price')}
                                                selectedValue={price.dailyPrice}
                                                renderSelectedValue={() => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'grid',
                                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                                gridAutoRows: '1fr',
                                                                gridColumnGap: '20px',
                                                                gridRowGap: '5px',
                                                                textAlign: 'start',
                                                            }}
                                                        >
                                                            <Typography variant="subtitle2">Daily</Typography>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                {formatPriceString(price.dailyPrice)}
                                                            </Typography>

                                                            {price.weeklyPrice && (
                                                                <>
                                                                    <Typography variant="subtitle2">Weekly</Typography>
                                                                    <Typography variant="subtitle2" fontWeight="bold">
                                                                        {formatPriceString(price.weeklyPrice)}
                                                                    </Typography>
                                                                </>
                                                            )}
                                                            {price.monthlyPrice && (
                                                                <>
                                                                    <Typography variant="subtitle2">Monthly</Typography>
                                                                    <Typography variant="subtitle2" fontWeight="bold">
                                                                        {formatPriceString(price.monthlyPrice)}
                                                                    </Typography>
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                            />

                                            <SwipeableDrawer open={drawerState === 'price'} onClose={() => setDrawerState(null)} fillHeight>
                                                <PricingModelForm
                                                    onApply={(data) => {
                                                        setListingState({ type: 'price', payload: data });
                                                        setDrawerState(null);
                                                    }}
                                                    category={category}
                                                    loading={discountLoading}
                                                    defaultValues={price}
                                                    discountDefinition={discountDefinition}
                                                />
                                            </SwipeableDrawer>
                                        </>
                                    )}
                                </SectionContainer>

                                <SectionContainer sx={{ px: 2, gap: 0 }}>
                                    {!hasEnoughImages && (
                                        <Typography variant="caption">
                                            {allImages.length <= 1
                                                ? t('uploadNImages', { count: minImages - allImages.length })
                                                : t('uploadOneMoreImage')}
                                        </Typography>
                                    )}
                                    <LoadingButton
                                        disabled={stripeSetupIncomplete || !formValid || discountFetchError || !hasEnoughImages}
                                        type="submit"
                                        variant="contained"
                                        loading={submitStatus === 'loading'}
                                        aria-label="Add listing"
                                        sx={{ width: '100%', height: '40px', borderRadius: '10px', p: '0', marginTop: '10px' }}
                                    >
                                        <p>{isNew ? t('submit') : t('saveChanges')}</p>
                                    </LoadingButton>
                                    {submitStatus === 'error' && renderErrorText(t('submitError'))}
                                </SectionContainer>
                            </Box>
                        </form>
                    </Box>

                    <SwipeableDrawer open={isPreviewing && drawerState === null} onClose={() => setIsPreviewing(false)}>
                        <Box sx={{ height: '100%' }}>
                            <ListingDetails listing={listingPreview} showProfile={false} isOwnListing={false} />
                        </Box>
                    </SwipeableDrawer>
                </AnimatedContainer>
            )}
        </AnimatePresence>
    );
};

export const AddListingView: React.FC = () => <ListingForm isNew />;

export const EditListingView: React.FC = () => {
    const { id: listingId } = useParams();
    const { data: listingData, status } = useListing(listingId, { own: true });

    return (
        <ContentLoader status={status} style={{ height: '100%', width: '100%', padding: 0 }}>
            <ListingForm listingData={listingData} />
        </ContentLoader>
    );
};
