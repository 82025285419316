import React from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RobesLogoFull } from './components/RobesLogo/RobesLogoFull';
import { Divider } from '@mantine/core';
import { useLogout } from './hooks/auth/useLogout';
import { NotFoundErrorPage } from './components/ErrorBoundary/NotFoundErrorPage';

export const StyledSpan = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
}));

const UnknownErrorPage: React.FC = () => {
    const { t } = useTranslation();
    const { mutate: logout } = useLogout();

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center' }}>
            <RobesLogoFull height={80} containerStyle={{ marginBottom: 4 }} />

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    color: (t) => t.palette.text.primary,
                }}
            >
                <Typography variant="h4" fontWeight={100} sx={{ color: (t) => t.palette.primary.main, mb: 4, mt: 2 }}>
                    {t('oops')}
                </Typography>

                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        {t('somethingWentWrong')}
                    </Typography>
                    <Typography variant="body2">{t('tryRefreshing')}</Typography>
                </Box>

                <Button variant="contained" sx={{ borderRadius: '15px', mt: 3 }} onClick={() => window.location.reload()}>
                    {t('reloadPage')}
                </Button>

                <Divider label={t('orIfThatDoesNotWork')} labelPosition="center" mx="md" sx={{ width: '90%' }} />

                <Button variant="text" onClick={() => logout({ successToast: { type: 'info', msg: t('loggedOut') } })}>
                    {t('logout')}
                </Button>
            </Box>
        </Box>
    );
};

export const ErrorElement: React.FC<{ error?: unknown }> = ({ error }) => {
    const getErrorStatusCode = () => {
        if (error && typeof error === 'object' && (error as any).response && typeof (error as any).response === 'object') {
            return (error as any).response.status as number;
        }

        return null;
    };

    const errorStatus = getErrorStatusCode();

    if (errorStatus === 404) {
        return <NotFoundErrorPage />;
    }

    return <UnknownErrorPage />;
};
