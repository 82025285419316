import { FormControl, InputBaseComponentProps, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { SettingsContainer } from '../UserProfile.components';
import { useUpdateProfile } from '../hooks/useUpdateProfile';
import { noop } from '../../../helpers/commonHelpers';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { LanguageOutlined } from '@mui/icons-material';
import { getAppConfig } from '../../../countryConfigs';

export const LanguageSelector: React.FC<{ displayIcon?: boolean; inputProps?: InputBaseComponentProps; variant?: 'standard' | 'outlined' }> = ({
    displayIcon = false,
    variant = 'standard',
    inputProps,
}) => {
    const { i18n } = useTranslation();
    const { data: currentUser } = useCurrentUser();
    const { languages } = getAppConfig();

    const { mutate: updateProfile } = useUpdateProfile(noop, false);
    const queryClient = useQueryClient();
    const languageOptions = getAppConfig().languages;

    const handleLanguageChange = (event: SelectChangeEvent) => {
        const lang = event.target.value;
        i18n.changeLanguage(lang);

        // Categories rely on server-side translations
        queryClient.invalidateQueries(['categories']);

        if (currentUser) {
            updateProfile({ privateData: { language: lang } });
        }
    };

    if (languages.length === 1) {
        return null;
    }

    return (
        <FormControl size="small">
            <Select
                variant={variant}
                onChange={handleLanguageChange}
                MenuProps={{ PaperProps: { sx: { maxHeight: '300px' } } }}
                inputProps={inputProps}
                value={i18n.language}
                {...(displayIcon && {
                    startAdornment: (
                        <LanguageOutlined fontSize="small" sx={{ opacity: 0.8, mr: '4px', ...(variant === 'standard' && { mb: '3px' }) }} />
                    ),
                })}
                {...(variant === 'standard' && {
                    disableUnderline: true,
                })}
            >
                {languageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const LanguageSettings: React.FC = () => {
    const { t } = useTranslation();

    return (
        <SettingsContainer>
            <List sx={{ width: '100%' }}>
                <ListItem secondaryAction={<LanguageSelector />}>
                    <ListItemText primary={t('language')} />
                </ListItem>
            </List>
        </SettingsContainer>
    );
};
