import { useQuery } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { Capacitor } from '@capacitor/core';
import { invariant } from '../../helpers/commonHelpers';
import semver from 'semver';
import { App } from '@capacitor/app';

type MinimumVersions = {
    ios: string;
    android: string;
};

export const satisfiesMinimumVersion = async () => {
    const platform = Capacitor.getPlatform();
    const { version } = await App.getInfo();
    invariant(platform === 'ios' || platform === 'android', 'Version check is only available on iOS and Android');

    const { data: minimumVersions } = await getApiClient('minimum-version').get<MinimumVersions>('/');
    const satisfiesMinimumVersion = semver.gte(version, minimumVersions[platform]);

    return satisfiesMinimumVersion;
};

export const useCheckSatisfiesMinimumVersion = () => {
    const enabled = Capacitor.isNativePlatform();

    return useQuery(['versionCheck'], satisfiesMinimumVersion, { staleTime: 1000 * 60 * 15, enabled });
};
