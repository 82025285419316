import React, { CSSProperties, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button/Button';

import { ReactFCWithChildren } from '../../types/types';
import { formatShortMonthAndDate } from '../../helpers/dateAndTimeHelpers';
import { Box, DialogActions, Popper, Tooltip, Typography } from '@mui/material';
import { isRangeValid, rangeLengthInDays } from '../../views/ViewListing/ListingFooter.helpers';
import { addDays, addMonths, isSameDay } from 'date-fns';
import { capitalizeString } from '../../helpers/commonHelpers';
import { DateRange } from './DateRangePicker.types';
import { DateRangePicker, DateRangePickerDay, DateRangePickerDayProps, RangePosition, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { StrikeThrough } from './DateRangePicker.components';
import { minimumRentalLength } from '../../constants';
import { useDuration } from '../../hooks/useDuration';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Modal } from '../Modal/Modal';
import { rem } from '@mantine/core';
import { InfoOutlined } from '@mui/icons-material';

interface Props {
    selectedRange: DateRange;
    isVisible: boolean;
    rangeConstraints: DateRange;
    rangePosition: RangePosition;
    allowSameDaySelection?: boolean;
    mode?: 'inline' | 'modal';
    isDateDisabledFn: (date: Date) => boolean;
    onChange: (range: DateRange) => void;
    onFocusChange: (focus: RangePosition) => void;
    setVisible: (val: boolean) => void;
    onConfirm: () => void;
}

const calendarButtonStyle: CSSProperties = {
    textDecoration: 'underline',
    textTransform: 'none',
    padding: 0,
};

type CustomDayProps = DateRangePickerDayProps<Date> & { selectedRange?: DateRange };

const CustomDayWithTooltip: React.FC<CustomDayProps> = (props) => {
    const [finished] = useDuration([], 1000);
    const { t } = useTranslation();

    return (
        <Tooltip open={!finished} title={t('minimumDays', { amount: minimumRentalLength })} placement="top">
            <div>
                <DateRangePickerDay {...props} />
            </div>
        </Tooltip>
    );
};

const CustomDay = (props: CustomDayProps) => {
    const { selectedRange, ...rest } = props;
    const { disabled, day, outsideCurrentMonth } = rest;

    if (!disabled || outsideCurrentMonth) {
        const showTooltip = selectedRange && selectedRange[0] && !selectedRange[1] && isSameDay(selectedRange[0], day);

        if (showTooltip) {
            return <CustomDayWithTooltip {...rest} />;
        }
        return <DateRangePickerDay {...rest} />;
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <DateRangePickerDay {...rest} />
            <StrikeThrough />
        </Box>
    );
};

const CustomActionBar: React.FC = () => <></>;

const AlertContainer: React.FC<{ displayRentalMaxLengthAlert: boolean; displayRentalLengthError: boolean }> = ({
    displayRentalMaxLengthAlert,
    displayRentalLengthError,
}) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ padding: '0 16px 16px', height: '30px', width: '100%', textAlign: 'center' }}>
            {displayRentalMaxLengthAlert && (
                <Typography variant="caption" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                    <InfoOutlined fontSize="small" />
                    {t('rentalMaxLengthAlert')}
                </Typography>
            )}
            {displayRentalLengthError && (
                <Typography
                    variant="caption"
                    sx={{
                        margin: '0',
                        color: '#a8323a',
                    }}
                >
                    {t('minRentalLength', { amount: minimumRentalLength })}
                </Typography>
            )}
        </Box>
    );
};

interface CustomLayoutProps {
    children: React.ReactNode;
    displayRentalMaxLengthAlert: boolean;
    displayRentalLengthError: boolean;
}

const CustomLayout: React.FC<CustomLayoutProps> = (props) => (
    <Box {...props}>
        <>{props.children}</>
        <AlertContainer displayRentalMaxLengthAlert={props.displayRentalMaxLengthAlert} displayRentalLengthError={props.displayRentalLengthError} />
    </Box>
);

export const DateRangePickerComponent: ReactFCWithChildren<Props> = ({
    selectedRange,
    onFocusChange,
    rangePosition,
    isDateDisabledFn,
    rangeConstraints,
    onChange,
    isVisible,
    allowSameDaySelection = false,
    mode = 'modal',
    setVisible,
    onConfirm,
}) => {
    const [isLastMonthVisible, setIsLastMonthVisible] = useState(false);
    const [minDate, maxDate] = rangeConstraints;

    const { t } = useTranslation();

    const isValid = useMemo(() => {
        const [start, end] = selectedRange;
        const rangeLength = rangeLengthInDays(start, end) || 0;

        return rangeLength >= minimumRentalLength;
    }, [selectedRange]);

    const isDesktop = useBreakpoint('sm');
    const isDirty = useMemo(() => selectedRange[0] && selectedRange[1], [selectedRange]);

    const minDateOffset = allowSameDaySelection ? 0 : 1;

    const resolvedMinDate = minDate || addDays(new Date(), minDateOffset);
    const resolvedMaxDate = maxDate || addDays(new Date(), 89 + minDateOffset);

    const handleSelectedMonthChange = (d: Date) => {
        let isLastMonthVisible: boolean;
        if (mode === 'inline') {
            isLastMonthVisible = d.getMonth() === resolvedMaxDate.getMonth() || d.getMonth() === addMonths(resolvedMaxDate, -1).getMonth();
        } else {
            isLastMonthVisible = d.getMonth() === resolvedMaxDate.getMonth();
        }
        setIsLastMonthVisible(isLastMonthVisible);
    };

    const displayRentalLengthError = Boolean(!isValid && isDirty);
    const displayRentalMaxLengthAlert = isLastMonthVisible && !displayRentalLengthError;

    if (mode === 'inline') {
        return (
            <>
                <Box sx={{ py: 2 }}>
                    <DateRangePicker
                        onChange={onChange}
                        autoFocus={false}
                        localeText={{ start: t('startDate'), end: t('endDate'), toolbarTitle: t('selectRentingPeriod') }}
                        value={selectedRange}
                        onMonthChange={handleSelectedMonthChange}
                        disableAutoMonthSwitching
                        rangePosition={rangePosition}
                        reduceAnimations={false}
                        onRangePositionChange={onFocusChange}
                        formatDensity="dense"
                        /* @ts-ignore doesn't support custom prop typing */
                        slots={{ day: CustomDay, actionBar: CustomActionBar, layout: CustomLayout }}
                        slotProps={{
                            /* @ts-ignore doesn't support custom prop typing */
                            day: { selectedRange },
                            /* @ts-ignore doesn't support custom prop typing */
                            layout: { displayRentalMaxLengthAlert, displayRentalLengthError },
                            fieldSeparator: { children: <>{t('to')}</> },
                            textField: {
                                size: 'small',
                                variant: 'standard',
                                inputProps: { style: { fontSize: rem(12) } },
                                InputLabelProps: { style: { fontSize: rem(12) } },
                            },
                        }}
                        shouldDisableDate={isDateDisabledFn}
                        minDate={resolvedMinDate}
                        maxDate={resolvedMaxDate}
                    />
                </Box>

                <AlertContainer displayRentalMaxLengthAlert={false} displayRentalLengthError={displayRentalLengthError} />
            </>
        );
    }

    const showDateRange = isRangeValid(selectedRange);

    return (
        <>
            {showDateRange && (
                <Button variant="text" onClick={() => setVisible(true)} sx={calendarButtonStyle}>
                    <Typography variant="body1">{`${capitalizeString(formatShortMonthAndDate(selectedRange[0]))} - ${capitalizeString(
                        formatShortMonthAndDate(selectedRange[1]),
                    )}`}</Typography>
                </Button>
            )}

            <Modal open={isVisible} variant="auto" onClose={() => setVisible(false)} modalStyle={{ margin: '16px' }}>
                <StaticDateRangePicker
                    onChange={onChange}
                    autoFocus={false}
                    onMonthChange={handleSelectedMonthChange}
                    localeText={{ start: t('startDate'), end: t('endDate'), toolbarTitle: t('selectRentingPeriod') }}
                    value={selectedRange}
                    disableAutoMonthSwitching
                    rangePosition={rangePosition}
                    reduceAnimations={false}
                    calendars={isDesktop ? 2 : 1}
                    onRangePositionChange={onFocusChange}
                    slots={{ day: CustomDay, actionBar: CustomActionBar }}
                    /* @ts-ignore doesn't support custom prop typing */
                    slotProps={{ day: { selectedRange } }}
                    shouldDisableDate={isDateDisabledFn}
                    minDate={resolvedMinDate}
                    maxDate={resolvedMaxDate}
                />

                <AlertContainer displayRentalMaxLengthAlert={displayRentalMaxLengthAlert} displayRentalLengthError={displayRentalLengthError} />

                <DialogActions sx={{ pt: 2 }}>
                    <Button
                        variant="text"
                        onClick={() => {
                            onChange([null, null]);
                            onFocusChange('start');
                        }}
                    >
                        {t('reset')}
                    </Button>
                    <Button
                        disabled={!isValid}
                        variant="contained"
                        onClick={() => {
                            onConfirm();
                        }}
                    >
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Modal>
        </>
    );
};
