import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApiClient } from '../services/sharetribe/apiClients';

export const useHomeDeliveryPostalCodes = (): UseQueryResult<string[]> => {
    const getHomePage = async () => {
        const { data } = await getApiClient('delivery').get<string[]>('/postal-codes');
        return data;
    };

    return useQuery(['postal-codes'], getHomePage);
};
