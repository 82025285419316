import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { Requirements, StripeAccount } from '../../../transactions/apiTypes';
import { isNotNil } from '../../../helpers/commonHelpers';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { hasRequirements } from '../Stripe/helpers';

interface StripeAccountStatus {
    payoutsDisabled: boolean;
    requirements: Requirements;
    bankInformationMissing: boolean; // user has stripe account but no bank account added;
    deadline?: number; // deadline for current requirements, if present
    pausedByAdmin?: boolean;
}

export const getStripeAccountStatus = (stripeAccountData: StripeAccount['stripeAccountData'] | undefined): StripeAccountStatus => {
    if (!stripeAccountData || !stripeAccountData.requirements) {
        return {
            requirements: {},
            payoutsDisabled: false,
            bankInformationMissing: false,
        };
    }

    let { requirements } = stripeAccountData;
    const hasCurrentRequirements = hasRequirements(stripeAccountData, ['currently_due']);
    const payoutsDisabled = isNotNil(stripeAccountData.requirements.disabled_reason);
    const bankInformationMissing = Boolean(hasCurrentRequirements && stripeAccountData?.requirements?.currently_due?.includes('external_account'));
    const deadline = stripeAccountData.requirements.current_deadline;
    const pausedByAdmin = stripeAccountData.requirements.disabled_reason === 'platform_paused';

    return {
        payoutsDisabled,
        requirements,
        bankInformationMissing,
        deadline,
        pausedByAdmin,
    };
};

export type StripeAccountDataWithStatus = StripeAccount['stripeAccountData'] & StripeAccountStatus;

export const useStripeAccount = (): UseQueryResult<StripeAccountDataWithStatus> => {
    const { data: loggedInUser } = useCurrentUser();

    const stripeAccountId = loggedInUser?.stripeAccount?.stripeAccountId;

    const isValidLogin = !loggedInUser?.banned && !loggedInUser?.deleted;
    const enabled = !!stripeAccountId && isValidLogin;

    return useQuery(
        ['stripe-account'],
        async () => {
            const url = '/account';
            const { data } = await getApiClient('stripe').get<StripeAccount>(url);

            const status = getStripeAccountStatus(data.stripeAccountData);

            return {
                ...data.stripeAccountData,
                ...status,
            };
        },
        { enabled },
    );
};
