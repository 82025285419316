import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query';
import { getNextPageParam } from '../../helpers/commonHelpers';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { ResponseMeta } from '../../types/apiTypes';
import { ConversationHistory } from '../apiTypes';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';

/**
 * Returns a specific conversation's message history
 */
export const useConversations = (startingPage = 1): UseInfiniteQueryResult<ConversationHistory> => {
    const token = useAppSelector(selectToken);

    const getConversations = async ({ pageParam = startingPage }) => {
        if (!token) {
            return null;
        }
        const url = `/?page=${pageParam}`;
        const { data } = await getApiClient('conversations').get<{ data: ConversationHistory; meta: ResponseMeta }>(url);

        return { ...data, nextPage: pageParam + 1 } || [];
    };

    return useInfiniteQuery(['conversation-history'], getConversations, {
        staleTime: 2 * 60 * 1000,
        getNextPageParam,
        enabled: !!token,
    });
};
