import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { toast } from 'react-toastify';
import CenteredContainer from '../../components/CenteredContainer/CenteredContainer';
import { ErrorSVG } from '../../components/ErrorSVG/ErrorSVG';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { SuccessSVG } from '../../components/SuccessSvg/SuccessSvg';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import useLocationParams from '../../hooks/useLocationParams';
import { User } from '../../types/apiTypes';
import { useVerifyEmail } from './useVerifyEmail';
import { Preferences } from '@capacitor/preferences';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { fetchProfile } from '../../helpers/authHelpers';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';
import { Capacitor } from '@capacitor/core';

export const VerifyEmailView: React.FC = () => {
    const [verificationError, setVerificationError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const token = useAppSelector(selectToken);
    const navigate = useSafeNavigate();

    const { t } = useTranslation();
    const { t: verificationToken } = useLocationParams();

    const handleVerificationSuccess = () => {
        setIsVerified(true);
    };

    const redirectToLogin = async () => {
        const msg = t('noLoginFound');
        toast.info(msg, { toastId: 'noLoginFound' });

        await Preferences.set({ key: 'pendingVerificationToken', value: verificationToken });

        navigate(`/login/email`, { replace: true });
    };

    const handleVerificationError = (err: AxiosError) => {
        if (err.response?.status === 401) {
            redirectToLogin();
        } else {
            setVerificationError(true);
        }
    };

    const { mutate: verifyEmail, isLoading } = useVerifyEmail({ onSuccess: handleVerificationSuccess, onError: handleVerificationError });

    useEffectOnce(() => {
        const handleVerify = async () => {
            // No accessToken, redirect to login
            if (!token) {
                redirectToLogin();
            }

            const { response } = await fetchProfile(token);
            const user = response?.data;

            // Already verified, show success window
            if (user && user.emailVerified) {
                setIsVerified(true);
            }

            // Not verified yet, attempt to verify
            else if (user && verificationToken) {
                verifyEmail(verificationToken);
            }

            // No, active login, redirect user to login.
            else if (verificationToken) {
                redirectToLogin();
            } else {
                setVerificationError(true);
            }
        };

        handleVerify();
    });

    const handleContinue = () => {
        navigate('/');
    };

    const isNative = Capacitor.isNativePlatform();
    const successText = isNative ? t('verificationSuccessTextMobile') : t('verificationSuccessText');

    return (
        <CenteredContainer>
            <RobesLogoFull height={90} containerStyle={{ marginBottom: '30px', justifyContent: 'center' }} />

            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ height: '200px' }}>
                    {isLoading && !isVerified && !verificationError ? (
                        <>
                            <CircularProgress />
                        </>
                    ) : (
                        <>
                            {isVerified && (
                                <>
                                    <SuccessSVG variant="xs" />

                                    <Typography sx={{ pt: 4 }} variant="subtitle1">
                                        {successText}
                                    </Typography>

                                    <Button
                                        sx={{ mt: 4, padding: '6px 24px', borderRadius: '10px' }}
                                        fullWidth={isNative}
                                        variant="contained"
                                        onClick={handleContinue}
                                    >
                                        {t('continue')}
                                    </Button>
                                </>
                            )}
                            {verificationError && (
                                <>
                                    <ErrorSVG variant="sm" />
                                    <Typography sx={{ pt: 4 }} variant="h6">
                                        {t('verificationFailedText')}
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </CenteredContainer>
    );
};
