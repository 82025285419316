import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import { ChatWithUserButton } from './ChatWithUserButton';
import { useCurrentUserId } from '../../user/hooks/useUser';
import { getAppCountryCode } from '../../countryConfigs';

export const ChatWithSupportButton: React.FC<{ sx?: SxProps; description?: string; listingId?: string }> = ({ sx, description, listingId }) => {
    const { t } = useTranslation();
    const currentUserId = useCurrentUserId();
    const countryCode = getAppCountryCode();
    const adminId = process.env[`REACT_APP_ROBES_ADMIN_USER_ID_${countryCode}`] || '';

    if (!currentUserId) {
        return null;
    }

    return <ChatWithUserButton userId={adminId} sx={sx} description={description} listingId={listingId} buttonText={t('chatWithSupport')} />;
};
