import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { RobesLogoFull } from '../../components/RobesLogo/RobesLogoFull';
import { Trans, useTranslation } from 'react-i18next';

import { StaggerList, StaggerListElement } from '../../animations/components/Stagger';
import { InfoOutlined } from '@mui/icons-material';

export const StripeIdentityRequiresInput: React.FC<{ reason?: string }> = ({ reason }) => {
    const { t } = useTranslation();
    const resolvedReason = reason ? t(reason) : t('stripe_identity_requires_input_unknown');

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '30px',
            }}
        >
            <StaggerList custom={{ stagger: 0.8 }} style={{ width: '80%', display: 'flex', gap: '32px', flexDirection: 'column' }}>
                <StaggerListElement
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <RobesLogoFull height={70} />
                    <InfoOutlined color="info" fontSize="large" />
                </StaggerListElement>

                <StaggerListElement style={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant="body1">
                        <Trans i18nKey="stripeIdentityRequiresInput" values={{ reason: resolvedReason }} components={{ strong: <b />, br: <br /> }} />
                    </Typography>
                </StaggerListElement>
            </StaggerList>
        </Box>
    );
};
