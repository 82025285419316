import React, { CSSProperties, useEffect, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { Typography, Button, Switch, ListItemText, Skeleton, Box, Badge, Card, SxProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DateRangePickerComponent } from '../../components/DateRangePicker/DateRangePicker';
import { calculateDiscountedTotal, getDateRangeConstraints, isDayBlockedFn, isRangeValid, rangeLengthInDays } from './ListingFooter.helpers';
import { useTimeslots } from '../../queries/useTimeslots';
import { useToggleAvailability } from './useToggleAvailability';
import { ExtendedListing } from '../../types/apiTypes';
import { DateRange } from '../../components/DateRangePicker/DateRangePicker.types';
import { RangePosition } from '@mui/x-date-pickers-pro';
import { isSameDay } from 'date-fns';
import { DisplayTotal } from './ListingPriceDisplayTotal';
import { ListingFooterSkeleton } from './ListingFooter.skeleton';
import { InfoOutlined } from '@mui/icons-material';
import { SignupLink } from '../../components/SignUpLink/SignUpLink';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';

const availabilityToggleContainer: CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
};

interface ListingControlsCardProps {
    isOwnListing: boolean;
    isLoading: boolean;
    showDateRangePicker: boolean;
    selectedRange: DateRange;
    listing: ExtendedListing;
    sxProps: SxProps;
    onRentClick: () => void;
    onRangeSelected: (selection: DateRange) => void;
    setShowDateRangePicker: (val: boolean) => void;
}

export const ListingControlsCard: React.FC<ListingControlsCardProps> = ({
    isOwnListing,
    isLoading,
    showDateRangePicker,
    setShowDateRangePicker,
    onRentClick,
    onRangeSelected,
    selectedRange,
    listing,
    sxProps,
}) => {
    const [focusedInput, setFocusedInput] = useState<RangePosition>('start');
    const [rangeConstraints, setRangeConstraints] = useState<DateRange>([null, null]);
    const { state, price } = listing;
    const isAvailable = state === 'published';

    const { t } = useTranslation();

    const { mutate: toggleAvailability } = useToggleAvailability();
    const userToken = useAppSelector(selectToken);

    const { data: timeslots } = useTimeslots(listing.id);

    const isDayBlocked = isDayBlockedFn(timeslots);
    const allowSameDaySelection = listing.publicData.presentInShowroom;
    const rangeLength = rangeLengthInDays(selectedRange[0], selectedRange[1]);
    const priceTotal = rangeLength ? price.amount * rangeLength : undefined;
    const discountedTotal = calculateDiscountedTotal(rangeLength, listing);

    useEffect(() => {
        const constraints = getDateRangeConstraints(timeslots, selectedRange);
        setRangeConstraints(constraints);
    }, [focusedInput, selectedRange, timeslots]);

    return (
        <Box sx={{ padding: '16px 8px', position: 'sticky', top: 0, ...sxProps }}>
            <Card sx={{ padding: '20px', borderRadius: '10px', width: '100%', display: 'flex' }} elevation={3}>
                {!userToken && <SignupLink fullWidth label={t('signUpToRent')} />}
                {userToken && (
                    <>
                        {isLoading ? (
                            <ListingFooterSkeleton />
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                                {!isOwnListing && (
                                    <>
                                        <>
                                            {priceTotal ? (
                                                <DisplayTotal total={priceTotal} discountedTotal={discountedTotal} />
                                            ) : (
                                                <Typography variant="body1" fontWeight="400">
                                                    {t('selectDatesForTotal')}
                                                </Typography>
                                            )}
                                        </>
                                        <DateRangePickerComponent
                                            selectedRange={selectedRange}
                                            mode="inline"
                                            onFocusChange={(focus) => {
                                                // Prevents focus from jumping back to start after selecting full range
                                                const shouldNotSetFocus = focus === 'start' && selectedRange[0] && !selectedRange[1];
                                                if (shouldNotSetFocus) {
                                                    return;
                                                }

                                                setFocusedInput(focus);
                                            }}
                                            allowSameDaySelection={allowSameDaySelection}
                                            rangePosition={focusedInput}
                                            isDateDisabledFn={isDayBlocked}
                                            rangeConstraints={rangeConstraints}
                                            onChange={(selection: DateRange) => {
                                                if (selectedRange[0] && selectedRange[1]) {
                                                    const isEndSameAsSelection = selection[1] && isSameDay(selectedRange[1], selection[1]);
                                                    const newStartDate = isEndSameAsSelection || !selection[1] ? selection[0] : selection[1];
                                                    const newRange = [newStartDate, null] as DateRange;
                                                    onRangeSelected(newRange);

                                                    setFocusedInput('end');
                                                } else {
                                                    onRangeSelected(selection);
                                                }
                                            }}
                                            isVisible={showDateRangePicker}
                                            setVisible={setShowDateRangePicker}
                                            onConfirm={() => setShowDateRangePicker(false)}
                                        />
                                    </>
                                )}

                                {isOwnListing ? (
                                    <>
                                        {listing.state !== 'pendingApproval' && (
                                            <div style={availabilityToggleContainer}>
                                                <Tooltip title={t('availabilityToggleInfo')}>
                                                    <InfoOutlined sx={{ fontSize: '18px', opacity: 0.7, marginRight: '4px' }} />
                                                </Tooltip>
                                                <ListItemText id="settings-compact-mode-switch" primary={t('available')} />
                                                <Switch
                                                    edge="end"
                                                    onChange={() => toggleAvailability(listing)}
                                                    checked={isAvailable}
                                                    inputProps={{
                                                        'aria-labelledby': 'settings-compact-mode-switch',
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {listing.state === 'pendingApproval' && (
                                            <Box sx={{ display: 'flex' }}>
                                                <InfoOutlined sx={{ fontSize: '18px', opacity: 0.7, marginRight: '8px' }} />
                                                <Typography variant="body2">{t('pendingApprovalDescription')}</Typography>
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                            disabled={!isRangeValid(selectedRange)}
                                            variant="contained"
                                            type="submit"
                                            onClick={onRentClick}
                                            sx={{ px: 2 }}
                                        >
                                            {t('rent')}
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </>
                )}
            </Card>
        </Box>
    );
};
