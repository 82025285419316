import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { User } from '../types/apiTypes';
import { ReactFCWithChildren } from '../types/types';
import { useCurrentUser } from '../user/hooks/useUser';
import { SplashScreen } from '@capacitor/splash-screen';
import { useLogout } from '../hooks/auth/useLogout';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/hooks';
import { selectToken } from '../store/userReducer';
import { useSafeNavigate } from '../hooks/useSafeNavigate';
import { useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { getOnboardingFlowUrl } from '../helpers/authHelpers';

const allowedRoutesForUserWithUnverifiedEmail = ['/verification-pending', '/verify-email', '/login/email'];
const allowedRoutesForNative = ['/login', '/login/email', '/verification-pending', '/verify-email', '/privacy-policy', '/terms-of-service'];

export const AuthType = {
    LOGGED_IN: 'logged-in',
    EMAIL_VERIFIED: 'email-verified',
    INVALID: 'invalid',
} as const;

export type AuthType = typeof AuthType[keyof typeof AuthType];

const AuthContext = createContext<{ isLoading: boolean; grantedAuth: AuthType[] }>({
    isLoading: false,
    grantedAuth: [],
});

const getAuthCondition = (user: User | undefined, error: any) => ({
    isDeleted: !!user?.deleted,
    isBanned: !!user?.banned,
    userExists: !!user,
    emailVerified: !!user?.emailVerified,
    authError: error?.response?.data?.code === 'not-logged-in',
});

const getUserAuth = (condition: ReturnType<typeof getAuthCondition>): AuthType[] =>
    match(condition)
        .with({ isBanned: true }, { isDeleted: true }, () => [AuthType.INVALID])
        .with({ emailVerified: true }, () => [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED])
        .with({ userExists: true, emailVerified: false, isDeleted: false, isBanned: false }, () => [AuthType.LOGGED_IN])
        .otherwise(() => []);

export const AuthProvider: ReactFCWithChildren = ({ children }) => {
    const [loadingError, setLoadingError] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const { t } = useTranslation();
    const token = useAppSelector(selectToken);

    const { data: user, isError, status, isLoading, error } = useCurrentUser();
    const { mutate: logout } = useLogout();

    const location = useLocation();
    const navigate = useSafeNavigate();

    useEffect(() => {
        const handleStartNativePlatform = async () => {
            !token && navigate('/login', { replace: true });

            if (token && user) {
                const url = getOnboardingFlowUrl(user);

                if (url === '/onboarding') {
                    navigate(url, { replace: true });
                }
            }
        };

        if (initialized) {
            if (status === 'error') {
                setLoadingError(true);
            }
            if (loadingError && status === 'success') {
                setLoadingError(false);
            }
            return;
        }

        const hasLoaded = status === 'success' || status === 'error';

        if (hasLoaded && !initialized) {
            setInitialized(true);

            if (Capacitor.isNativePlatform()) {
                handleStartNativePlatform();
            }
        }
    }, [status, token, navigate, initialized]);

    const value = useMemo(() => {
        const condition = getAuthCondition(user, error);
        return { isLoading, grantedAuth: getUserAuth(condition) };
    }, [user, isError, isLoading, error]);

    useEffect(() => {
        if (!token || !value.grantedAuth.length) {
            return;
        }

        if (value.grantedAuth.includes(AuthType.INVALID)) {
            logout({ successToast: { type: 'error', msg: t('loginExpired') } });
            return;
        }

        // TODO: remove this when we have a proper solution for the native app
        if (Capacitor.isNativePlatform() && !token) {
            if (allowedRoutesForNative.includes(location.pathname)) {
                return;
            } else {
                navigate('/login', { replace: true });
            }
        }

        if (!value.grantedAuth.includes(AuthType.EMAIL_VERIFIED)) {
            if (allowedRoutesForUserWithUnverifiedEmail.includes(location.pathname)) {
                return;
            } else {
                navigate('/verification-pending', { replace: true });
            }
        }
    }, [value, token, location.pathname]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const hasRequiredAuth = (requiredAuth: AuthType, grantedAuth: AuthType[]) => grantedAuth.includes(requiredAuth);

export const useAuth = () => useContext(AuthContext);
