import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDuration } from '../../hooks/useDuration';
import { AnimatePresence, motion, easeInOut } from 'framer-motion';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { swapVertical } from '../../animations/constants';
import { usePrevious } from '../../hooks/usePrevious';
import { sleep } from '../../helpers/commonHelpers';

interface StripeIdentityPendingProps {
    refreshDuration?: number;
    timedOut?: boolean;
    onTimeout?: () => void;
    onRefresh?: () => Promise<void>;
}

export const StripeIdentityPending: React.FC<StripeIdentityPendingProps> = ({ refreshDuration = 3000, timedOut, onTimeout, onRefresh }) => {
    const [refreshing, setRefreshing] = useState(false);
    const [timeoutExceeded, setTimedOut] = useDuration([], 10000);
    const { t } = useTranslation();

    const previousTimedOutValue = usePrevious(timedOut);

    useEffect(() => {
        if (timeoutExceeded && onTimeout) {
            onTimeout();
        }
    }, [timeoutExceeded]);

    useEffect(() => {
        if (previousTimedOutValue && previousTimedOutValue !== timedOut) {
            setTimedOut(false);
        }
    }, [timedOut]);

    const refreshAndUpdateUserData = async () => {
        if (!onRefresh) {
            return;
        }

        setRefreshing(true);

        await sleep(refreshDuration);

        await onRefresh();

        setRefreshing(false);
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '30px',
                width: '100%',
            }}
        >
            <AnimatePresence mode="wait" initial={false}>
                {timedOut && !refreshing ? (
                    <AnimatedContainer
                        custom={{ dir: 'down', height: 30 }}
                        variants={swapVertical}
                        key="minimized"
                        style={{ width: '100%' }}
                        transition={{ ease: easeInOut, duration: 0.3 }}
                    >
                        <Typography>{t('stripeTakingLongerThanExpected')}</Typography>
                        <Button variant="contained" fullWidth sx={{ p: 0.5, mt: 2 }} onClick={refreshAndUpdateUserData}>
                            <motion.div animate={{ opacity: [0.3, 1, 0.3] }} transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}>
                                {t('refresh')}
                            </motion.div>
                        </Button>
                    </AnimatedContainer>
                ) : (
                    <AnimatedContainer
                        variants={swapVertical}
                        custom={{ dir: 'up', height: 30 }}
                        key="full"
                        transition={{ y: { ease: easeInOut, duration: 0.3 }, opacity: { ease: easeInOut, duration: 0.1 } }}
                    >
                        <CircularProgress />
                    </AnimatedContainer>
                )}
            </AnimatePresence>
        </Box>
    );
};
