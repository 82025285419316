import { LoadingButton } from '@mui/lab';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { AddedCredits } from '../../views/UserProfile/hooks/useRedeemPromotionalCode';
import { SwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';
import { PromotionalCodeSubmitSuccess } from './PromotionalCodeSubmitSuccess';
import { CreditsConfiguration } from '../../views/UserProfile/hooks/useCreditsConfiguration';
import { getAppConfig } from '../../countryConfigs';

interface PromotionalCodeDrawerProps {
    open: boolean;
    configuration: CreditsConfiguration | undefined;
    onClose: () => void;
    onSubmit: (data: { code: string }) => void;
    isLoading: boolean;
    submitError: string | null;
    addedCredits: AddedCredits | null;
    hideHeaderOnSuccess?: boolean;
    showSuccessAnimation?: boolean;
}

export const PromotionalCodeDrawer: React.FC<PromotionalCodeDrawerProps> = ({
    open,
    onClose,
    onSubmit,
    isLoading,
    submitError,
    addedCredits,
    hideHeaderOnSuccess = false,
    showSuccessAnimation = false,
}) => {
    const { t } = useTranslation();
    const { register, handleSubmit, reset } = useForm<{ code: string }>();
    const currency = getAppConfig().stripeConfig.currency;

    const handleClose = () => {
        reset();
        onClose();
    };

    const getHeaderText = () => {
        if (addedCredits && hideHeaderOnSuccess) {
            return '';
        }

        return t('enterYourCode');
    };

    return (
        <SwipeableDrawer open={open} onClose={handleClose} headerText={getHeaderText()} fillHeight={false}>
            {showSuccessAnimation && addedCredits ? (
                <PromotionalCodeSubmitSuccess addedCredits={addedCredits}>
                    <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }} fullWidth>
                        {t('close')}
                    </Button>
                </PromotionalCodeSubmitSuccess>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
                        <Typography variant="body2">
                            <Trans i18nKey="promoCodeDrawerBodyText" components={{ b: <strong /> }} />
                        </Typography>
                        <TextField
                            variant="outlined"
                            label={t('enterYourCode')}
                            error={!!submitError}
                            helperText={submitError ? t(submitError) : undefined}
                            sx={{ mt: 5 }}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            {...register('code', { required: true })}
                        />
                        <LoadingButton loading={isLoading} variant="contained" type="submit" sx={{ mt: 10 }}>
                            {t('redeemCode')}
                        </LoadingButton>
                    </Box>
                </form>
            )}
        </SwipeableDrawer>
    );
};
