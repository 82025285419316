import { AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { getApiClient } from '../services/sharetribe/apiClients';
import { User } from '../types/apiTypes';
import { Capacitor } from '@capacitor/core';

export const fetchProfile = async (token: string): Promise<{ response?: AxiosResponse<User>; error?: unknown }> => {
    const url = '/current';
    try {
        const response = await getApiClient('user').get(url, {
            headers: {
                Authorization: token,
            },
        });
        return { response };
    } catch (error) {
        return { error };
    }
};

/**
 * After login/signup, direct the user to the correct view
 */
export const handleAuthRedirect = (user: User, navigate: NavigateFunction, url?: string): void => {
    const { emailVerified } = user;

    if (url) {
        navigate(url, { replace: true });
    } else if (emailVerified) {
        navigate(getOnboardingFlowUrl(user), { replace: true });
    } else if (!emailVerified) {
        navigate('/verification-pending');
    }
};

export const getOnboardingFlowUrl = (user: User): string => {
    const onboardingPending = user.profile?.privateData.onboardingState !== 'complete';
    const startNativeOnboarding = Capacitor.isNativePlatform() && onboardingPending;
    return startNativeOnboarding ? '/onboarding' : '/';
};
